<template>
  <div ref="modalbg" class="modalbg" @click="checkClick($event)">
    <div class="modalcontent">
      <div class="text-center w-full border-b-4 border-black p-2">
        <div style="position: absolute; right: 40px; color: black" class="cursor-pointer" @click="$emit('close')">
          <i ref="xmark" class="fa fa-xmark text-4xl"></i>
        </div>
        <span class="mt-10 font-bold text-3xl text-black">{{employee.name}} {{employee.surname}}</span>
      </div>
      <div class="w-full overflow-x-auto">
        <table class="bg-white w-full">
          <tbody>
            <tr>
              <th class="bg-gray-500 text-white">Vorname</th>
              <td>{{ employee.name }}</td>
            </tr>
            <tr>
              <th class="bg-gray-500 text-white">Nachname</th>
              <td>{{ employee.surname }}</td>
            </tr>
            <tr>
              <th class="bg-gray-500 text-white">Mobilnummer</th>
              <td><a :href="'tel:'+employee.phone">{{ employee.phone }}</a></td>
            </tr>
            <tr>
              <th class="bg-gray-500 text-white">Adresse</th>
              <td>{{ employee.street }}<br>{{employee.zip}} {{employee.city}}</td>
            </tr>
            <tr>
              <th class="bg-gray-500 text-white">Ausbildung</th>
              <td>{{ employee.training}}</td>
            </tr>


            <tr>
              <th class="bg-gray-500 text-white">Aktionen (Beta!)</th>
              <td><button v-if="job.Mitarbeiternr" class="mx-1 inline-block font-bold mt-4 bg-gray-500 rounded p-2 text-white" @click="sendMessage('Bestätigung','whatsapp')">
                Bestätigung (WA)
              </button>
              <button v-if="job.Mitarbeiternr" class="mx-1 inline-block font-bold mt-4 bg-gray-500 rounded p-2 text-white" @click="sendMessage('Bestätigung','sms')">
                Bestätigung (SMS)
              </button><button v-if="job.Mitarbeiternr" class="mx-1 inline-block font-bold mt-4 bg-gray-500 rounded p-2 text-white" @click="sendMessage('Annahme','whatsapp')">
                Annahme (WA)
              </button>
              <button v-if="job.Mitarbeiternr" class="mx-1 inline-block font-bold mt-4 bg-gray-500 rounded p-2 text-white" @click="sendMessage('Annahme','sms')">
                Annahme (SMS)
              </button>
              </td>
            </tr>
            
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import CASP from "../CASP";
  export default {
    name: "JobModal",
    props: {
      job: Object,
      token: String,
    },
    data() {
      return {
        employee: {},
      };
    },
    methods: {
      sendMessage(type, channel) {
        if (this.employee.phone) {
          if (channel == "sms") {
            window.open("sms:" + this.employee.phone + "?body=" + encodeURIComponent("Hallo " + this.employee.name + ",\ndu hast den Auftrag für den " + this.job.datum_formatted + " noch nicht "+(type=="Bestätigung"?"bestätigt":"angenommen")+". Bitte mach das noch."))
          } else if (channel == "whatsapp") {
            window.open("https://wa.me/" + this.employee.phone.replace("+49", "") + "?text=" + encodeURIComponent("Hallo " + this.employee.name + ",\ndu hast den Auftrag für den " + this.job.datum_formatted + " noch nicht "+(type=="Bestätigung"?"bestätigt":"angenommen")+". Bitte mach das noch."))
          }
        }
      },
      getEmployee(persnr) {
        let vm = this;
        CASP.getAdminUser(this.token, persnr, (status, res) => {
          if (status == 200) {
            res = JSON.parse(res);
            if (res.length == 1) vm.employee = res[0];
          }
        });
      },
      checkClick(event) {
        if (event.target == this.$refs.modalbg) {
          this.$emit("close");
        }
      },
    },
    created: function () {
      if (this.job.Mitarbeiternr) {
        this.getEmployee(this.job.Mitarbeiternr);
      }
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .detailline {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
  }

  .attributename {
    display: inline-block;
    flex-grow: 1;
    flex-basis: 33.33%;
    font-weight: bold;
  }

  .attributevalue {
    border-bottom: 1px solid black;
    flex-grow: 2;
    flex-basis: 66.66%;
  }
</style>