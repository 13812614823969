<template>
  <toolbar title="Archiv" :showReload="true" @reload="loadArchive()"/>
  
  <div class="w-full text-center">
    <div class="w-full md:w-5/6 inline-block text-left">
   <spinner v-if="loading"/>
  <div class="joblist" style="padding: 5px">
    <template v-for="month in months" :key="month.month">
      <div
        style="
          width: 100%;
          text-align: center;
          border-bottom: 2px solid black;
          padding: 10px;
          background-color: white;
          color: black;
        "
      >
        <span style="font-size: 30px; font-weight: bold">{{ month.month }}</span
        ><br />
        <span style="color: black">Stunden gearbeitet: {{ month.hours }}</span>
      </div>
      <template v-for="job in month.jobs" :key="job.id">
        <Archivejob @reloadArchive="loadArchive()" :job="job" />
        <div style="height: 2px; background: black; width: 100%"></div>
      </template>
    </template>
  </div>
    </div>
  </div>
</template>

<script>
import Archivejob from "@/components/Archivejob.vue";
import Toolbar from "@/components/Toolbar.vue";
import CASP from "../../CASP";
import { useCookies } from "vue3-cookies";
import Spinner from '@/components/Spinner.vue';
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  name: "Archivelist",
  data() {
    return {
      token: "",
      months: [],
      loading: true
    };
  },
  components: {
    Archivejob,
    Toolbar,
    Spinner,
  },
  methods: {
    loadArchive() {
      let comp = this;
      this.loading=true
      CASP.getArchive(this.token, (archive) => {
        comp.months = archive;
        comp.loading = false;
      });
    },
  },
  created: function () {
    document.getElementById("mainspinner").style.display = "none";
    if (this.cookies.get("authtoken")) {
      this.token = this.cookies.get("authtoken");
    } else {
      window.location = "/forbidden";
    }
    this.loadArchive()
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
