<template>
    <Spinner v-if="loading" />
    <div
        class="px-10" style="text-align:center; border-bottom: 2px solid black; background-color: white;color: black">
        <i style="position: absolute; left: 12px; margin-top: 8px;color: black" class="fas fa-arrow-left fa-lg"
            onclick="window.location = '/jobs'"></i>
        <span style="font-size: 25px; font-weight: bold">{{title}}</span>
    </div>
    <div class="w-full text-center mt-3">
    <span class="font-bold text-2xl underline">Auftragsreihe</span>
    </div>
    <template  v-for="job in jobs" :key="job">
      <Job :job="job"/>
    </template>
    
    <div class="mt-6 px-8">
    <div
        style="
          margin: 3px;
          font-weight: bold;
          border: 2px solid red;
        "
        v-if="showError"
      >
        <span>{{ errorMsg }}</span>
      </div>
      <div
        style="
          margin: 3px;
          font-weight: bold;
          border: 2px solid green;
        "
        v-if="showSuccess"
      >
        <span>{{ successMsg }}</span>
      </div>
    <div class="w-full text-center">
      <span class="font-bold" >Diese Aufträge können nicht einzeln, sondern nur alle zusammen angenommen werden.</span>
    <button v-if="jobs.length > 0 && !showSuccess" class="w-full mt-2" @click="accept()">Alle Annehmen</button>
    </div>
    </div>

</template>

<script>
import CASP from '../../CASP'
import Spinner from '@/components/Spinner'
import Job from '@/components/Job'
import { useCookies } from 'vue3-cookies'
export default {
  setup () {
    const { cookies } = useCookies()
    return { cookies }
  },
  name: 'Jobdetails',
  components: {
    Spinner,
    Job
  },
  data () {
    return {
      loading: true,
      title: "Gruppe lädt...",
      jobs: [],
      showError: false,
      showSuccess: false,
      errorMsg: "",
      successMsg: ""
    }
  },
  methods: {
showErrorMessage(message) {
      this.showError = true;
      this.showSuccess = false;
      this.errorMsg = message;
      const comp = this;
      setTimeout(() => {
        comp.showError = false;
      }, 5000);
    },
    showSuccessMessage(message) {
      console.log("showcucessmaessage");
      this.showError = false;
      this.showSuccess = true;
      this.successMsg = message;
    },
    getJobs () {
      const comp = this
      CASP.getJobsInGroup(this.token, this.$route.params.groupid, (jobs) => {
        if(jobs.length>0){
          comp.title=jobs[0].Vertragsbeschreibung
        }
        jobs.forEach(job=>{
          job.Vertragsbeschreibung=""
        })
        comp.jobs = jobs
        comp.loading = false
      })
    },
    accept () {
      const comp = this
      CASP.acceptJobGroup(this.token, this.$route.params.groupid, (scode, response) => {
        if (scode === 200) {
          comp.showSuccessMessage('Du hast alle Aufträge angenommen')
        } else {
          this.showErrorMessage(response)
        }
      })
    },
  },
  created: function () {
    document.getElementById('mainspinner').style.display = 'none'
    if (this.cookies.get('authtoken')) {
      this.token = this.cookies.get('authtoken')
      this.getJobs()
    } else {
      window.location = '/forbidden'
    }
  }
}

</script>
<style scoped>

.detailline {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex
}

.attributename {
  display: inline-block;
  flex-grow: 1;
  flex-basis: 33.33%;
  font-weight: bold
}

.attributevalue {
  border-bottom: 1px solid black;
  flex-grow: 2;flex-basis: 66.66%
}

/* Style the buttons that are used to open the tab content */
button {
  cursor: pointer;
  padding: 10px 14px;
  display: inline-block;
  color: rgb(52,199,89);
  border: 2px solid rgb(52,199,89);
  border-radius: 8px;
  font-weight: bold;
  font-size: 18px;
  background-color:white
}

.disabled{
  border: 1px solid gray;
  color: gray;
}

</style>
