<template>
  <Toolbar
    title="Rundgang erstellen"
    :showReload="false"
    backUrl="/admin/routes"
  />
  <div v-show="qrscan" class="absolute w-full h-full top-0 left-0 z-10">
    <div class="text-center pt-36 w-full h-full z-10 bg-black/80">
      <video
        id="video"
        ref="video"
        class="w-96 h-96 max-h-96 inline-block"
        autoplay
      ></video
      ><br />
      <button class="text-black bg-red-500" @click="stopQRScan()">
        Abbrechen
      </button>
    </div>
  </div>
  <div class="p-4 text-center">
    <div class="w-full xl:w-5/6 inline-block text-left">
      <div class="pr-4">
        <label class="block font-bold">Name</label>
        <input
          v-model="route.name"
          class="bg-white rounded-lg px-2 py-1 border border-gray-300 w-full"
          type="text"
          placeholder="Bezeichnung des Rundgangs"
        />
      </div>
      <div class="pr-4">
        <label class="block font-bold">Beschreibung</label>
        <textarea
          v-model="route.description"
          rows="3"
          class="bg-white rounded-lg px-2 py-1 border border-gray-300 w-full"
          type="text"
          placeholder="Beschreibung des Rundgangs"
        />
      </div>
      <div class="w-full grid md:grid-cols-2">
        <div class="pr-4">
          <label class="block font-bold">Vertrag</label>
          <input
            v-model="route.contract"
            class="bg-gray-100 rounded-lg px-2 py-1 border border-gray-300 w-full"
            type="text"
            disabled
          />
        </div>
        <div class="pr-4">
          <label class="block font-bold">Einsatzort</label>
          <input
            v-model="route.location"
            class="bg-gray-100 rounded-lg px-2 py-1 border border-gray-300 w-full"
            type="text"
            disabled
          />
        </div>
        <div class="pr-4">
          <label class="block font-bold">Einsatzstelle</label>
          <input
            v-model="route.place"
            class="bg-gray-100 rounded-lg px-2 py-1 border border-gray-300 w-full"
            type="text"
            disabled
          />
        </div>
        <div class="pr-4">
          <input
            id="tour_active"
            v-model="route.active"
            type="checkbox"
            checked
          />
          <label for="tour_active" class="block font-bold">Aktiv</label>
        </div>
        <div class="pr-4">
          <input id="tour_onlyNFC" v-model="route.onlyNFC" type="checkbox" />
          <label for="tour_onlyNFC" class="block font-bold">Nur NFC</label>
        </div>
      </div>
      <span class="text-2xl font-bold mt-6 inline-block mr-4">Wegpunkte</span>
      <div class="w-full overflow-x-auto">
        <table class="">
          <thead>
            <tr>
              <th>Nr.</th>
              <th class="min-w-[200px]">Name</th>
              <th class="min-w-[200px]">Info</th>
              <th>Code</th>
              <th>Aktionen</th>
            </tr>
          </thead>
          <tbody ref="body">
            <tr v-for="row in route.waypoints" :key="row">
              <td>{{ row.order }}</td>
              <td>
                <input
                  v-model="row.name"
                  placeholder="Name"
                  class="h-full w-full px-2 py-2"
                />
              </td>
              <td>
                <input
                  v-model="row.info"
                  placeholder="Info"
                  class="h-full w-full px-2 py-2"
                />
              </td>
              <td @click="scanningRow = row">
                <input
                  v-model="row.code"
                  placeholder="CASP-P-X-XX"
                  class="h-full w-full px-2 py-2"
                />
              </td>
              <td>
                <button class="mr-2" @click="startQRScan(row)">QR</button
                ><button @click="deletePoint(row)">Löschen</button>
              </td>
            </tr>
            <tr>
              <td>{{ newPoint.order }}</td>
              <td>
                <input
                  v-model="newPoint.name"
                  placeholder="Name"
                  class="h-full w-full px-2 py-2"
                />
              </td>
              <td>
                <input
                  v-model="newPoint.info"
                  placeholder="Info"
                  class="h-full w-full px-2 py-2"
                />
              </td>
              <td @click="scanningRow = newPoint">
                <input
                  v-model="newPoint.code"
                  placeholder="CASP-P-X-XX"
                  class="h-full w-full px-2 py-2"
                />
              </td>
              <td>
                <button class="mr-2" @click="startQRScan(newPoint)">QR</button
                ><button @click="addPoint">Hinzufügen</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="w-full text-right">
        <button @click="saveRoute()">Rundgang speichern</button>
      </div>
    </div>
  </div>
</template>

<script>
import CASP from "../../CASP";
import Spinner from "@/components/Spinner";
import Toolbar from "@/components/Toolbar.vue";
import Sortable from "sortablejs";
export default {
  name: "AdminTour",
  data() {
    return {
      contracts: {},
      loading: true,
      qrscan: false,
      scanningRow: {
        code: "",
      },
      route: {
        name: "",
        description: "",
        contract: "",
        location: "",
        place: "",
        placeCode: "",
        onlyNFC: false,
        active: true,
        waypoints: [],
      },
      newPoint: {
        name: "",
        info: "",
        code: "",
      },
    };
  },
  components: {
    Toolbar,
  },
  methods: {
    stopQRScan() {
      if (this.qrscan) {
        window.rescanner.stopQRScan();
        this.qrscan = false;
      }
    },
    startQRScan(row) {
      if (this.qrscan) {
        window.rescanner.stopQRScan();
        this.qrscan = false;
        return;
      }
      console.log("start scan");
      window.rescanner.startQRScan(this.$refs.video, "environment");
      this.qrscan = true;
      this.scanningRow = row;
    },
    getContracts() {
      CASP.getPresets("", (presets) => {
        this.contracts = presets.contracts;
        let code =
          this.route.placeCode != ""
            ? this.route.placeCode
            : new URLSearchParams(window.location.search).get("location");
        this.route.placeCode = code;
        Object.keys(this.contracts).forEach((c) => {
          Object.keys(this.contracts[c].locations).forEach((l) => {
            Object.keys(this.contracts[c].locations[l].places).forEach((p) => {
              if (this.contracts[c].locations[l].places[p] === code) {
                this.route.location = l;
                this.route.contract = c;
                this.route.place = p;
              }
            });
          });
        });
      });
    },
    addPoint() {
      this.newPoint.order =
        this.route.waypoints.length == 0
          ? 1
          : Math.max(...this.route.waypoints.map((item) => item.order)) + 1;
      this.route.waypoints.push(this.newPoint);
      this.newPoint = {
        name: "",
        info: "",
        code: "-",
      };
    },
    deletePoint(row) {
      this.route.waypoints.splice(this.route.waypoints.indexOf(row), 1);
    },
    scanResult(message) {
      window.rescanner.stopQRScan();
      this.qrscan = false;
      if (!this.scanningRow) {
        return;
      }
      this.scanningRow.code = message;
      this.scanningRow = false;
    },
    saveRoute() {
      if (this.$route.params.id) {
        CASP.updateRoute(
          this.$route.params.id,
          this.route,
          (status, response) => {
            if (status == 200) {
              this.$router.push({ name: "AdminRoutes" });
            }
          }
        );
      } else {
        CASP.addRoute(this.route, (status, response) => {
          if (status == 200) {
            this.$router.push({ name: "AdminRoutes" });
          }
        });
      }
    },
  },
  mounted() {
    document.getElementById("mainspinner").style.display = "none";
    let vm = this;
    if (!window.rescanner) {
      let rescannerLib = document.createElement("script");
      rescannerLib.setAttribute("src", "/rescanner.js");
      rescannerLib.onload = () => {
        window.rescanner.startNFCScan();
        window.rescanner.onNFCSuccess = (message) => {
          vm.scanResult(message);
        };
        window.rescanner.onQRSuccess = (message) => {
          vm.scanResult(message);
        };
      };
      document.head.appendChild(rescannerLib);
    } else {
      window.rescanner.startNFCScan();
      window.rescanner.onNFCSuccess = (message) => {
        vm.scanResult(message);
      };
      window.rescanner.onQRSuccess = (message) => {
        vm.scanResult(message);
      };
    }
    if (this.$route.params.id) {
      CASP.getRoute(this.$route.params.id, (status, response) => {
        if (status == 200) {
          this.route = response;
        }
        this.getContracts();
      });
    } else {
      this.getContracts();
    }
    if (window.outerWidth > 600) {
      new Sortable(this.$refs.body, {
        animation: 150,
        ghostClass: "sortable-ghost",
        onEnd: (evt) => {
          console.log(evt);
          const oldIndex = evt.oldIndex;
          const newIndex = evt.newIndex;
          const rows = this.route.waypoints.slice();
          rows.splice(newIndex, 0, rows.splice(oldIndex, 1)[0]);
          this.route.waypoints = rows;
        },
      });
    }
  },
};
</script>

<style>
.sortable-ghost {
  opacity: 0.5;
  background-color: #f0f0f0;
}
</style>