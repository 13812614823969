<template>
  <div v-show="showDropdown" ref="dropdown" class="z-20 border-gray-500 absolute p-2 w-full bg-white max-h-96 overflow-y-auto" @click="selectDropdown"></div>

  <Spinner v-if="loading" />
  <div class="w-full h-full relative text-center m-0 p-0" @click="generalClick" @keyup.esc="generalClick($event, true)">
    <top-banner :mode="bannerMode" :text="bannerText" v-if="bannerText"/>
    <div v-if="reportDialogVisible" class="modalbg">
      <div class="modalcontent p-5 lg:!w-auto">
        <div class="text-center w-full border-b-4 border-black p-2">
          <div style="position: absolute; right: 40px;color: black " class="cursor-pointer" @click="reportDialogVisible=false">
            <i ref="xmark" class="fa fa-xmark text-4xl"></i>
          </div>
          <span class="mt-10 px-10 font-bold text-3xl text-black">Report per Mail senden</span>
        </div>
        <div>
          <label class="font-bold block">Mailadresse</label>
          <input class="bg-gray-100 w-full px-3 py-2" v-model="reportMail" placeholder="Mailadresse">
        </div>
        <div class="text-right mt-2">
          <button @click="sendReport()" class="inline-block">Absenden</button>
        </div>
      </div>
    </div>
    <div v-if="checkinJob">
      <div class="modalbg">
        <div class="modalcontent p-5 lg:!w-auto h-full">
          <div class="absolute right-5 font-black cursor-pointer" @click="checkinPicture = null; checkinJob.checkedIn = oldCheckinTime; checkinJob = null">
            <i ref="xmark" class="fa fa-xmark text-4xl"></i>
          </div>
          <h1 class="pr-5 text-center">{{ checkinJob.Vertragsbeschreibung }}</h1>
          <div class="h-full overflow-y-auto pb-9">
          <div class="grid md:grid-cols-2">
            <div class="text-center">
              <div class="w-auto md:w-full inline-block text-left">
                <div class="my-1">
                  <span class="block font-bold text-left">Lfd Nr</span>
                  <span>{{ checkinJob.Lfd_Nr }}</span>
                </div>
                <div class="my-1">
                  <span class="block font-bold text-left">Mitarbeiter</span>
                  <span>{{ checkinJob.Mitarbeiter }}</span>
                </div>
                <div class="my-1">
                  <span class="block font-bold text-left">Beginn</span>
                  <span>{{ checkinJob.Soll_Von }} ({{ checkinJob.datum_formatted }})</span>
                </div>
                <div class="my-1">
                  <span class="block font-bold text-left">Ende</span>
                  <span>{{ checkinJob.Soll_Bis }}</span>
                </div>
                <div class="my-1">
                  <span class="block font-bold text-left">Position</span>
                  <span>{{ checkinJob.Pos }}</span>
                </div>
                <div class="my-1">
                  <span class="block font-bold text-left">Sonstige Infos</span>
                  <span>{{ checkinJob.Bemerkungen }}</span>
                </div>
                <div class="my-1">
                  <span class="block font-bold text-left">Checkin-Zeit</span>
                  <input class="border-b-2 w-64 py-2" v-model="checkinJob.checkedIn" type="time">
                </div>
                <div class="my-1">
                  <span class="block font-bold text-left">Strafe</span>
                  <input class="border-b-2 w-64 py-2" v-model="checkinJob.Q" placeholder="Strafe">
                </div>
              </div>

            </div>
            <div v-if="checkinPicture" class="text-center">
              <img :src="checkinPicture" class="w-44 inline" />
            </div>
            <div v-else>
              <i class="fa fa-user-tie fa-8x" />
            </div>
          </div>
          <div class="text-center">
            <button @click="declineCheckin" class="text-center mt-2 bg-red-500 mx-1">Checkin ablehnen</button>
            <button @click="confirmCheckin" class="text-center mt-2">Checkin bestätigen</button>
          </div>
        </div>
        </div>
      </div>
    </div>
    <div class="fixed w-full z-10 text-center">
      <img class="w-full lg:mt-24 inline sm:hidden" src="@/assets/banner.jpg" />
      <img class="w-full sm:inline hidden" src="@/assets/DesktopBanner.png" />
    </div>
    <div class="relative w-full h-full text-center overflow-y-auto">
      <div class="relative w-full text-center pt-48 sm:pt-[10%] xl:w-5/6 xl:inline-block">
        <button class="hidden md:inline absolute left-0 text-black bg-caspyellow border-4 border-gray-500 rounded-lg w-32 py-2 font-bold" onclick="window.location='/admin'">
          Zurück
        </button>
        <button class="hidden whitespace-nowrap md:inline float-right border-4 border-gray-500 rounded-lg w-32 py-2 font-bold" @click="reportDialogVisible=true">
          Report senden
        </button>
        <span class="font-bold text-4xl w-8/12">Check-In Übersicht</span><br />
        <div>
          <div class="inline-block p-2 relative text-left">
            <span class="block font-bold mt-2">Vertrag</span>
            <div class="absolute right-4 text-2xl bottom-3 text-gray-500 cursor-pointer" @click="clearFilter(); getJobs()">
              <i class="fa fa-xmark" />
            </div>
            <input size="1" v-model="filter.contract" placeholder="Vertrag" @focus="filterDropdown($event, Object.keys(allContracts), 'contract')" class="dropdown border-b-2 w-64 sm:w-96 p-2 border-gray-500"
              @input="filterDropdown($event, Object.keys(allContracts), 'contract')" />
          </div>
          <div class="inline-block p-2 relative text-left">
            <span class="block font-bold mt-2">Einsatzort</span>
            <div class="absolute right-4 text-2xl bottom-3 text-gray-500 cursor-pointer" @click="filter.location = ''; filter.place = ''; getJobs()">
              <i class="fa fa-xmark" />
            </div>
            <input size="1" v-model="filter.location" placeholder="Einsatzort" @focus="filterDropdown($event, filteredLocations, 'location')" class="dropdown border-b-2 w-64 sm:w-96 p-2 border-gray-500"
              @input="filterDropdown($event, filteredLocations, 'location')" />
          </div>
          <div class="inline-block p-2 relative text-left">
            <span class="block font-bold mt-2">Einsatzstelle</span>
            <div class="absolute right-4 text-2xl bottom-3 text-gray-500 cursor-pointer" @click="filter.place = ''">
              <i class="fa fa-xmark" />
            </div>
            <input size="1" v-model="filter.place" placeholder="Einsatzstelle" @focus="filterDropdown($event, filteredPlaces, 'place')" class="dropdown border-b-2 w-64 sm:w-96 p-2 border-gray-500"
              @input="filterDropdown($event, filteredPlaces, 'place')" />
          </div>
          <div>
            <div class="inline-block p-2 relative text-left">
              <span class="block font-bold mt-2">Start nach</span>
              <input size="1" v-model="start" type="datetime-local" />
            </div>
            <div class="inline-block p-2 relative text-left">
              <span class="block font-bold mt-2">Start vor</span>
              <input size="1" v-model="end" type="datetime-local" />
            </div>
          </div>
          <button class="mx-1 inline md:hidden  text-black bg-caspyellow border-4 border-gray-500 rounded-lg w-32 py-2 font-bold" onclick="window.location='/admin'">
            Zurück
          </button>
          <button class="mx-1 inline whitespace-nowrap md:hidden border-4 border-gray-500 rounded-lg w-32 py-2 font-bold" @click="reportDialogVisible=true">
            Report senden
          </button>
          <button class="mx-1 inline py-2 border-4 border-gray-500 rounded-lg" @click="getJobs()">Suchen</button>
        </div>
        <div id="tablecontent" class="w-full">
          <span v-if="filter.location == ''" class="font-bold block text-left">Angeforderte App-Check-Ins</span>
          <span v-else class="font-bold block text-left">Check-Ins für {{ filter.location }}</span>
          <table class="w-full overflow-x-auto block md:table">
            <thead>
              <th>Lfd Nr.</th>
              <th class="text-center">Check-In</th>
              <th class="text-center">Bestätigt</th>
              <th>Beginn</th>
              <th>Ende</th>
              <th class="text-center">Aktionen</th>
              <th>Mitarbeiter</th>
              <th>Projekt</th>
              <th>Position</th>
              <th>Strafe</th>
            </thead>
            <tbody>
              <tr v-for="job in jobs" :key="job.id">
                <td>{{ job.Lfd_Nr }}</td>
                <td class="text-center">
                  <div v-if="job.checkedIn">{{ job.checkedIn }}</div>
                  <div v-else><svg fill="#ef4444" xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 448 512">
                      <path
                        d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                    </svg></div>
                </td>
                <td class="text-center">
                  <div v-if="job.checkinConfirmed"><svg fill="#22C55E" xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 448 512">
                      <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                    </svg></div>
                  <div v-else><svg fill="#ef4444" xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 448 512">
                      <path
                        d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                    </svg></div>
                </td>
                <td>{{ job.Soll_Von }} ({{ job.datum_formatted }})</td>
                <td>{{ job.Soll_Bis }}</td>
                <td class="text-center">
                  <a v-if="job.ma_data && job.ma_data.phone" :href="'tel:' + job.ma_data.phone" class="m-1 inline-block bg-red-500  text-white">
                    Anrufen
                  </a>
                  <button @click="showCheckin(job)">Einchecken</button>
                </td>
                <td>
                  {{ job.Mitarbeiter }}
                </td>
                <td>
                  {{ job.Vertragsbeschreibung }}
                </td>
                <td>
                  {{ job.Pos }}
                </td>
                <td>
                  {{ job.Q }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="md:hidden inline-block mt-5">
          <button class=" text-black bg-caspyellow border-4 border-gray-500 rounded-lg w-32 py-2 font-bold" onclick="window.location='/admin'">
            Zurück
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CASP from "../../CASP";
import { useCookies } from "vue3-cookies";
import JobModal from "@/components/JobModal.vue";
import Spinner from "@/components/Spinner";
import TopBanner from '@/components/TopBanner.vue';

export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  name: "Jobs",
  components: { JobModal, Spinner, TopBanner },
  data() {
    return {
      bannerMode: "success",
      bannerText:"",
      reportMail: "Einsatzleitung.Nord@sec-security.de",
      reportDialogVisible: false,
      start: "",
      end: "",
      oldCheckinTime: "",
      allLocations: [],
      allPlaces: [],
      checkinJob: null,
      showDropdown: false,
      checkinPicture: null,
      loading: false,
      filteredLocations: [],
      filteredPlaces: [],
      jobs: [],
      dropdownRef: "",
      connection: null,
      filter: {
        location: "",
        place: ""
      },
      allContracts: {}
    };
  },
  methods: {
    sendReport(){
      let vm=this;
      CASP.sendCheckinReport(this.token, this.filter, this.reportMail,(res)=>{
        vm.reportDialogVisible=false;
        vm.bannerText="Report versendet"
        vm.bannerMode="success"
        setTimeout(()=>{
          vm.bannerText="";
        },2000)
      })
    },
    declineCheckin() {
      let vm = this
      let patch = {
        checkinConfirmed: false,
        checkedIn: "",
      }
      CASP.changeJob(this.token, this.checkinJob.id, patch, (res) => {
        Object.keys(patch).forEach(key => {
          this.checkinJob[key] = patch[key]
        })
        vm.checkinJob = null
      })
    },
    confirmCheckin() {
      let vm = this
      let patch = {
        checkinConfirmed: true,
        Q: vm.checkinJob.Q,
        checkedIn: vm.checkinJob.checkedIn
      }

      CASP.changeJob(this.token, this.checkinJob.id, patch, (res) => {
        Object.keys(patch).forEach(key => {
          this.checkinJob[key] = patch[key]
        })
        vm.checkinJob = null
      })
    },
    generalClick(event, close = false) {
      console.log(event)
      if (close || (event.target.className.includes && !event.target.className.includes("dropdown"))) this.showDropdown = false
    },
    selectDropdown(event) {
      if (this.dropdownRef == "contract") {
        this.setContract(event.target.innerText)
        this.getJobs()
      }
      else if (this.dropdownRef == "location") {
        this.setLocation(event.target.innerText)
        this.getJobs()
      }
      else if (this.dropdownRef == "place") {
        this.filter.place = event.target.innerText
        this.getJobs()
      }
    },
    showCheckin(job) {
      this.oldCheckinTime = job.checkedIn
      if (!job.checkedIn) {
        let currentTime = (new Date()).toLocaleTimeString().substring(0, 5)
        if (currentTime < job.Soll_Von || parseInt(job.Soll_Von.substring(0, 2)) - parseInt(currentTime.substring(0, 2)) > 16) {
          job.checkedIn = job.Soll_Von
        } else {
          job.checkedIn = currentTime
        }
      }
      this.checkinJob = job
      let vm = this
      setTimeout(() => {
        CASP.getPicture(this.token, this.checkinJob.ma_data, (res) => {
          vm.checkinPicture = res.image
        })
      }, 200)

    },
    getJobs() {
      
      let vm = this;
      this.loading = true;
      this.filter.start = this.start.substring(0, 10);
      this.filter.end = this.end.substring(0, 10);
      if (this.filter.location == "") {
        this.filter.checkinRequired = true
      } else {
        delete this.filter.checkinRequired;
        this.filter.earliestStart = this.start.substring(11, 16)
        this.filter.latestStart = this.end.substring(11, 16)
        this.startSocket();
      }
      CASP.getAdminJobs(this.token, this.filter, (res) => {
        vm.jobs = res.jobs;
        vm.loading = false;
      });
    },
    startSocket() {
      const vm = this
      this.connection = new WebSocket(process.env.VUE_APP_WS_URL + '/checkin')
      this.connection.onopen = function (event) {
        setTimeout(() => {
          vm.connection.send(JSON.stringify({ type: "init", token: vm.token, filter: vm.filter }))
        }, 500)

      }
      this.connection.onmessage = function (event) {
        let data = JSON.parse(event.data)
        if (data.type === "checkin") {
          vm.showCheckin(data.job)
          let newJobs = []
          vm.jobs.forEach((job) => {
            if (job.id == data.job.id) {
              newJobs.push(data.job)
            } else {
              newJobs.push(job)
            }
          })
          vm.jobs = newJobs;
        } else if (data.type == "update") {
        }
      }
    },
    getPresets() {
      let vm = this;
      CASP.getPresets(this.token, (res) => {
        if (res) {
          this.allContracts = res.contracts
          vm.allLocations = {};
          Object.keys(res.contracts).forEach(contract => {
            Object.keys(res.contracts[contract].locations).forEach(location => {
              if (vm.allLocations[location]) {
                Object.keys(res.contracts[contract].locations[location].places).forEach(place => {
                  vm.allLocations[location].places[place] = res.contracts[contract].locations[location].places[place]
                })
              }
              else {
                vm.allLocations[location] = res.contracts[contract].locations[location]
              }
            })
          })
        }
      });
    },
    clearFilter() {
      this.filter = {
        contract: "",
        location: "",
        place: ""
      }
      this.filteredLocations = []
      this.filteredPlaces = []
    },
    setContract(contract) {
      console.log(contract)
      this.filter.contract = contract
      this.filteredLocations = Object.keys(this.allContracts[contract].locations)
      if (this.filteredLocations.length == 1) {
        this.setLocation(this.filteredLocations[0])
      } else {
        this.filter.location = ""
        this.filter.place = ""
      }
    },
    setLocation(location) {
      this.filter.location = location
      this.filteredPlaces = Object.keys(this.allLocations[location].places)
      if (this.filteredPlaces.length == 1) {
        this.filter.place = this.filteredPlaces[0]
      } else {
        this.filter.place = ""
      }
    },
    filterDropdown(event, data, reference) {
      this.dropdownRef = reference;
      let options = "";
      data.forEach((el) => {
        if (el.toLowerCase().includes(event.target.value.toLowerCase())) {
          options += "<p class='dp'>" + el + "</p>";
        }
      });
      if (this.$refs.dropdown) {
        this.showDropdown = true;
        this.$refs.dropdown.innerHTML = options;
        event.target.parentNode.appendChild(this.$refs.dropdown)
      }
    },
  },
  created: function () {
    let today = new Date();
    today.setHours(today.getHours() + 1)
    this.start = today.toISOString().substring(0, 14) + "00";
    today.setDate(today.getDate() + 1);
    this.end = today.toISOString().substring(0, 14) + "00";
    document.getElementById("mainspinner").style.display = "none";
    if (this.cookies.get("authtoken")) {
      this.token = this.cookies.get("authtoken");
      CASP.isAuthenticated(this.token, (auth) => {
        if (!auth) {
          window.location = "/admin/login";
        } else {
          this.getJobs();
          this.getPresets();
        }
      });
    } else {
      window.location = "/admin/login";
    }
  },
};
</script>
<style scoped></style>