<template>
  <div class="w-96 center top-14 z-30">
    <div v-bind:class="{'bg-green-500': mode=='success', 'bg-red-400': mode=='error'}" class="w-full py-5 px-3 relative rounded-md text-center ">
    <span class="w-full text-lg py-3 font-bold text-white">{{text}}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    mode: String,
    text: String
  },
};
</script>
