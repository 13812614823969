<template >
  <toolbar title="Persönliche Daten" />
  <div class="w-full text-center">
    <div
      class="w-full h-14 text-black bg-caspyellow text-center border-b-2 border-b-black"
    >
      <span class="text-3xl mt-2 inline-block font-bold tracking-widest"
        >Persönliche Daten</span
      >
    </div>
    <div class="max-w-5xl inline-block pt-10">
      <div class="text-left">
        <div class="grid grid-cols-2">
          <div>
            <span class="font-bold text-lg">Name</span><br />
            <span class="text-lg">{{ user.given_name }}</span
            ><br /><br />
            <span class="font-bold text-lg">Vorname</span><br />
            <span class="text-lg">{{ user.family_name }}</span
            ><br /><br />
          </div>
          <div class="text-center">
            <i
              v-show="user.pictureId == ''"
              class="fa fa-user-tie fa-8x inline"
            />
            <img v-show="user.pictureId" id="img" class="w-32 inline" />
          </div>
          </div>
          <span class="font-bold text-lg">Anschrift</span><br />
          <span class="text-lg">{{ user.street }}</span
          ><br />
          <span class="text-lg">{{ user.zip }} {{ user.city }}</span
          ><br /><br />
          <span class="font-bold text-lg">Geburtsdatum</span><br />
          <span class="text-lg">{{ user.birthdate }}</span
          ><br /><br />
          <span class="font-bold text-lg">Mobilnummer</span><br />
          <span class="text-lg">{{ user.phone }}</span
          ><br /><br />
          <span class="font-bold text-lg">Ausbildung</span><br />
          <span class="text-lg">{{ user.training }}</span
          ><br /><br />
      </div>
      <a
        :href="
          'mailto:backoffice@casp-security.com?subject=Datenänderung&body=Hallo,%0A%0AIch würde gerne folgende Daten ändern:%0A...%0A%0AViele Grüße%0A' +
          user.given_name +
          ' ' +
          user.family_name
        "
        class="inline-block mt-10 border-caspyellow text-black bg-caspyellow rounded-lg w-28 xl:w-56 xs:w-72 py-2 border-2 font-bold xl:m-10"
      >
        Daten ändern (E-Mail)
      </a>
    </div>
  </div>
</template>
<script>
import Toolbar from "@/components/Toolbar.vue";
import CASP from "../../CASP";
import QR from "@/lib/QR.js";

import { useCookies } from "vue3-cookies";
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      user: {},
    };
  },
  components: { Toolbar, QR },
  created: function () {
    document.getElementById("mainspinner").style.display = "none";
    if (this.cookies.get("authtoken")) {
      this.token = this.cookies.get("authtoken");
    } else {
      window.location = "/forbidden";
    }
    let comp = this;
    CASP.getUser(this.token, (status, user) => {
      if (status == 200) {
       comp.user = JSON.parse(user);
        if (user.pictureId != "") {
          CASP.getFile(this.token, comp.user.pictureId, (response, type) => {
            document.getElementById("img").src =
              "data:" + type + ";base64," + CASP.arrayBufferToBase64(response);
          });
        }
      }
    });
  },
};
</script>
