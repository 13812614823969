<template>
  <toolbar title="Dokumente" />

  <div class="w-full text-center">
    <div class="w-full md:w-5/6 inline-block text-left">
      <div class="tab">
        <button v-bind:class="{ tabactive: show == 'job' }" @click="show = 'job'">
          Schicht
        </button>
        <button v-bind:class="{ tabactive: show == 'public' }" @click="show = 'public'" class="md:hidden">
          Öffentlich
        </button>
        <button v-bind:class="{ tabactive: show == 'pay' }" @click="show = 'pay'" class="md:hidden">
          Lohn
        </button>
        <button v-bind:class="{ tabactive: show == 'misc' }" @click="show = 'misc'" class="md:hidden">
          Sonstiges
        </button>
      </div>
      <div v-if="show == 'job'" class="w-full relative text-center">
        <span v-if="jobdocuments && jobdocuments.length == 0" class="inline-block mt-4">Keine Dokumente vorhanden</span>
        <div v-else v-for="doc in jobdocuments" :key="doc" class="
        w-full
        border-b border-b-gray-200
        py-2
        pl-2
        text-left
        cursor-pointer
      " @click="viewFile(doc)">
          <span class="align-middle font-bold inline-block w-5/6">
          {{doc.document.contract}} ({{doc.document.date}}, {{ doc.type }})
        </span>
          <i :class="{ 'text-green-500': !doc.needToSign, 'text-red-500': doc.needToSign }" class="fa fa-signature text-3xl align-middle float-right mt-1 mr-5"  />
        </div>
      </div>
      <div v-if="show == 'public'" class="w-full relative text-center">
        <span v-if="files.public && files.public.length == 0" class="inline-block mt-4">Keine Dokumente vorhanden</span>
        <div v-else v-for="file in files.public" :key="file" class="
        w-full
        border-b border-b-gray-200
        py-2
        pl-2
        text-left
        cursor-pointer
      " @click="loadFile(file)">
          <span class="align-middle font-bold inline-block w-5/6">{{
          file.name
        }}</span>
          <i class="fa fa-download align-middle float-right mt-1 mr-5 text-gray-500" />
        </div>
      </div>
      <div v-if="show == 'pay'" class="w-full relative text-center">
        <span v-if="files.pay && files.pay.length == 0" class="inline-block mt-4">Keine Dokumente vorhanden</span>
        <div v-else v-for="file in files.pay" :key="file" class="
        w-full
        border-b border-b-gray-200
        py-2
        pl-2
        text-left
        cursor-pointer
      " @click="loadFile(file)">
          <span class="align-middle font-bold inline-block w-5/6">{{
          file.name
        }}</span>
          <i class="fa fa-download align-middle float-right mt-1 mr-5 text-gray-500" />
        </div>
      </div>
      <div v-if="show == 'misc'" class="w-full relative text-center">
        <span v-if="files.misc && files.misc.length == 0" class="inline-block mt-4">Keine Dokumente vorhanden</span>
        <div v-else v-for="file in files.misc" :key="file" class="
        w-full
        border-b border-b-gray-200
        py-2
        pl-2
        text-left
        cursor-pointer
      " @click="loadFile(file)">
          <span class="align-middle font-bold inline-block w-5/6">{{
            file.name
            }}</span>
          <i class="fa fa-download align-middle float-right mt-1 mr-5 text-gray-500" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Toolbar from "@/components/Toolbar.vue";
import CASP from "../../CASP";
import { useCookies } from "vue3-cookies";
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  components: { Toolbar },
  data() {
    return {
      files: [],
      jobdocuments: [],
      show: "job",
    };
  },
  methods: {
    getJobDocuments() {
      CASP.getJobDocumentsForUser((s, r) => {
        if (s == 200) {
          this.jobdocuments = r
        }
      })
    },
    viewFile(file){
      if(file.signed){
        window.location="/jobs/"+file.job+"/documents/"+file.id
      }else{
        window.location="/jobs/"+file.job+"/documents/"+file.id+"/edit"
      }
    },
    loadFile(file) {
      CASP.getFileOTL(this.token, file.id, (s, r) => {
        if (s == 200) {
          window.location = process.env.VUE_APP_API_URL + JSON.parse(r).link;
        }
      });
    },
  },
  created: function () {
    document.getElementById("mainspinner").style.display = "none";
    if (this.cookies.get("authtoken")) {
      this.token = this.cookies.get("authtoken");
    } else {
      window.location = "/forbidden";
    }
    let vm = this;
    CASP.getFiles(this.token, (s, files) => {
      if (s == 200) {
        vm.files = JSON.parse(files);
      }
    });
    this.getJobDocuments()
  },
};
</script>
<style scoped>
button {
  color: gray;
  border: none;
  border-bottom: 3px solid gray;
  outline: none;
  cursor: pointer;
  background: inherit;
  padding: 14px 16px;
  transition: 0.3s;
  flex-grow: 1;
}

/* Create an active/current tablink class */
.tabactive {
  color: #007aff;
  border-bottom: 3px solid rgb(0, 122, 255);
}

/* Style the tab content */
.tabcontent {
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
</style>
