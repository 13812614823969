<template>
  <div class="fixed w-full z-10 text-center">
    <img class="w-full lg:mt-24 inline sm:hidden" src="@/assets/banner.jpg" />
    <img class="w-full sm:inline hidden" src="@/assets/DesktopBanner.png" />
  </div>
  <div class="relative w-full h-full text-center overflow-y-auto p-4">
    <div
      class="relative w-full text-center pt-36 sm:pt-[10%] xl:w-2/3 md:inline-block"
    >
      <button
        class="hidden md:inline absolute left-0 text-black bg-caspyellow border-4 border-gray-500 rounded-lg w-32 py-2 font-bold"
        onclick="window.location='/admin'"
      >
        Zurück
      </button>
      <span class="text-4xl font-bold block">Schicht-Dokumente</span>
      <br />
      <div class="w-full text-left">
        <span class="font-bold text-xl">Offene Dokumente</span>
        <table>
          <thead>
            <tr>
              <th>Vertrag</th>
              <th>Standort</th>
              <th>Typ</th>
              <th>Datum</th>
              <th>Verfasser</th>
              <th class="text-center">Aktionen</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="document in openDocuments" :key="document.id">
              <td>{{ document.jobdata.Vertragsbeschreibung }}</td>
              <td>{{ document.jobdata.Einsatzort_Beschreibung }}</td>
              <td>{{ document.type }}</td>
              <td>{{ document.document.date }}</td>
              <td>{{ document.document.author.name }}</td>
              <td>
                <button
                  class="bg-gray-500 text-white py-2 px-4 rounded-lg font-bold"
                  @click="viewDocument(document)"
                >
                  Anzeigen
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="w-full text-left">
        <span class="font-bold text-xl">Filtern nach Vertrag</span>
        <div class="mt-2">
          <label class="font-bold mr-3">Objekt-Filter:</label>
          <input
            v-model="filter"
            class="border-b-2 w-64 mr-2 p-2 border-gray-500"
            placeholder="Filter"
          />
          <div class="inline-block cursor-pointer pt-2" @click="filter = ''">
            <i class="!inline-block fa fa-xmark text-xl align-middle"></i>
          </div>
        </div>
        <div class="relative w-full xl:inline-block">
          <div
            class="inline-block px-3 py-2 rounded-lg shadow-lg m-1 font-bold cursor-pointer bg-white"
            :class="{ '!bg-caspyellow': selectedContract == contract }"
            v-for="contract in Object.keys(contracts)"
            :key="contract.id"
            @click="selectContract(contract)"
          >
            {{ contract }}
          </div>
        </div>
        <div v-if="selectedContract">
          <span class="text-xl font-bold block"
            >Standorte für {{ selectedContract }}:</span
          >
          <div class="relative w-full xl:inline-block">
            <div
              class="inline-block px-3 py-2 rounded-lg shadow-lg m-1 font-bold cursor-pointer bg-white"
              :class="{ '!bg-caspyellow': selectedLocation == location }"
              v-for="location in Object.keys(
                allContracts[selectedContract].locations
              )"
              :key="location.id"
              @click="selectLocation(location)"
            >
              {{ location }}
            </div>
          </div>

          <div v-if="selectedLocation">
            <div class="w-full mt-3 bg-white p-2 rounded-lg min-w-fit">
              <div
                v-if="!locationInfo.documentsEnabled"
                class="w-full text-center"
              >
                <button
                  class="text-white font-bold bg-green-500 inline-block"
                  @click="setDocuments(true)"
                >
                  Dokumente aktivieren
                </button>
              </div>
              <div v-else>
                <div class="cursor-pointer inline-block mr-5">
                  <span
                    class="inline-block font-bold border-b-2 border-black text-xl"
                    :class="{
                      '!border-caspyellow': selectedTab == 'Dokumente',
                    }"
                    @click="selectedTab = 'Dokumente'"
                    >Dokumente</span
                  >
                </div>
                <div
                  class="cursor-pointer inline-block"
                  v-show="stats.permissions.internalDispo"
                >
                  <span
                    class="inline-block font-bold border-b-2 border-black text-xl"
                    :class="{
                      '!border-caspyellow': selectedTab == 'Zugriffsrechte',
                    }"
                    @click="selectedTab = 'Zugriffsrechte'"
                    >Zugriffsrechte</span
                  >
                </div>
                <div
                  v-if="selectedTab == 'Dokumente'"
                  class="relative w-full xl:inline-block"
                >
                  <div>
                    <label class="font-bold mr-4">Tag:</label>
                    <input
                      type="date"
                      v-model="selectedDate"
                      @change="getLocationInfo()"
                    />
                    <button
                      class="float-right text-white font-bold bg-red-500"
                      @click="setDocuments(false)"
                    >
                      Dokumente deaktivieren
                    </button>
                  </div>
                  <div>
                    <table>
                      <thead>
                        <tr>
                          <th>Erstellt</th>
                          <th>Dokument</th>
                          <th>Verfasser</th>
                          <th class="text-center">Aktionen</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="document in locationInfo.docs"
                          :key="document.id"
                        >
                          <td>
                            {{
                              new Date(document.createdAt).toLocaleDateString()
                            }}
                          </td>
                          <td>{{ document.type }}</td>
                          <td>{{ document.document.author.name }}</td>
                          <td class="text-center">
                            <button class="m-1" @click="viewDocument(document)">
                              Ansehen
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  v-else-if="selectedTab == 'Zugriffsrechte'"
                  class="relative w-full xl:inline-block"
                >
                  <div class="relative">
                    <table class="w-full min-w-0">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>E-Mail</th>
                          <th>Erteilt von</th>
                          <th>Kommentar</th>
                          <th class="text-center">Aktionen</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="permission in locationInfo.permissions"
                          :key="permission.id"
                        >
                          <td>
                            <input
                              v-if="permission.user.id == -1"
                              class="border-b-2"
                              v-model="permission.user.name"
                            /><span v-else>{{ permission.user.name }}</span>
                          </td>
                          <td>
                            <input
                              v-if="permission.user.id == -1"
                              class="border-b-2"
                              v-model="permission.user.mail"
                            /><span v-else>{{ permission.user.mail }}</span>
                          </td>
                          <td>{{ permission.createdBy }}</td>
                          <td>
                            <input
                              class="border-b-2"
                              v-model="permission.comment"
                            />
                          </td>
                          <td class="text-center">
                            <button
                              class="m-1"
                              v-if="permission.user.id != -1"
                              @click="
                                deleteExternalDocumentPermission(permission)
                              "
                            >
                              Löschen
                            </button>
                            <button
                              class="m-1"
                              @click="updateDocumentUser(permission)"
                            >
                              Speichern
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CASP from "@/CASP.js";
export default {
  name: "AdminDocuments",
  data() {
    return {
      allContracts: {},
      contracts: {},
      filter: "",
      selectedContract: null,
      selectedLocation: null,
      selectedDate: new Date().toISOString().substr(0, 10),
      openDocuments: [],
      stats: {},
      selectedTab: "Dokumente",
      locationInfo: {},
    };
  },
  methods: {
    setDocuments(value) {
      CASP.updateLocation(
        this.selectedContract,
        this.selectedLocation,
        { documentsEnabled: value },
        (status, res) => {
          this.getLocationInfo();
        }
      );
    },
    getContracts() {
      CASP.getPresets("", (presets) => {
        this.allContracts = presets.contracts;
        delete this.allContracts[""];
        this.contracts = this.allContracts;
      });
    },
    viewDocument(document) {
      window.location = "/jobs/" + document.job + "/documents/" + document.id;
    },
    getStats() {
      CASP.getStats("", (status, response) => {
        if (status == 200) {
          this.stats = JSON.parse(response);
        }
      });
    },
    selectContract(contract) {
      this.filter = contract;
      this.selectedContract = contract;
    },
    getOpenDocuments() {
      CASP.getOpenDocuments((status, res) => {
        this.openDocuments = res.filter((x) => x.jobdata !== null);
      });
    },
    getDocumentUsers() {
      CASP.getDocumentUsers(
        this.selectedContract,
        this.selectedLocation,
        (status, res) => {
          this.permissions = res;
          this.permissions.push({
            user: {
              id: -1,
              name: "",
              mail: "",
            },
            comment: "",
            createdBy: "",
          });
        }
      );
    },
    updateDocumentUser(permission) {
      permission.location = this.selectedLocation;
      permission.contract = this.selectedContract;
      CASP.updateDocumentUser(permission, (status, res) => {
        if (status == 200) {
          this.getDocumentUsers();
        }
      });
    },
    deleteExternalDocumentPermission(user) {
      CASP.deleteExternalDocumentPermission(user, (status, res) => {
        if (status == 200) {
          this.getDocumentUsers();
        }
      });
    },
    selectLocation(location) {
      this.selectedLocation = location;
      this.getLocationInfo();
    },
    getLocationInfo() {
      CASP.getLocationInfo(
        this.selectedContract,
        this.selectedLocation,
        this.selectedDate,
        (status, res) => {
          this.locationInfo = res;
          this.locationInfo.permissions.push({
            user: {
              id: -1,
              name: "",
              mail: "",
            },
            comment: "",
            createdBy: "",
          });
        }
      );
    },
    filterContracts() {
      this.contracts = JSON.parse(JSON.stringify(this.allContracts));
      for (const [key, value] of Object.entries(this.allContracts)) {
        if (!key.toLowerCase().includes(this.filter.toLowerCase())) {
          delete this.contracts[key];
        }
      }
    },
  },
  watch: {
    filter: function () {
      this.filterContracts();
    },
  },
  created: function () {
    this.getStats();
    this.getContracts();
    this.getOpenDocuments();
    document.getElementById("mainspinner").style.display = "none";
  },
};
</script>