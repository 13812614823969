<template>
      <div v-if="message" class="fixed bottom-4 right-4  text-white p-4 rounded-lg text-lg font-bold w-56" :class="{ 'bg-green-500': successMode, 'bg-red-500': !successMode }">{{message}}</div>
</template>

<script>
export default {
  name: 'Banner',
  data: function () {
    return {
      message: '',
      successMode: true
    }
  },
  methods: {
    success(message) {
      this.message = message
      this.successMode = true
      setTimeout(() => {
        this.message = ''
      },2000)
    },
    failure(message) {  
      this.message = message
      this.successMode = false
      setTimeout(() => {
        this.message = ''
      },2000)
    }
  }
}
</script>