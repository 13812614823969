<template>
  <div>
    <div
      class="absolute right-4 text-2xl bottom-3 text-gray-500 cursor-pointer"
      @click="edit = ''"
    >
      <i class="fa fa-xmark" />
    </div>
    <input
      v-model="edit"
      :placeholder="placeholder"
      @click="filter(true)"
      class="border-b-2 w-64 p-2 border-gray-500"
      @input="filter()"
    />
    <div
      v-show="showDropdown"
      @click="choose"
      ref="dropdown"
      class="z-10 border-gray-500 absolute p-2 bg-white max-h-96 overflow-y-auto"
    ></div>
  </div>
</template>

<script>
export default {
  name: "Autocomplete",
  props: {
    list: Array,
    placeholder: String,
    value: String,
  },
  data() {
    return {
      showDropdown: false,
      edit: "",
    };
  },
  methods: {
    choose(event) {
      this.edit = event.target.innerText;
      this.$emit("update:value", event.target.innerText);
      this.showDropdown = false;
    },
    filter(click = false) {
      if (click) {
        this.showDropdown = !this.showDropdown;
      }else{
        this.showDropdown=true
      }
      console.log(this.showDropdown);
      let options = "";
      this.list.forEach((el) => {
        if (el.toLowerCase().includes(this.edit.toLowerCase())) {
          options += "<p class='dp'>" + el + "</p>";
        }
      });
      if (this.$refs["dropdown"]) {
        console.log("setting");
        this.$refs["dropdown"].innerHTML = options;
      }
    },
  },
  created: function () {
    this.edit=""+this.value
  }
};
</script>
