<template>
  <toolbar
    title="Wächter-Kontrollgang"
    :showReload="true"
    @reload="reloadAll()"
    :backUrl="'/job/'+$route.params.jobid"
  />
  <div v-if="oldAppError" class="w-full text-center">
    <span class="w-3/4 bg-red-400 font-bold p-10 rounded-xl mt-10 inline-block"
      >Deine App ist zu alt, um diese Funktion zu nutzen. Bitte lade dir die
      neuste Version der App hier herunter:<br />
      <a
        href="https://trit-solutions.com/caspapps/"
        class="!text-white !mt-4 inline-block"
        >Neue Version</a
      ></span
    >
  </div>
  <div v-if="stats.currentJob" class="md:p-4">
    <div v-if="stats.currentJob" class="text-center">
      <span class="font-bold w-full text-2xl"
        >{{ stats.currentJob.Einsatzstelle_Beschreibung }} ({{
          stats.currentJob.Datum_von
        }})</span
      >
    </div>
    <div class="w-full text-left overflow-x-auto">
      <template v-for="route in stats.currentJob.routes" :key="route">
        <span class="font-bold text-xl mt-4 inline-block">{{
          route.name
        }}</span>
        <table class="!mt-0">
          <thead>
            <th>Nr.</th>
            <th>Beginn</th>
            <th>Status</th>
            <th>Aktionen</th>
          </thead>
          <tbody>
            <tr v-for="(tour, index) in tours.filter(x=>x.route==route.id)" :key="tour">
              <td>{{ index + 1 }}</td>
              <td>{{ tour.startedAt }}</td>
              <td>
                ({{ tour.waypointscans.length }} / {{ route.waypoints.length }})
              </td>
              <td><button @click="continueTour(tour)">Fortsetzen</button></td>
            </tr>
            <tr>
              <td colspan="5" class="text-center">
                <button @click="startTour(route)">
                  Neuen Rundgang beginnen
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </div>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar.vue";
import CASP from "../../CASP";
export default {
  name: "EWKSList",
  components: {
    toolbar,
  },
  data() {
    return {
      stats: {},
      window: window,
      oldAppError: false,
      tours:[]
    };
  },
  methods: {
    reloadRoutes() {
      CASP.getRoutesForJob(this.$route.params.jobid, (status, response) => {
        if (status == 200) {
          this.stats.currentJob.routes = response;
          localStorage.setItem("stats", JSON.stringify(this.stats));
        }
      });
    },
    reloadAll(){
      CASP.getStats("", (status, response) => {
        this.getStats()
      })
    },
    getStats() {
      if (localStorage && localStorage.getItem("stats")) {
        this.stats = JSON.parse(localStorage.getItem("stats"));
        this.tours=this.stats.currentJob.tours;
        if(localStorage.getItem("local")){
          let local=JSON.parse(localStorage.getItem("local"));
          if(local.tours){
            this.tours=this.tours.concat(local.tours);
          }
          if(local.waypointscans){
            for(let i=0;i<local.waypointscans.length;i++){
              let tour=this.tours.find(x=>x.id==local.waypointscans[i].tour);
              if(tour){
                tour.waypointscans.push(local.waypointscans[i]);
              }
            }
          }
        }
        if (!this.stats.currentJob) {
          window.location = "/";
        }
      }
    },
    continueTour(tour) {
      window.location = "/tours/" + tour.id;
    },
    startTour(route) {
      window.location =
        "/jobs/" + this.$route.params.jobid + "/tours/" + route.id + "/new";
    },
    getRoutes() {
      CASP.getRoutes(this.token, (status, response) => {});
    },
  },
  created: function () {
    document.getElementById("mainspinner").style.display = "none";
    if (!localStorage) {
      this.oldAppError = true;
      return;
    }
    this.getStats();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
