<template>
  <div class="job" style="padding: 5px">
    <div class="column a">
      <span style="font-size: 20px; font-weight: bold">{{ job.title }}</span>
      <div class="detailrow">
        <svg class="svg-inline--fa fa-calendar-days fa-xl" style="padding-right: 5px;" aria-hidden="true" focusable="false" data-prefix="far" data-icon="calendar-days" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z"></path></svg><span style="font-weight: bold">{{ job.date }}</span>
      </div>
      <div class="detailrow">
        <svg class="svg-inline--fa fa-clock fa-xl" style="padding-right: 5px;" aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"></path></svg>
        <span v-if="job.timeConfirmed" style="font-weight: bold">{{
        job.time
      }}</span>
        <div v-else>
          <input id="{{job.id}}" type="hidden" />
          <input :placeholder="job.time" v-model="value" style="width: 13ch" />
          <span style="padding-left: 5px; color: red">{{ error }}</span>
          <span style="padding-left: 5px; color: green">{{ success }}</span>
        </div>
      </div>
      <div class="detailrow">
        <svg class="svg-inline--fa fa-location-dot fa-xl" style="padding-right: 5px;" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="location-dot" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="currentColor" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"></path></svg><span style="font-weight: bold">{{ job.location }}</span>
      </div>
    </div>
    <div class="column b text-center flex-grow mr-3">
      <svg v-if="job.timeConfirmed" class="svg-inline--fa fa-circle-check fa-2x text-green-500 inline-block" aria-hidden="true" focusable="false" data-prefix="far" data-icon="circle-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"></path></svg>
      <div v-else style="
          cursor: pointer;
          flex-grow: 1;
          color: black;
          display: table-cell;
        " class="text-center pr-2" @click="save()"><svg class="svg-inline--fa fa-floppy-disk inline fa-2x" aria-hidden="true" focusable="false" data-prefix="far" data-icon="floppy-disk" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M48 96V416c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V170.5c0-4.2-1.7-8.3-4.7-11.3l33.9-33.9c12 12 18.7 28.3 18.7 45.3V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H309.5c17 0 33.3 6.7 45.3 18.7l74.5 74.5-33.9 33.9L320.8 84.7c-.3-.3-.5-.5-.8-.8V184c0 13.3-10.7 24-24 24H104c-13.3 0-24-10.7-24-24V80H64c-8.8 0-16 7.2-16 16zm80-16v80H272V80H128zm32 240a64 64 0 1 1 128 0 64 64 0 1 1 -128 0z"></path></svg></div>
      <div @click="window.location = '/jobs/' + job.id + '/documents'" v-if="job.documentsEnabled">
        <svg class="svg-inline--fa fa-folder-open ml-2 fa-2x inline-block cursor-pointer" aria-hidden="true" focusable="false" data-prefix="far" data-icon="folder-open" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M384 480h48c11.4 0 21.9-6 27.6-15.9l112-192c5.8-9.9 5.8-22.1 .1-32.1S555.5 224 544 224H144c-11.4 0-21.9 6-27.6 15.9L48 357.1V96c0-8.8 7.2-16 16-16H181.5c4.2 0 8.3 1.7 11.3 4.7l26.5 26.5c21 21 49.5 32.8 79.2 32.8H416c8.8 0 16 7.2 16 16v32h48V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H87.7 384z"></path></svg>
      </div>
    </div>
  </div>
</template>

<script>
import CASP from '../CASP'
import { useCookies } from 'vue3-cookies'

export default {
  setup() {
    const { cookies } = useCookies()
    return { cookies }
  },
  name: 'Archivejob',
  props: {
    job: Object
  },
  data() {
    return {
      window: window,
      value: '',
      error: '',
      success: ''
    }
  },
  methods: {
    msg(msg, error = false) {
      if (error) {
        this.error = msg
        this.success = ''
      } else {
        this.success = msg
        this.error = ''
      }
    },
    save() {
      if (this.value.includes('-')) {
        const parts = this.value.split('-')
        if (parts.length === 2) {
          const von = parts[0].trim()
          const bis = parts[1].trim()
          if (
            von.match(/^[0-9]?[0-9]:[0-9][0-9]$/) &&
            bis.match(/^[0-9]?[0-9]:[0-9][0-9]$/)
          ) {
            if (parseInt(von.split(':')[0]) > 23 || parseInt(von.split(':')[1]) > 59 || parseInt(bis.split(':')[0]) > 23 || parseInt(bis.split(':')[1]) > 59) {
              this.msg('Eingabe falsch', true)
            } else {
              CASP.setAttendance(
                this.cookies.get('authtoken'),
                this.job.id,
                von,
                bis,
                (response) => {
                  if (response.status === 204) {
                    this.msg('Zeit gespeichert')
                  } else if (response.status === 400) {
                    this.msg(response.responseText, true)
                  } else {
                    this.msg('Da hat etwas nicht geklappt', true)
                  }
                }
              )
            }
          } else {
            this.msg('Zeitangabe falsch formatiert', true)
          }
        }
      } else {
        if (this.value.trim() === '') {
          this.msg('Das Feld ist leer', true)
        } else {
          this.msg('Zeitangabe falsch formatiert', true)
        }
      }
    }
  },
  created: function () {
    if (this.job.time !== 'XX:XX - XX:XX') {
      this.value = this.job.time
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.column {
  float: left;
}

.a {
  width: 80%;
}

.b {
  width: 20%;
  height: 100%;
  text-align: right;
  position: absolute;
  display: table;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
}

.job {
  display: table;
  position: relative;
  overflow: hidden;
  clear: both;
  content: "";
  width: 100%;
  vertical-align: center;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.detailrow {
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

button {
  cursor: pointer;
  padding: 10px 14px;
  display: inline-block;
  color: rgb(52, 199, 89);
  border: 2px solid rgb(52, 199, 89);
  border-radius: 8px;
  font-weight: bold;
  font-size: 18px;
  width: 40%;
  background-color: white;
}

input {
  outline: none;
  border: unset;
  border-bottom: 2px solid rgb(0, 0, 0);
}

input:focus {
  outline: none;
  border: unset;
  border-bottom: 2px solid green;
}
</style>
