<template>
  <toolbar
    title="Aufgaben"
    :showReload="true"
    @reload="getOpenTasks()"
    :backUrl="'/'"
  />
  <div class="w-full text-center">
    <div class="w-full md:w-5/6 inline-block text-left">
      <Spinner v-if="loading" />
      <div class="tab">
        <button
          style="flex-grow: 4; font-size: 15px"
          v-bind:class="{ tabactive: showOpenTasks }"
          class="singletab"
          @click="setTasks()"
        >
          Offen
        </button>
        <button
          style="font-size: 15px"
          v-bind:class="{ tabactive: showSolvedTasks }"
          class="singletab"
          @click="setClosed()"
        >
          Erledigt
        </button>
        <button
          style="font-size: 15px"
          v-bind:class="{ tabactive: showNew }"
          class="text-red-500 singletab"
          @click="setNew()"
        >
          Neu
        </button>
      </div>
      <div v-if="showOpenTasks || showSolvedTasks" style="padding: 3px">
        <div v-if="tasks.length == 0" class="pt-10 text-center">
          <span class="font-bold inline-block"
            >Aktuell keine offenen Aufgaben</span
          >
        </div>
        <template v-for="task in tasks" :key="task.id">
          <div class="w-full p-2 border-b border-black" @click="viewTask(task)">
            <span class="font-bold text-xl">{{ task.summary }}</span>
            <div
              class="text-sm font-bold inline-block p-1 rounded ml-2"
              :class="{
                'text-red-800 bg-red-200 border-red-800':
                  task.status == 'Offen',
                'text-yellow-800 bg-yellow-200 border-yellow-800':
                  task.status == 'In Bearbeitung',
                'text-green-800 bg-green-200 border-green-800':
                  task.status == 'Erledigt',
              }"
            >
              {{ task.status }}
            </div>
            <div class="">
              <i class="fa fa-user"></i>
              <span
                v-if="task.assignedTo == null"
                class="pl-1 font-bold text-red-500"
                >Nicht zugewiesen</span
              >
              <span v-else class="pl-1">{{ task.assignedName }}</span>
            </div>
            <div class="pt-2">
              <pre class="font-sans w-full whitespace-pre-wrap">{{
                task.description
              }}</pre>
            </div>
          </div>
        </template>
      </div>
      <div v-if="showNew">
        <div class="job" style="padding: 15px; display: block">
          <div v-if="error">
            <h3 style="color: red">{{ error }}</h3>
          </div>
          <div style="margin-top: 15px; margin-bottom: 15px">
            <div>
              <span style="font-weight: bold">Titel:</span>
            </div>
            <input v-model="newTask.summary" class="px-2 py-1 w-full" />
          </div>
          <div style="margin-top: 15px; margin-bottom: 15px">
            <span
              style="
                display: inline-block;
                font-weight: bold;
                margin-bottom: 5px;
              "
              >Beschreibung:</span
            >
            <div>
              <textarea
                v-model="newTask.description"
                class="p-2 box-border w-full"
                rows="3"
                @focus="resizeTextarea"
                @keyup="resizeTextarea"
              ></textarea>
            </div>
            <span style="font-weight: bold">Zuweisen:</span>
            <select v-model="newTask.assignedTo" class="w-full px-2 py-1">
              <option value="">Nicht zugewiesen</option>
              <option
                v-for="contact in taskContacts"
                :key="contact"
                :value="contact.id"
              >
                {{ contact.name }} {{ contact.surname }}
              </option>
            </select>
          </div>
          <div class="">
            <span class="font-bold inline-block">Dateien:</span><br />
            <div v-for="file in newTask.files" :key="file" class="m-2 inline-block">
              <div class="text-blue-500 font-bold border-b-2 border-blue-500 mr-2">{{ file.name }}</div>
            </div>
            <div
              class="font-bold px-6 border-4 border-blue-500 rounded text-blue-500 inline-block m-2 cursor-pointer"
              @click="addFile()"
            >
              +
            </div>
          </div>
          <div class="w-full text-right">
            <button
              class="bg-white px-3 py-2 border-2 rounded-lg border-blue-500 text-blue-500"
              @click="createTask()"
            >
              Erstellen
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CASP from "../../CASP";
import Spinner from "@/components/Spinner";
import { useCookies } from "vue3-cookies";
import Toolbar from "@/components/Toolbar.vue";

export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  name: "Jobs",
  components: {
    Spinner,
    Toolbar,
  },
  data() {
    return {
      newTask: {
        assignedTo: "",
        files:[]
      },
      showOpenTasks: true,
      showSolvedTasks: false,
      showNew: false,
      taskContacts: [],
      tasks: [],
      token: "",
      newMessage: "",
      contact: "",
      error: false,
      loading: true,
      anonymous: false,
    };
  },
  methods: {
    addFile() {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.addEventListener("change", (event) => {
        let file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
          this.newTask.files.push({ name: file.name, data: event.target.result });
        };
        reader.readAsDataURL(file);
      });
      fileInput.click();
    },
    viewTask(task) {
      window.location = "/tasks/" + task.id;
    },
    hideAll() {
      this.showOpenTasks = false;
      this.showNew = false;
      this.showSolvedTasks = false;
    },
    setNew() {
      this.hideAll();
      this.showNew = true;
    },
    setClosed() {
      this.hideAll();
      this.showSolvedTasks = true;
      this.getClosedTasks();
    },
    setTasks() {
      this.hideAll();
      this.getOpenTasks();
      this.showOpenTasks = true;
    },
    getOpenTasks() {
      const comp = this;
      CASP.getTasks("open", (status, tasks) => {
        comp.tasks = tasks;
        comp.loading = false;
      });
    },
    getClosedTasks() {
      const comp = this;
      CASP.getTasks("closed", (status, tasks) => {
        comp.tasks = tasks;
        comp.loading = false;
      });
    },
    resizeTextarea(e) {
      const area = e.target;
      area.style.overflow = "hidden";
      area.style.height = area.scrollHeight + "px";
    },
    createTask() {
      const comp = this;
      CASP.createTask(this.newTask, (status, response) => {
        if (status == 200) {
          this.setTasks();
        } else {
          comp.error = response.error;
        }
      });
    },
    sendHint() {
      const comp = this;
      CASP.createTask(
        this.token,
        {
          contact: "",
          message: this.newMessage,
          hint: true,
          anonymous: this.anonymous,
        },
        (response) => {
          if (response.success) {
            window.location = "/task/" + response.taskId;
          } else {
            comp.error = response.error;
          }
        }
      );
    },
  },
  created: function () {
    document.getElementById("mainspinner").style.display = "none";
    if (this.cookies.get("authtoken")) {
      this.token = this.cookies.get("authtoken");
    } else {
      window.location = "/forbidden";
    }
    this.getOpenTasks();
    let comp = this;
    CASP.getTaskContacts((status, taskContacts) => {
      comp.taskContacts = taskContacts.filter(
        (contact) => !["PSL1", "PSL3"].includes(contact.persnr)
      );
    });
  },
};
</script>
<style scoped>
.tab {
  overflow: hidden;
  background-color: white;
  display: flex;
}

input:focus {
  outline: none;
  border: unset;
  border-bottom: 2px solid rgb(0, 122, 255);
}

input,
textarea {
  border: unset;
  outline: none;
  border-bottom: 1px solid black;
}

textarea:focus {
  border-bottom: 1px solid rgb(0, 122, 255);
  outline: none;
}

.small {
  flex-grow: 2;
}

.large {
  flex-grow: 1;
}

button {
  font-weight: bold;
}

.unread {
  color: rgb(245, 0, 0);
}

/* Style the buttons that are used to open the tab content */
.singletab {
  color: gray;
  border: none;
  outline: none;
  cursor: pointer;
  background: inherit;
  padding: 14px 16px;
  transition: 0.3s;
  flex-grow: 1;
  border-bottom: 3px solid gray;
}

/* Change background color of buttons on hover
.tab button:hover {
  background-color: rgb(37, 37, 37);
}*/

/* Create an active/current tablink class */
.tabactive {
  color: rgb(0, 122, 255);
  border-bottom: 3px solid rgb(0, 122, 255);
}

/* Style the tab content */
.tabcontent {
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
</style>