<template >
  <Spinner v-if="loading" />
  <div class="w-full h-full relative text-center m-0 p-0" @mousedown="clear(); mousedown = true" @mouseup="mouseup" @keyup.esc="reset"  @keyup.enter="clear()">
    <div class="w-full h-full bg-gray-400 absolute top-0 left-0 z-10" v-if="highlightselected"></div>
    <div class="fixed w-full z-10 text-center">
      <img class="w-full lg:mt-24 inline sm:hidden" src="@/assets/banner.jpg" />
      <img class="w-full sm:inline hidden" src="@/assets/DesktopBanner.png" />
    </div>
    <div class="relative w-full h-full text-center overflow-y-auto">
      <div class="relative w-full text-center pt-48 sm:pt-[10%] xl:w-5/6 xl:inline-block">
        <button class="hidden md:inline absolute left-0 text-black bg-caspyellow border-4 border-gray-500 rounded-lg w-32 py-2 font-bold" onclick="window.location='/admin'">
          Zurück
        </button>
        <span class="font-bold text-4xl">Auftragsplanung</span><br />
        <div id="tablecontent" class="w-full">
          <table class="w-full overflow-x-auto block md:table">
            <thead>
              <th>Lfd</th>
              <th>Check-In</th>
              <th>Beginn</th>
              <th>Aktionen</th>
              <th>Mitarbeiter</th>
              <th>Projekt</th>
              <th>Position</th>
              <th>Aktionen</th>
            </thead>
            <tbody>
              <tr v-for="job in jobs" :key="job.id">
                <td v-if="job==jobs[0]">
                  <input v-model="job.Lfd_Nr" @change="updateLFDs">
                </td>
                <td v-else style="width: 40px;">
                  {{job.Lfd_Nr}}
                </td>
                <td class="text-center">
                  <div v-if="job.checkedIn"><i class="fa fa-check" /> ({{ job.checkedIn }})</div><i v-else class="fa fa-xmark" />
                </td>
                <td class="text-center"><input type="time" @input="write" @mouseleave="mouseleft" @mouseenter="mouseentered" v-model="job.Soll_Von" class="bg-transparent border-gray-500 w-full" style="border-bottom: 1px solid black" /></td>
                <td>
                  <a v-if="job.phone" :href="'tel:' + job.phone" class="mx-1 inline-block font-bold bg-gray-500 rounded p-2 text-white" style="background: #ff3a35; border: #ff3a35">
                    Anrufen
                  </a>
                </td>
                <td><input @input="write" @mouseleave="mouseleft" @mouseenter="mouseentered" v-model="job.Mitarbeiternr" class="bg-transparent border-gray-500 w-full" style="border-bottom: 1px solid black" /></td>
                <td>
                  {{ job.Vertragsbeschreibung }}
                </td>
                <td>
                  {{ job.Pos }}
                </td>
                <td>
                  <button class="bg-gray-500 text-white py-2 px-4 rounded-lg font-bold" @click="copy(job)">Kopieren</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="md:hidden inline-block mt-5">
          <button class=" text-black bg-caspyellow border-4 border-gray-500 rounded-lg w-32 py-2 font-bold" onclick="window.location='/admin'">
            Zurück
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CASP from "../../CASP";
import { useCookies } from "vue3-cookies";
import JobModal from "@/components/JobModal.vue";
import Spinner from "@/components/Spinner";

export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  name: "Jobs",
  components: { JobModal, Spinner },
  data() {
    return {
      mousedown: false,
      loading: false,
      multiinput: [],
      multidefaults: [],
      highlightselected: false,
      markedStyle: "border: 3px solid gray;",
      jobs: [
        {
          "Lfd_Nr": 1,
          "Mitarbeiternr": "PSL20522 (Michel Vanoplinis)",
          "phone": "+49 178 8468210",
          "datum_formatted": "04.09.2023",
          "Vertragsbeschreibung": "Maritim Hotel",
          "Pos": "Objektschutz Nachtwache",
          "id": "1111040000OBJEKTSCHUTZ",
          "Soll_Von": "21:00"
        },
        {
          "Lfd_Nr": 2,
          "Mitarbeiternr": "PSL20771 (Tarik Birge)",
          "phone": "+49 163 4574157",
          "datum_formatted": "04.09.2023",
          "Vertragsbeschreibung": "Saturn Parkhaus",
          "Pos": "Objektschutz Nachtwache",
          "id": "1111340000OBJEKTSCHUTZ",
          "Soll_Von": "22:00"
        }
      ],
    };
  },
  methods: {
    updateLFDs: function (event) {
      for(let i = 0; i < this.jobs.length; i++){
        this.jobs[i].Lfd_Nr = parseInt(event.target.value)+i
      }
    },
    copy: function (job) {
      this.jobs.push(JSON.parse(JSON.stringify(job)))
    },
    write: function (event) {
      this.multiinput.forEach((input) => {
        input.value = event.target.value
      })
    },
    clear: function () {
      this.multiinput.forEach((input) => {
        input.parentNode.style = "";
      })
      this.multiinput = [];
      this.multidefaults = [];
      window.getSelection().removeAllRanges();
    },
    reset: function () {
      console.log("ESC")
      for (let i = 0; i < this.multiinput.length; i++) {
        this.multiinput[i].value = this.multidefaults[i]
      }
      this.clear()
    },
    mouseup: function () {
      this.mousedown = false
      if (this.multiinput.length > 0) {
        let first = this.multiinput[0]
        first.focus()
        first.select()
        if(first.type=="time"){
          first.showPicker()
        }
      }
    },
    mouseleft: function (input) {
      if (this.mousedown && input.target.parentNode.style != this.markedStyle) {
        input.target.parentNode.style = this.markedStyle;
        if (this.multiinput.length == 0) {
          this.multiinput.push(input.target)
          this.multidefaults.push(input.target.value)
        }
      }
    },
    mouseentered: function (input) {
      if (this.mousedown && input.target.parentNode.style != this.markedStyle) {
        input.target.parentNode.style = this.markedStyle;
        this.multiinput.push(input.target)
        this.multidefaults.push(input.target.value)
      }
    },
    getJobs() {
      let vm = this;
      this.loading = true;
      CASP.getCheckinJobs(this.token, (res) => {
        vm.jobs = res;
        vm.loading = false;
      });
    },
  },
  computed: {
    today: function () {
      let d = new Date().toISOString().split("T")[0].split("-")
      return d[2] + "." + d[1] + "." + d[0]
    },
  },
  created: function () {
    document.getElementById("mainspinner").style.display = "none";
    if (this.cookies.get("authtoken")) {
      this.token = this.cookies.get("authtoken");
      CASP.isAuthenticated(this.token, (auth) => {
        if (!auth) {
          window.location = "/admin/login";
        } else {
          //this.getJobs();
        }
      });
    } else {
      window.location = "/admin/login";
    }
  },
};
</script>
<style scoped>
input{
  border-bottom: 1px solid black;
  min-width: 1px;
  width: 100%;
}</style>