<template>
  <div class="">
    <div v-if="signhere" class="fixed inset-0 flex flex-col items-center justify-center bg-black/80 z-20">
      <span class="font-bold text-5xl text-white pb-10">Unterschrift {{ who }}</span>
      <canvas ref="canvas" class="w-11/12 h-[30%] bg-white" @mousedown="startSignature" @mousemove="drawSignature" @mouseup="endSignature" @touchstart="startSignature" @touchmove="drawSignature" @touchend="endSignature"></canvas>
      <div class="p-4">
        <button class="mr-3 bg-caspyellow text-black" @click="$emit('close')">Schließen</button>
        <button v-if="signature.length > 0" class="mr-3 bg-red-500" @click="clear()">Löschen</button>
        <button v-if="signature.length > 0" class="mr-3 bg-green-500" @click="saveSignature()">Speichern</button>
      </div>
    </div>
    <div v-else class="fixed inset-0 flex flex-col items-center justify-center bg-black/80 z-20">
      <span class="font-bold text-5xl text-white pb-10">Warten auf Unterschrift<br> von {{ who }}...</span>
      <div class="p-4">
        <button class="mr-3 bg-caspyellow text-black" @click="signhere = true">Hier unterschreiben</button>
      </div>
    </div>
  </div>
</template>

<script>
import CASP from '../CASP'
export default {
  name: 'Job',
  expose: ["draw"],
  props: {
    path: Array,
    who: String,
    type: String,
    docId: Object
  },
  data() {
    return {
      signature: [],
      signhere: true,
    }
  },
  methods: {
    startSignature(event) {
      const canvas = this.$refs.canvas;
      const rect = canvas.getBoundingClientRect();
      const scaleX = canvas.width / rect.width;
      const scaleY = canvas.height / rect.height;
      const touchX = event.clientX ? event.clientX : event.touches[0].clientX;
      const touchY = event.clientY ? event.clientY : event.touches[0].clientY;
      this.lastX = (event.touchX - rect.left) * scaleX;
      this.lastY = (event.touchY - rect.top) * scaleY;
      this.signature.push({ x: this.lastX, y: this.lastY });
      this.isDrawing = true;
      event.preventDefault();
      console.log(event)
    },
    drawSignature(event) {
      if (!this.isDrawing) return;
      const canvas = this.$refs.canvas;
      const rect = canvas.getBoundingClientRect();
      const scaleX = canvas.width / rect.width;
      const scaleY = canvas.height / rect.height;
      const touchX = event.clientX ? event.clientX : event.touches[0].clientX;
      const touchY = event.clientY ? event.clientY : event.touches[0].clientY;
      const ctx = canvas.getContext('2d');
      ctx.strokeStyle = 'black'; // Set the stroke color to black
      ctx.lineWidth = 2; // Set the line width
      //ctx.lineCap = 'round'; // Set the line cap style
      const x = (touchX - rect.left) * scaleX;
      const y = (touchY - rect.top) * scaleY;
      ctx.beginPath();
      ctx.moveTo(this.lastX, this.lastY);
      ctx.lineTo(x, y);
      ctx.stroke();
      this.lastX = x;
      this.lastY = y;
      this.signature.push({ x: this.lastX, y: this.lastY });
    },
    clear() {
      this.$refs.canvas.getContext('2d').clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
      this.signature = []
    },
    draw(canvas, path) {
      if (!canvas) {
        canvas = this.$refs.canvas;
        path = this.path
      }
      const ctx = canvas.getContext('2d');
      let offset = {
        x: 1000,
        y: 0
      }
      for (let i = 0; i < path.length; i++) {
        if (path[i].x != null && !isNaN(path[i].x) && path[i].x < offset.x) offset.x = path[i].x
        if (path[i].y != null && !isNaN(path[i].y) && path[i].y > offset.y) offset.y = path[i].y
      }
      offset.x = offset.x - 10
      offset.y = canvas.height - offset.y
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.strokeStyle = 'black'; // Set the stroke color to black
      ctx.lineWidth = 2; // Set the line width
      ctx.lineCap = 'round'; // Set the line cap style
      ctx.beginPath();
      if (path.length > 0) ctx.moveTo(path[0].x - offset.x, path[0].y);
      this.drawNext(ctx, path, 0, offset)
    },
    waitForSignature() {
      CASP.checkSignature(this.docId, this.type, (status, response) => {
        if (response.signed) {
          this.$emit("documentSigned")
        }
        else {
          setTimeout(() => {
            this.waitForSignature()
          }, 1000)
        }
      })
    },
    drawNext(ctx, path, index, offset) {
      if (!path || !path.length > 0 || index === path.length - 1) {
        return
      }
      if ((path[index].x == null || isNaN(path[index].x)) && path && index < path.length - 1) {
        ctx.moveTo(path[index + 1].x - offset.x, path[index + 1].y + offset.y);
        this.drawNext(ctx, path, index + 1, offset)
        return
      }
      ctx.lineTo(path[index].x - offset.x, path[index].y + offset.y);
      ctx.stroke();
      setTimeout(() => {
        this.drawNext(ctx, path, index + 1, offset)
      }, 1);
    },
    saveSignature() {
      CASP.signDocument(this.docId, this.type, this.signature, (status, response) => {
        this.$emit("documentSigned")
      })
    },
    endSignature() {
      this.isDrawing = false;
    },
  },
  mounted: function () {
    console.log(this.path)
    this.signature = this.path ? this.path : []
    this.draw(this.$refs.canvas, this.signature)
    console.log(this.type)
    if (this.type == "passed") {
      this.signhere = false
      this.waitForSignature()
    }
  }
}
</script>
