<template>
  <Spinner v-if="loading" />
  <div class="w-full h-full relative text-center m-0 p-0">
    <Transition>
      <JobModal v-if="selectedJob" :job="selectedJob" @close="selectedJob = false" :users="allUsers" @changeUser="changeUser" />
    </Transition>
    <div class="fixed w-full z-10 text-center">
      <img class="w-full lg:mt-24 inline sm:hidden" src="@/assets/banner.jpg" />
      <img class="w-full sm:inline hidden" src="@/assets/DesktopBanner.png" />
    </div>
    <div class="relative w-full h-full text-center overflow-y-auto">
      <div class="relative w-full text-center pt-48 sm:pt-[10%] xl:inline-block">
        <div class="hidden md:inline absolute left-0">
          <button class=" text-black bg-caspyellow border-4 border-gray-500 rounded-lg w-32 py-2 font-bold" onclick="window.location='/admin'">
            Zurück
          </button>
          <button v-if="Object.keys(jobs).length > 0" class="ml-5 text-white bg-gray-500 border-4 border-gray-500 rounded-lg w-32 py-2 font-bold" @click="exportCSV()">
            CSV Export
          </button>
        </div>
        <span class="font-bold text-4xl">Schichtplanung</span><br />
        <div class="text-left border-8 border-gray-500 rounded-xl shadow-lg mt-5 p-5 w-11/12 sm:w-5/6 inline-block">
          <span class="block text-2xl font-bold">Filter</span>
          <div class="inline-block p-2 relative">
            <span class="block font-bold mt-2">Projekt</span>
            <Autocomplete :list="allProjects.map(el=>el.name)" :value="project" @update:value="newProject =>{ project = newProject;getJobs()}" placeholder="Projekt"/>
          </div>
          <div class="inline-block p-2">
            <div class="inline-block p-2 acwrapper">
              <span class="block font-bold mt-2">Monat</span>
              <input type="month" v-model="month" class="border-b-2 w-36 border-gray-500" @change="getJobs" />
            </div>
          </div>
          <div class="inline-block p-2">
            <div class="inline-block p-2 acwrapper 2xl:border-l-4 pl-4">
              <span class="block font-bold mt-2">Legende</span>
              <div class="grid md:grid-cols-2 2xl:text-2xl md:text-lg">
                <span><i class="mr-2 fa fa-file-circle-question" />: Zugewiesen,
                  aber nicht angenommen</span>
                <span><i class="mr-2 fa fa-file" />: Zugewiesen und
                  angenommen</span>
                <span><i class="text-green-500 mr-2 fa fa-file-circle-check" />:
                  Bestätigt</span>
                <span><i class="text-green-500 mr-2 fa fa-file-shield" />: Zeit
                  geprüft</span>
                <span><i class="text-green-500 mr-2 fa fa-file-invoice-dollar" />:
                  Bezahlt</span>
              </div>
            </div>
          </div>
        </div>
        <div class="md:hidden inline-block mt-5">
          <button class=" text-black bg-caspyellow border-4 border-gray-500 rounded-lg w-32 py-2 font-bold" onclick="window.location='/admin'">
            Zurück
          </button>
          <button v-if="Object.keys(jobs).length > 0" class="ml-5 text-white bg-gray-500 border-4 border-gray-500 rounded-lg w-32 py-2 font-bold" @click="exportCSV()">
            CSV Export
          </button>
        </div>
        <div v-if="Object.keys(jobs).length > 0" class="w-full">
          <div v-for="place in Object.keys(jobs)" :key="place" class="w-full">
            <span class="inline-block text-2xl font-bold mt-5">{{ place }}</span><br />
            <div v-if="jobs[place].Offen && jobs[place].Offen.length > 0">
              <span class="inline-block font-bold text-xl w-full text-left">Offene Schichten ({{jobs[place].Offen.length}}):</span>
            <table class="w-full overflow-x-auto block md:table mt-2">
              <thead ref="head">
                <th>Datum</th>
                <th>Beginn</th>
                <th>Ende</th>
                <th>Einsatzstelle</th>
                <th>Position</th>
                <th>Mitarbeiter</th>
                <th>Aktionen</th>
              </thead>
              <tbody ref="body">
                <tr v-for="job in jobs[place].Offen" :key="job.id">
                  <td>{{ job.datum_formatted }}</td>
                  <td>{{ job.Soll_Von }}</td>
                  <td>{{ job.Soll_Bis }}</td>
                  <td>{{ job.Einsatzstelle_Beschreibung }}</td>
                  <td>{{ job.Pos }}</td>
                  <td>
                    <div>
                      <div class="inline-block p-2 relative">
                        <Autocomplete :list="allUsers.map(el=>el.displayName)" :value="job.Mitarbeiternr" @update:value="newVal =>{ job.Mitarbeiternr = newVal;job.changed=true}" placeholder="Mitarbeiternr"/>
                        
                      </div>
                      <button v-if="job.changed" class="mx-1 inline-block font-bold mt-4 bg-gray-500 rounded p-2 text-white" @click="changeUser(job)">
                        Speichern
                      </button>
                      <button v-if="job.changed" class="mx-1 inline-block font-bold mt-4 bg-gray-500 rounded p-2 text-white" @click="getJobs()">
                        Reset
                      </button>
                    </div>
                  </td>
                  <td class="align-top">
                   <button class="mx-1 inline-block font-bold mt-4 bg-gray-500 rounded p-2 text-white" @click="selectedJob=job">Details</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
            <div class="block overflow-x-auto relative ">
              <span class="inline-block font-bold text-xl w-full text-left">Schichtplan:</span>
              <table class="w-full inline-block origin-top-left scale-75">
                <thead>
                  <th class="mt-5">Schicht</th>
                  <th v-for="day in days" :key="day.date">
                    {{ day.weekday }}<br />{{ day.date }}
                  </th>
                </thead>
                <tbody>
                  <template v-for="user in Object.keys(jobs[place])" :key="user">
                    <tr v-if="user != 'Offen'">
                      <th class="bg-gray-500 text-white">{{ user }}</th>
                      <td v-for="day in days" :key="day.date" class="text-center">
                        <div class="text-center cursor-pointer inline" @click="selectedJob = job" v-for="job in jobs[place][user].filter((el) => {
                          console.log(el.Datum_von)
                          console.log(day.obj.toISOString().substring(0, 11) + '00:00:00')
                          return (
                            el.Datum_von ==
                            day.obj.toISOString().substring(0, 10)
                          );
                        })" :key="job.id">
                          <i v-if="job.Mitarbeiternr == ''" class="fa fa-file-circle-xmark text-2xl text-red-700" />
                          <i v-else-if="job.Bez" class="fa fa-file-invoice-dollar text-2xl text-green-600" />
                          <i v-else-if="job['Ist_Zeiten_geprüft']" class="fa fa-file-shield text-2xl text-green-600" />
                          <i v-else-if="job['MA_bestätigt']" class="fa fa-file-circle-check text-2xl text-green-600" />
                          <i v-else-if="job.MA_gebucht" class="fa fa-file text-2xl" />
                          <i v-else class="fa fa-file-circle-question text-2xl" />
                          {{job.Soll_Von}} - {{job.Soll_Bis}}
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-else-if="searched">
          <span class="text-xl font-bold mt-10">Keine Aufträge in diesem Monat</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CASP from "../../CASP";
  import { useCookies } from "vue3-cookies";
  import JobModal from "@/components/JobModal.vue";
  import Autocomplete from "@/components/Autocomplete";
  import Spinner from "@/components/Spinner";
  import { Sortable } from "sortablejs-vue3";

  export default {
    setup() {
      const { cookies } = useCookies();
      return { cookies };
    },
    name: "Jobs",
    components: { JobModal, Spinner,Autocomplete, Sortable },
    data() {
      return {
        token: "",
        project: "",
        month: new Date().toISOString().split("T")[0].substring(0, 7),
        end: "",
        loading: false,
        allProjects: [],
        console: console,
        allUsers: [],
        showProjectDropdown: false,
        selectedJob: false,
        searched: false,
        drag: false,
        jobs: {},
        days: [],
        jobEmployeeDropdown: ""
      };
    },
    methods: {
      filterUsers(job) {
        console.log("filter" + job.id);
        this.jobEmployeeDropdown = job.id;
        let options = "";
        this.allUsers.forEach((el) => {
          if (
            el.displayName.toLowerCase().includes(job.Mitarbeiternr.toLowerCase())
          ) {
            options += "<p class='dp'>" + el.displayName + "</p>";
          }
        });
        console.log(options)
        if (this.$refs["jobDropdown" + job.id]) {
          console.log("setting")
          this.$refs["jobDropdown" + job.id][0].innerHTML = options;
          console.log(this.$refs["jobDropdown" + job.id])
        }
      },
      exportCSV() {
        Object.keys(this.jobs).forEach(key => {
          let place = this.jobs[key]
          let head = document.getElementsByTagName("THEAD")[0].childNodes
          let text = "Schicht;"
          this.getDays()
          this.days.forEach(day => {
            text += day.date + ";"
          })
          text += "\n"
          Object.keys(place).forEach(user => {
            if(user=="Offen")return
            text += user + ";"
            this.days.forEach(day => {
              place[user].forEach(job => {
                if (job.Datum_von == day.obj.toISOString().substring(0, 11) + '00:00:00') {
                  if (job.Mitarbeiternr) {
                    text += job.Soll_Von+" - "+job.Soll_Bis
                  } else {
                    text += "?"
                  }
                }
              })
              text += ";"

            })
            text += "\n"
          })

          let element = document.createElement('a');
          element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
          element.setAttribute('download', new Date().toJSON().split("T")[0] + "_" + key + ".csv");
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        })

      },
      getDays() {
        let daysInMonth = new Date(
          this.month.split("-")[0],
          this.month.split("-")[1] + 1,
          0
        ).getDate();
        let days = [];
        for (let i = 1; i <= daysInMonth; i++) {
          let d = new Date(
            this.month.split("-")[0],
            this.month.split("-")[1] - 1,
            i
          );
          d.setHours(5)
          days.push({
            weekday: d.toLocaleDateString("de-DE", { weekday: "short" }),
            date: (i < 10 ? "0" : "") + i + "." + this.month.split("-")[1],
            obj: d,
          });
        }
        this.days = days;
      },
      
      changeCheckin(job) {
        this.changeJob(job, { checkinRequired: job.checkinRequired });
      },
      changeUser(job) {
        this.selectedJob = false;
        console.log("change user ccalled");
        this.changeJob(job, { Mitarbeiternr: job.Mitarbeiternr.split(" (")[0] });
      },
      changeJob(job, changes) {
        CASP.changeJob(this.token, job.id, changes, (res) => {
          if (res) {
            this.getJobs();
          }
        });
      },
      getProjects() {
        let vm = this;
        CASP.getProjects(this.token, (res) => {
          if (res) {
            vm.allProjects = res;
            if (res.length == 1) {
              this.project = res[0].name;
              this.getJobs();
            }
          }
        });
      },
      getUsers() {
        let vm = this;
        CASP.getUsers(this.token, (res) => {
          if (res) {
            vm.allUsers = res;
          }
        });
      },

      filterProjects() {
        this.showProjectDropdown = true;
        let options = "";
        this.allProjects.forEach((el) => {
          if (el.name.toLowerCase().includes(this.project.toLowerCase())) {
            options += "<p class='dp'>" + el.name + "</p>";
          }
        });
        if (this.$refs.projectList) {
          this.$refs.projectList.innerHTML = options;
        }
      },
      getJobs() {
        this.getDays();
        let vm = this;
        if (
          this.allProjects.filter((el) => {
            return el.name.trim() == this.project.trim();
          }).length == 1
        ) {
          this.loading = true;
          this.jobs = {};
          CASP.getPlanJobs(
            this.token,
            { project: this.project, month: this.month },
            (res) => {
              vm.jobs = res;
              vm.searched = true;
              vm.loading = false;
              console.log(Object.keys(vm.jobs).length > 0);
            }
          );
        }
      },
    },
    computed: {
      today: function () {
        let d = new Date().toISOString().split("T")[0].split("-");
        return d[2] + "." + d[1] + "." + d[0];
      },
      datestamp: function () {
        return new Date().toISOString().split("T")[0] + "00:00:00";
      },
    },
    created: function () {
      console.log(this.month);
      document.getElementById("mainspinner").style.display = "none";
      if (this.cookies.get("authtoken")) {
        this.token = this.cookies.get("authtoken");
        CASP.isAuthenticated(this.token, (auth) => {
          if (!auth) {
            window.location = "/admin/login";
          } else {
            this.getProjects();
            this.getUsers();
            this.getJobs();
          }
        });
      } else {
        window.location = "/admin/login";
      }
      this.filterProjects();
    },
  };
</script>
