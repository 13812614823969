<template>
  <toolbar title="Aufträge" :showReload="true" @reload="loadJobs()" backUrl="/" />
  
  <div class="w-full text-center">
    <div class="w-full md:w-5/6 inline-block text-left">
  <div class="tab">
    <button v-bind:class="{ tabactive: showPublic }" @click="setPublic()">
      Öffentlich
    </button>
    <button v-bind:class="{ tabactive: showPrivate }" @click="setPrivate()">
      Privat
    </button>
    <button v-bind:class="{ tabactive: showAccepted }" @click="setAccepted()">
      Angenommen
    </button>
  </div>
  <Spinner v-if="loading" />
  <Joblist v-if="showPrivate" :loading="loading" :jobs="jobs.assigned" />
  <Joblist v-if="showPublic" :loading="loading" :jobs="jobs.available" />
  <Joblist v-if="showAccepted" :loading="loading" :jobs="jobs.confirmed" />
    </div></div>
</template>

<script>
import Joblist from "@/components/Joblist";
import Spinner from "@/components/Spinner";
import CASP from "../../CASP";
import Toolbar from "@/components/Toolbar.vue";
import { useCookies } from "vue3-cookies";
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  name: "Jobs",
  components: {
    Joblist,
    Spinner,
    Toolbar,
  },
  data() {
    return {
      loading: true,
      showPrivate: true,
      showPublic: false,
      showAccepted: false,
      showArchive: false,
      archive: [],
      jobs: {
        assigned: [],
        available: [],
        confirmed: [],
      },
      allJobs: [],
      token: "",
    };
  },
  methods: {
    hideAll() {
      this.showPrivate = false;
      this.showPublic = false;
      this.showAccepted = false;
      this.showArchive = false;
    },
    setPrivate() {
      this.hideAll();
      this.showPrivate = true;
    },
    setPublic() {
      this.hideAll();
      this.showPublic = true;
    },
    setAccepted() {
      this.hideAll();
      this.showAccepted = true;
    },
    setArchive() {
      this.loading = true;
      this.hideAll();
      this.showArchive = true;
      const comp = this;
      CASP.getArchive(this.token, (archive) => {
        comp.archive = archive;
        comp.loading = false;
      });
    },
    loadJobs() {
      const comp = this;
      this.loading=true
      CASP.getAllJobs(this.token, (jobs) => {
        comp.jobs = jobs;
        comp.loading = false;
      });
    },
  },
  created: function () {
    document.getElementById("mainspinner").style.display = "none";
    if (this.cookies.get("authtoken")) {
      this.token = this.cookies.get("authtoken");
    } else {
      window.location = "/forbidden";
    }
    this.loadJobs();
  },
};
</script>
<style scoped>
html {
  height: 100%;
}
.tab {
  overflow: hidden;
  background-color: white;
  display: flex;
}

.small {
  flex-grow: 2;
}

.large {
  flex-grow: 1;
}

button {
  font-weight: bold;
}
/* Style the buttons that are used to open the tab content */
button {
  color: gray;
  border: none;
  border-bottom: 3px solid gray;
  outline: none;
  cursor: pointer;
  background: inherit;
  padding: 14px 16px;
  transition: 0.3s;
  flex-grow: 1;
}

/* Create an active/current tablink class */
.tabactive {
  color: #007aff;
  border-bottom: 3px solid rgb(0, 122, 255);
}

/* Style the tab content */
.tabcontent {
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
</style>
