<template>
  <Signature
    v-if="sign"
    :type="currentSignature"
    :docId="docId"
    :who="who"
    ref="sign"
    @close="sign = false"
    @documentSigned="documentSigned"
  />
  <toolbar v-if="edit" :title="type + ' bearbeiten'" />
  <toolbar v-else :title="type + ' hinzufügen'" />
  <div class="text-center w-full">
    <div
      v-if="document.author"
      class="p-2 w-full md:w-5/6 text-left inline-block"
    >
      <div v-if="type == 'Schichtnachweis'">
        <div class="overflow-auto">
          <table>
            <thead>
              <tr>
                <th>Projekt</th>
                <th>Datum</th>
                <th>Verfasser</th>
                <th>Funktion</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ document.contract }}</td>
                <td>{{ document.date }}</td>
                <td>{{ document.author.name }}</td>
                <td>{{ document.author.function }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <span class="font-bold text-xl">Schichten</span>
        <div class="overflow-auto">
          <table>
            <thead>
              <tr>
                <th>T/N</th>
                <th>Personalnr.</th>
                <th>Nachname</th>
                <th>Vorname</th>
                <th>Von</th>
                <th>Bis</th>
                <th>Bestätigt</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="job in document.shifts" :key="job.id">
                <td>
                  <select v-model="job.T_N">
                    <option value="T">T</option>
                    <option value="N">N</option>
                  </select>
                </td>
                <td>
                  <select
                    v-model="job.Mitarbeiternr"
                    @change="setEmployee(job)"
                  >
                    <option value="">Bitte auswählen</option>
                    <option
                      v-for="user in shifts"
                      :key="user.Mitarbeiternr"
                      :value="user.Mitarbeiternr"
                    >
                      {{ user.Mitarbeiternr }}
                    </option>
                  </select>
                </td>
                <td>
                  <input
                    v-model="job.Mitarbeiter_Nachname"
                    @input="checkRows(document.shifts, job, 'job')"
                  />
                </td>
                <td><input v-model="job.Mitarbeiter_Vorname" /></td>
                <td><input type="time" v-model="job.Soll_Von" /></td>
                <td><input type="time" v-model="job.Soll_Bis" /></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <span class="font-bold text-xl">Material- und Schlüsselübergabe</span>
        <div class="overflow-auto">
          <table>
            <thead>
              <tr class="w-full">
                <th>Typ</th>
                <th class="w-full">Beschreibung</th>
                <th class="min-w-[120px]">Menge</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="pass in document.passes" :key="pass.id">
                <td>
                  <select v-model="pass.type">
                    <option value="Material">Material</option>
                    <option value="Schlüssel">Schlüssel</option>
                  </select>
                </td>
                <td>
                  <input
                    v-model="pass.description"
                    @input="checkRows(document.passes, pass, 'pass')"
                    class="w-full px-2 py-1"
                    type="text"
                    placeholder="Beschreibung"
                  />
                </td>
                <td>
                  <input
                    v-model="pass.amount"
                    class="w-full px-2 py-1"
                    type="number"
                    placeholder="Menge"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <span class="font-bold text-xl mr-3">Übergabe an: </span
        ><select v-model="document.signatures.passed.id">
          <option value="">Bitte auswählen</option>
          <option
            v-for="user in signatures.passed"
            :key="user.Mitarbeiternr"
            :value="user.Mitarbeiternr"
          >
            ({{ user.Mitarbeiternr }}) {{ user.Mitarbeiter_Vorname }}
            {{ user.Mitarbeiter_Nachname }}
          </option>
        </select>
      </div>
      <div v-if="type == 'Schlüssel- und Materialübergabe'">
        <div class="overflow-auto">
          <table>
            <thead>
              <tr>
                <th>Projekt</th>
                <th>Datum</th>
                <th>Verfasser</th>
                <th>Funktion</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ document.contract }}</td>
                <td>{{ document.date }}</td>
                <td>{{ document.author.name }}</td>
                <td>{{ document.author.function }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <span class="font-bold text-xl">Material- und Schlüsselübergaben</span>
        <div class="overflow-auto">
          <table>
            <thead>
              <tr class="w-full">
                <th>Typ</th>
                <th class="w-full">Beschreibung</th>
                <th class="min-w-[120px]">Menge</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="pass in document.passes" :key="pass.id">
                <td>
                  <select v-model="pass.type">
                    <option value="Material">Material</option>
                    <option value="Schlüssel">Schlüssel</option>
                  </select>
                </td>
                <td>
                  <input
                    v-model="pass.description"
                    @input="checkRows(document.passes, pass, 'pass')"
                    class="w-full px-2 py-1"
                    type="text"
                    placeholder="Beschreibung"
                  />
                </td>
                <td>
                  <input
                    v-model="pass.amount"
                    class="w-full px-2 py-1"
                    type="number"
                    placeholder="Menge"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <span class="font-bold text-xl mr-3">Übergabe an: </span
        ><select v-model="document.signatures.passed.id">
          <option value="">Bitte auswählen</option>
          <option
            v-for="user in signatures.passed"
            :key="user.Mitarbeiternr"
            :value="user.Mitarbeiternr"
          >
            ({{ user.Mitarbeiternr }}) {{ user.Mitarbeiter_Vorname }}
            {{ user.Mitarbeiter_Nachname }}
          </option>
        </select>
      </div>
      <div v-if="type == 'Schichtprotokoll'">
        <div class="overflow-auto">
          <table>
            <thead>
              <tr>
                <th>Projekt</th>
                <th>Datum</th>
                <th>Verfasser</th>
                <th>Funktion</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ document.contract }}</td>
                <td>{{ document.date }}</td>
                <td>{{ document.author.name }}</td>
                <td>{{ document.author.function }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <span class="font-bold text-xl">Beobachtungen</span>
        <div class="overflow-auto">
          <table>
            <thead>
              <tr>
                <th>Zeit</th>
                <th>Erkenntnis / Beobachtung</th>
                <th>Ergriffene Maßnahme</th>
                <th>Meldung an</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="event in document.events" :key="event">
                <td><input type="time" v-model="event.time" /></td>
                <td>
                  <input
                    v-model="event.description"
                    @input="checkRows(document.events, event, 'event')"
                  />
                </td>
                <td><input v-model="event.action" /></td>
                <td><input v-model="event.reportedTo" /></td>
              </tr>
            </tbody>
          </table>
        </div>
        <span class="font-bold text-xl">Technische Defekte</span>
        <div class="overflow-auto">
          <table>
            <thead>
              <tr class="w-full">
                <th class="w-full">Beschreibung</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="defect in document.defects" :key="defect">
                <td>
                  <input
                    v-model="defect.description"
                    @input="checkRows(document.defects, defect, 'defect')"
                    class="w-full px-2 py-1"
                    type="text"
                    placeholder="Beschreibung"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <span class="font-bold text-xl">Besonderheiten / Dienstablauf</span>
        <div class="overflow-auto">
          <textarea
            v-model="document.misc"
            class="w-full px-2 py-1"
            type="text"
            placeholder="Sonstige Informationen"
          ></textarea>
        </div>
        <span class="font-bold text-xl mr-3">Übergabe an: </span
        ><select v-model="document.signatures.passed.id">
          <option value="">Bitte auswählen</option>
          <option
            v-for="user in signatures.passed"
            :key="user.Mitarbeiternr"
            :value="user.Mitarbeiternr"
          >
            ({{ user.Mitarbeiternr }}) {{ user.Mitarbeiter_Vorname }}
            {{ user.Mitarbeiter_Nachname }}
          </option>
        </select>
      </div>
      <div v-if="!document.signed" class="text-center w-full mt-5">
        <button
          @click="save()"
          class="bg-green-500 text-white font-bold py-2 px-4 rounded-lg"
        >
          Speichern
        </button>
        <button
          v-if="document.signatures.passed.id !== ''"
          @click="signDocument()"
          class="bg-red-500 text-white font-bold py-2 px-4 rounded-lg ml-2"
        >
          Unterschreiben
        </button>
        <button
          v-else
          class="bg-gray-500 text-white font-bold py-2 px-4 rounded-lg ml-2"
        >
          Unterschreiben
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Toolbar from "@/components/Toolbar.vue";
import CASP from "../../CASP";
import { useCookies } from "vue3-cookies";
import Signature from "../../components/Signature.vue";
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  components: { Toolbar, Signature },
  data() {
    return {
      docId: 0,
      shifts: [],
      stats: {},
      edit: false,
      documents: [],
      currentJob: false,
      type: "Schichtnachweis",
      stats: false,
      sign: false,
      currentSignature: false,
      signatures: {
        passed: [],
      },
      who: "",
      document: {
        shifts: [],
        events: [
          {
            time: "12:00",
            description: "",
            action: "",
            reportedTo: "",
          },
        ],
        defects: [
          {
            description: "",
          },
        ],
        misc: "",
        passes: [
          {
            type: "Material",
            description: "",
            amount: "",
          },
        ],
        signatures: {
          passing: {
            id: "",
            path: [],
          },
          passed: {
            id: "",
            path: [],
          },
          casp: {
            id: "",
            path: [],
          },
          client: {
            id: "",
            path: [],
          },
        },
      },
    };
  },
  methods: {
    setEmployee(job) {
      let em = this.signatures.passed.find(
        (x) => x.Mitarbeiternr == job.Mitarbeiternr
      );
      if (em) {
        job.Mitarbeiter_Nachname = em.Mitarbeiter_Nachname;
        job.Mitarbeiter_Vorname = em.Mitarbeiter_Vorname;
      }
    },
    getSignatures() {
      CASP.getSignaturesForJob(this.currentJob, (status, response) => {
        if (status == 200) {
          this.signatures = response;
          this.signatures.passed = this.signatures.passed.filter(
            (x) => x.Mitarbeiternr != this.stats.user.persnr
          );
          if (this.document.signatures.passed.id == this.stats.user.persnr) {
            this.document.signatures.passed = {};
          }
        }
      });
    },
    signDocument() {
      this.currentSignature = "passing";
      this.who = this.document.signatures.passing.id;
      if (this.docId == 0) {
        CASP.saveJobDocument(
          this.currentJob,
          this.type,
          this.document,
          this.$route.params.doc,
          (status, response) => {
            if (status == 200) {
              this.docId = response.id;
              this.sign = true;
            }
          }
        );
      } else {
        this.sign = true;
      }
    },
    save() {
      console.log(this.document);
      CASP.saveJobDocument(
        this.currentJob,
        this.type,
        this.document,
        this.$route.params.doc,
        (status, response) => {
          if (status == 200) {
            window.location = "/jobs/" + this.currentJob.id + "/documents";
          }
        }
      );
    },
    documentSigned() {
      if (this.currentSignature == "passing") {
        this.currentSignature = "passed";
        this.sign = false;
        setTimeout(() => {
          this.sign = true;
        }, 100);
        let em = this.signatures.passed.find(
          (x) => x.Mitarbeiternr == this.document.signatures.passed.id
        );
        this.who = em.Mitarbeiter_Vorname + " " + em.Mitarbeiter_Nachname;
      } else {
        window.location = "/jobs/" + this.currentJob.id + "/documents";
      }
    },

    checkRows(array, entry, type) {
      let relevantField = "";
      if (["pass", "defect", "event"].includes(type))
        relevantField = entry.description;
      else if (type == "job") relevantField = entry.Mitarbeiter_Nachname;
      if (relevantField && array.indexOf(entry) == array.length - 1) {
        if (type == "pass")
          array.push({
            type: "Material",
          });
        else if (type == "job")
          array.push({
            T_N: "T",
          });
        else if (type == "defect")
          array.push({
            description: "",
          });
        else if (type == "event")
          array.push({
            time: "12:00",
            description: "",
            action: "",
            reportedTo: "",
          });
      } else if (
        !relevantField &&
        array.indexOf(entry) == array.length - 2 &&
        !array[array.length - 1].description
      ) {
        array.splice(array.indexOf(entry) + 1, 1);
      } else if (!relevantField && array.indexOf(entry) != array.length - 1) {
        array.splice(array.indexOf(entry), 1);
      }
    },
    getStats() {
      CASP.getStats("", (status, response) => {
        if (status == 200) {
          this.stats = JSON.parse(response);
          this.getCurrentJob();
        }
      });
    },
    getJobDocument() {
      let vm = this;
      CASP.getJobDocument(this.$route.params.doc, (status, response) => {
        this.docId = this.$route.params.doc;
        this.type = response.type;
        this.document = response.document;
        this.document.shifts.forEach((shift) => {
          if (!shift.signature) {
            shift.signature = {
              date: null,
              path: [],
            };
          }
        });
        setTimeout(() => {
          document.getElementById("mainspinner").style.display = "none";

          if (document.shifts) {
            this.document.shifts.forEach((shift) => {
              if (!shift.Mitarbeiternr) return;
              vm.$refs.sign.draw(
                vm.$refs[shift.Mitarbeiternr][0],
                shift.signature.path
              );
            });
          }
        }, 100);
      });
    },
    processCurrentJob() {
      this.getSignatures();
      if (this.edit) {
        this.getJobDocument();
      } else {
        this.document.contract = this.currentJob.Vertragsbeschreibung;
        this.document.date = this.currentJob.Datum_von;
        this.document.signatures.passing.id = this.currentJob.Mitarbeiternr;
        this.document.author = {
          name:
            this.currentJob.Mitarbeiter_Vorname +
            " " +
            this.currentJob.Mitarbeiter_Nachname,
          function: this.stats.user.function,
        };
        if (this.$route.query.type == "Schichtnachweis") {
          CASP.getSameJobs(this.currentJob, (status, response) => {
            if (status == 200) {
              this.shifts = response;
              this.document.shifts = response;
              this.document.shifts.push({ T_N: "T" });
            }
          });
        }
      }
    },
    getCurrentJob() {
      let vm = this;
      if (this.$route.params.jobid) {
        CASP.getJobById("", this.$route.params.jobid, (response) => {
          if(!response)return;
          vm.currentJob = response;
          this.processCurrentJob();
        });
      } else if (this.$route.query.job) {
        CASP.getJobById("", this.$route.query.job, (response) => {
          if(!response)return;
          vm.currentJob = response;
          this.processCurrentJob();
        });
      } else {
        if (vm.stats.currentJob) {
          vm.currentJob = vm.stats.currentJob;
          this.processCurrentJob();
        }
      }
    },
  },
  created: function () {
    this.edit = this.$route.params.jobid && this.$route.params.doc;
    document.getElementById("mainspinner").style.display = "none";
    this.getStats();
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
  },
};
</script>
<style scoped>
table {
  overflow-x: auto;
}
</style>