<template>
  <Toolbar title="Aufgabendetails" backUrl="/tasks" />
  <Banner ref="banner" />

  <div>
    <div class="job" style="padding: 15px; display: block">
      <div style="margin-top: 15px; margin-bottom: 15px">
        <div>
          <span style="font-weight: bold">Titel:</span>
        </div>
        <input v-model="task.summary" class="px-2 py-1 w-full" />
      </div>
      <div class="w-full">
        <span style="font-weight: bold">Erstellt von: </span><span>{{ task.authorName }}</span><br>
        <span class="font-bold mt-2 inline-block">Status:</span>
        <div
          class="cursor-pointer text-sm font-bold inline-block p-1 rounded ml-2 border border-black"
          @click="task.status = 'Offen'"
          :class="{
            'text-red-800 bg-red-200 border-red-800': task.status == 'Offen',
          }"
        >
          Offen
        </div>
        <div
          class="cursor-pointer text-sm font-bold inline-block p-1 rounded ml-2 border border-black"
          @click="task.status = 'In Bearbeitung'"
          :class="{
            'text-yellow-800 bg-yellow-200 border-yellow-800':
              task.status == 'In Bearbeitung',
          }"
        >
          In Bearbeitung
        </div>
        <div
          class="cursor-pointer text-sm font-bold inline-block p-1 rounded ml-2 border border-black"
          @click="task.status = 'Erledigt'"
          :class="{
            'text-green-800 bg-green-200 border-green-800':
              task.status == 'Erledigt',
          }"
        >
          Erledigt
        </div>
      </div>
      <div style="margin-top: 15px; margin-bottom: 15px">
        <span
          style="display: inline-block; font-weight: bold; margin-bottom: 5px"
          >Beschreibung:</span
        >
        <div>
          <textarea
            v-model="task.description"
            class="p-2 box-border w-full"
            rows="3"
            @focus="resizeTextarea"
            @keyup="resizeTextarea"
          ></textarea>
        </div>
        <span style="font-weight: bold">Zuweisen:</span><br />
        <select v-model="task.assignedTo" class="px-2 py-1">
          <option value="null">Nicht zugewiesen</option>
          <option
            v-for="contact in taskContacts"
            :key="contact"
            :value="contact.id"
          >
            {{ contact.surname }}, {{ contact.name }}
          </option></select
        ><button
          @click="assignTaskToMe()"
          class="ml-2 bg-white border-2 border-blue-500 rounded text-blue-500"
        >
          Mir zuweisen
        </button>
      </div>
      <div class="">
        <span class="font-bold inline-block">Dateien:</span><br />
        <div v-for="file in files" :key="file" class="m-2 inline-block">
          <a :href="file.url">{{ file.name }}</a>
        </div>
        <div
          class="font-bold px-6 border-4 border-blue-500 rounded text-blue-500 inline-block m-2 cursor-pointer"
          @click="addFile()"
        >
          +
        </div>
      </div>
      <div class="w-full text-right">
        <button
          class="bg-white px-3 py-2 w-full border-2 rounded-lg border-blue-500 text-blue-500"
          @click="updateTask()"
        >
          Aktualisieren
        </button>
      </div>
    </div>
    <div class="px-4">
      <span class="font-bold text-lg">Kommentare</span>
      <div class="flex rounded border-b-2 border-black py-2">
        <textarea
          v-model="newComment"
          style="
            background: white;
            padding: 5px;
            box-sizing: border-box;
            flex-grow: 5;
          "
          rows="1"
          @focus="resizeTextarea"
          @keyup="resizeTextarea"
        ></textarea>
        <div
          @click="addComment()"
          style="
            flex-grow: 1;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          "
        >
          <i
            style="color: rgb(0, 122, 255)"
            class="far fa-paper-plane fa-lg"
          ></i>
        </div>
      </div>
      <div v-for="comment in comments" :key="comment">
        <div class="flex flex-row">
          <span class="text-blue-500 text-sm font-bold">{{
            comment.authorName
          }}</span
          ><span class="flex-grow text-right text-sm text-blue-500 font-bold">{{
            new Date(comment.createdAt).toLocaleString()
          }}</span>
        </div>
        <pre class="font-sans">{{ comment.comment }}</pre>
      </div>
    </div>
  </div>
</template>
<script>
import CASP from "../../CASP";
import Spinner from "@/components/Spinner";
import Toolbar from "@/components/Toolbar";
import Banner from "@/components/Banner.vue";
import { useCookies } from "vue3-cookies";
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  name: "Chat",
  components: {
    Spinner,
    Toolbar,
    Banner,
  },
  data() {
    return {
      files: [],
      task: {},
      taskContacts: [],
      newComment: "",
      comments: [
        {
          id: 1,
          name: "Max Mustermann",
          comment: "Testkommentar",
          date: "24.05.2024, 12:34:56",
        },
        {
          id: 1,
          name: "Max Mustermann",
          comment: "Testkommentar",
          date: "24.05.2024, 12:34:56",
        },
      ],
    };
  },
  methods: {
    addFile() {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.addEventListener("change", (event) => {
        let file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
          this.addTaskFile({ name: file.name, data: event.target.result });
        };
        reader.readAsDataURL(file);
      });
      fileInput.click();
    },
    assignTaskToMe() {
      CASP.getStats(this.cookies.get("authtoken"), (status, response) => {
        if (status == 200) {
          this.task.assignedTo = JSON.parse(response).user.id;
          this.updateTask();
        }
      });
    },
    updateTask() {
      let vm = this;
      CASP.updateTask(this.task, (status, response) => {
        console.log(vm.$refs);
        if (status == 200) {
          vm.$refs["banner"].success("Aufgabe aktualisiert");
        } else {
          vm.$refs["banner"].failure("Ein Fehler ist aufgetreten");
        }
      });
    },
    addComment() {
      let vm = this;
      CASP.addTaskComment(this.task, this.newComment, (status, response) => {
        if (status == 200) {
          vm.newComment = "";
          vm.$refs["banner"].success("Kommentar hinzugefügt");
          vm.getTaskComments();
        } else {
          vm.$refs["banner"].failure("Ein Fehler ist aufgetreten");
        }
      });
    },
    getTaskContacts() {
      CASP.getTaskContacts((status, response) => {
        this.taskContacts = response;
      });
    },
    getTaskComments() {
      CASP.getTaskComments(this.task, (status, response) => {
        this.comments = response;
      });
    },
    getTask() {
      let vm = this;
      CASP.getTask(this.$route.params.id, (status, response) => {
        document.getElementById("mainspinner").style.display = "none";
        this.task = response;
        this.getTaskComments();
        this.getTaskFiles();
      });
    },
    addTaskFile(file) {
      let vm = this;
      CASP.addTaskFile(this.task, file, (status, response) => {
        if (status == 200) {
          setTimeout(() => {
            vm.getTaskFiles();
          }, 100);
        } else {
          vm.$refs["banner"].failure("Ein Fehler ist aufgetreten");
        }
      });
    },
    getTaskFiles() {
      let vm = this;
      CASP.getTaskFiles(this.task, (status, response) => {
        this.files = response;
        this.files.forEach((file) => {
          file.url =
            process.env.VUE_APP_API_URL +
            "/tasks/" +
            this.task.id +
            "/files/" +
            file.id;
        });
      });
    },
  },
  created: function () {
    this.getTask();
    this.getTaskContacts();
  },
};
</script>