<template>
  <div id="printtest"></div>
  <Transition>
    <div v-if="showActions" ref="modalbg" class="z-30 center w-full h-full lg:px-20 lg:py-10 bg-black/50 text-center" @click="checkClick($event)">
      <div class="bg-white w-full lg:w-2/3 inline-block lg:rounded-3xl overflow-y-auto lg:px-8 pt-4 basis-0 relative text-center">
        <div class="text-center w-full border-b-4 border-black p-2">
          <div style="position: absolute; right: 40px; color: black" class="cursor-pointer" @click="showActions = false">
            <i ref="xmark" class="fa fa-xmark text-4xl"></i>
          </div>
          <span class="mt-10 font-bold text-3xl text-black">Aktionen</span>
        </div>
        <button v-if="jobs.length > 0" class="inline-block my-2 mx-2  text-white bg-gray-500 border-4 border-gray-500 rounded-lg w-32 py-2 font-bold" @click="exportCSV()">
          CSV Export
        </button>
        <button v-if="jobs.length > 0 && billingMode" class="inline-block my-2 mx-2  text-white bg-gray-500 border-4 border-gray-500 rounded-lg w-32 py-2 font-bold" @click="printPage()">
          Drucken
        </button><br>
        <button v-if="stats.permissions.admin" class="inline-block my-2 mx-2 bg-red-400 border-4 border-gray-500 rounded-lg px-4 py-2 font-bold" @click="requestSMS('confirm')">
          Bestätigung anfordern (SMS)
        </button><br>
        <button v-if="stats.permissions.admin" class="inline-block my-2 mx-2 bg-red-400 border-4 border-gray-500 rounded-lg px-4 py-2 font-bold" @click="requestSMS('accept')">
          Annahme anfordern (SMS)
        </button>
      </div>
    </div>
  </Transition>
  <Transition>
    <TopBanner :mode="bannerMode" :text="bannerText" v-if="bannerText" />
  </Transition>
  <Spinner v-if="loading" />
  <div class="w-full h-full relative text-center m-0 p-0 print:overflow-visible overflow-y-auto pt-3 sm:pt-0 " @click="checkClick($event)">
    <Transition>
      <JobModal v-if="selectedJob" :job="selectedJob" @close="selectedJob = false" :users="allUsers" @changeUser="changeUser" />
    </Transition>
    <Transition>
      <ContactDetails v-if="contactDetails" :token="token" :job="contactDetails" @close="contactDetails = false" />
    </Transition>
    <div class="inline w-full z-10 text-center">
      <img class="w-full lg:mt-24 inline sm:hidden" src="@/assets/banner.jpg" />
      <img class="w-full sm:inline hidden" src="@/assets/DesktopBanner.png" />
    </div>
    <div class="relative w-full h-full text-center pt-5 print:text-xxs origin-top-left">
      <div class="relative w-full text-center 2xl:w-11/12 2xl:inline-block">
        <div class="hidden lg:inline absolute left-0">
          <button class=" text-black bg-caspyellow border-4 border-gray-500 rounded-lg w-32 py-2 font-bold" onclick="window.location='/admin'">
            Zurück
          </button>
          <button v-if="!billingMode" class="ml-5 text-white bg-gray-500 border-4 border-gray-500 rounded-lg w-48 py-2 font-bold" @click="billingMode = true">
            Abrechnungsansicht
          </button>
          <button v-else class="ml-5 text-white bg-gray-500 border-4 border-gray-500 rounded-lg w-48 py-2 font-bold" @click="billingMode = false">
            Auftragsübersicht
          </button>
          <button v-if="jobs.length > 0" class="hidden 2xl:inline ml-5  text-white bg-gray-500 border-4 border-gray-500 rounded-lg w-32 py-2 font-bold" @click="exportCSV()">
            CSV Export
          </button>
          <button v-if="jobs.length > 0 && billingMode" class="hidden 2xl:inline ml-5  text-white bg-gray-500 border-4 border-gray-500 rounded-lg w-32 py-2 font-bold" onclick="print()">
            Drucken
          </button>
        </div>
        <div v-if="stats.permissions.admin" class="hidden lg:inline absolute right-0">
          <button class="2xl:hidden inline mx-2 float-right border-4 text-white bg-gray-500  border-gray-500 rounded-lg px-4 py-2 font-bold" @click="showActions = true">
            Aktionen
          </button>
          <button class="hidden 2xl:inline mx-2 float-right bg-red-400 border-4 border-gray-500 rounded-lg px-4 py-2 font-bold" @click="requestSMS('confirm')">
            Bestätigung anfordern (SMS)
          </button>
          <button class="hidden 2xl:inline mx-2 float-right bg-red-400 border-4 border-gray-500 rounded-lg px-4 py-2 font-bold" @click="requestSMS('accept')">
            Annahme anfordern (SMS)
          </button>
        </div>


        <span v-if="!billingMode" class="font-bold text-4xl">Auftragsübersicht</span>
        <span v-else class="font-bold text-4xl">Abrechnungsansicht</span><br />
        <div class="text-left border-8 border-gray-500 rounded-xl shadow-lg mt-5 p-5 w-11/12 sm:w-5/6 inline-block">
          <span class="block text-2xl font-bold">Filter</span>
          <div class="inline-block p-2 relative">
            <span class="block font-bold mt-2">Projekt</span>
            <div class="absolute right-4 text-2xl bottom-3 text-gray-500 cursor-pointer" @click="filter.project = ''">
              <i class="fa fa-xmark" />
            </div>
            <input size="1" v-model="filter.project" placeholder="Projektname" @click="filterProjects" @focus="filterProjects" class="border-b-2 w-64 sm:w-96 p-2 border-gray-500" @input="filterProjects" />
            <div v-if="showProjectDropdown" ref="projectList" class="border-gray-500 absolute p-2 w-full bg-white max-h-96 overflow-y-auto"></div>
          </div>
          <div class="inline-block p-2">
            <div class="inline-block p-2 acwrapper">
              <span class="block font-bold mt-2">Startdatum ab</span>
              <input size="1" type="date" v-model="filter.start" class="border-b-2 w-36 border-gray-500" @blur="getJobs()" />
            </div>
            <div class="inline-block p-2">
              <span class="block font-bold mt-2">Startdatum bis</span>
              <input size="1" type="date" v-model="filter.end" class="border-b-2 w-36 border-gray-500" @blur="getJobs()" />
            </div>
          </div>
          <button class="inline-block font-bold mx-1 mt-0 bg-gray-500 rounded p-2 text-white" @click="getJobs()()">Suchen</button>
          <button class="inline-block font-bold mx-1 mt-0 bg-gray-500 rounded p-2 text-white" @click="resetFilter()">Filter zurücksetzen</button>
          <div v-if="meta.results == 1000"><span class="text-red-500 font-bold font-xl pt-3">Maximale Anzahl an Ergebnisse erreicht. Bitte Ergebnis durch Filter weiter eingrenzen!</span></div>
        </div>
        <div class="lg:hidden inline-block mt-5">
          <button class=" text-black bg-caspyellow border-4 border-gray-500 rounded-lg w-32 py-2 font-bold" onclick="window.location='/admin'">
            Zurück
          </button>

          <button v-if="!billingMode" class="ml-5 text-white bg-gray-500 border-4 border-gray-500 rounded-lg w-48 py-2 font-bold" @click="billingMode = true">
            Abrechnungsansicht
          </button>
          <button v-else class="ml-5 text-white bg-gray-500 border-4 border-gray-500 rounded-lg w-48 py-2 font-bold" @click="billingMode = false">
            Auftragsübersicht
          </button>
          <button v-if="meta.results > 0" class="mt-3 sm:mt-0  ml-5 text-white bg-gray-500 border-4 border-gray-500 rounded-lg w-28 py-2 font-bold" @click="showActions = true">
            Aktionen
          </button>
        </div>
        <div v-if="searched" class="text-left mt-5">
          <span class="font-bold">Aufträge gefunden: {{ meta.results }}</span><span class="font-bold"> | Summe abrechenbarer Stunden: {{ meta.sumBillableHours }}</span><span class="font-bold"> | Summe Strafen: {{ meta.sumPenalties }}
            €</span>
        </div>
        <div id="tablecontent" class="w-full">
          <table class="w-full table">
            <thead ref="head">
              <th v-if="!billingMode">Lfd Nr.</th>
              <th>Datum</th>
              <th>Beginn</th>
              <th>Ende</th>
              <th v-if="billingMode">Stunden</th>
              <th v-if="billingMode">Strafen</th>
              <th>Einsatzort</th>
              <th>Einsatzstelle</th>
              <th>Position</th>
              <th v-if="billingMode">Firma</th>
              <th>Mitarbeiter</th>
              <th class="text-center">Angenommen</th>
              <th>Bestätigt</th>
              <th v-if="billingMode">Zeit geprüft</th>
              <th v-if="billingMode">Bezahlt</th>
              <th v-if="stats.permissions.internalDispo && !billingMode">Check-In notwendig?</th>
              <th class="print:hidden">Aktionen</th>
            </thead>
            <tbody ref="body">
              <tr id="filterrow" class="border-b-2 border-black print:hidden">
                <td v-if="!billingMode"><input size="1" class="min-w-0" placeholder="Nr" @change="getJobs()" v-model="filter.Lfd_Nr"></td>
                <td></td>
                <td><input size="1" class="min-w-0" placeholder="Beginn" type="time" @change="getJobs()" v-model="filter.starttime"></td>
                <td><input size="1" class="min-w-0" placeholder="Ende" type="time" @change="getJobs()" v-model="filter.endtime"></td>
                <td v-if="billingMode"></td>
                <td v-if="billingMode"><input size="1" class="min-w-0" placeholder="Strafen" @change="getJobs()" v-model="filter.Q"></td>
                <td><input size="1" class="min-w-0 w-full" placeholder="Einsatzort" @change="getJobs()" v-model="filter.location"></td>
                <td><input size="1" class="min-w-0 w-full" placeholder="Einsatzstelle" @change="getJobs()" v-model="filter.place"></td>
                <td><input size="1" class="min-w-0 w-full" placeholder="Position" @change="getJobs()" v-model="filter.Pos"></td>
                <td v-if="billingMode"><input size="1" class="min-w-0" placeholder="Firma" @change="getJobs()" v-model="filter.Firmenkurzel"></td>
                <td><input size="1" v-model="filter.persnr" placeholder="Mitarbeiter" @click="filterUsers({ id: 0 })" @focus="filterUsers({ id: 0 })" class="border-b-2 w-64 p-2 border-gray-500" @input="filterUsers({ id: 0 })" />
                  <div v-if="jobEmployeeDropdown == 0" :ref="'jobDropdown0'" class="z-10 border-gray-500 absolute p-2 w-full bg-white max-h-96 overflow-y-auto"></div>
                </td>
                <td>
                  <select @change="getJobs()" v-model="filter.accepted">
                    <option value="">Auswählen</option>
                    <option value="true">Ja</option>
                    <option value="false">Nein</option>
                  </select>
                </td>
                <td>
                  <select @change="getJobs()" v-model="filter.confirmed">
                    <option value="">Auswählen</option>
                    <option value="true">Ja</option>
                    <option value="false">Nein</option>
                  </select>
                </td>
                <td v-if="billingMode">
                  <select @change="getJobs()" v-model="filter.time_checked">
                    <option value="">Auswählen</option>
                    <option value="true">Ja</option>
                    <option value="false">Nein</option>
                  </select>
                </td>
                <td v-if="billingMode">
                  <select @change="getJobs()" v-model="filter.Bez">
                    <option value="">Auswählen</option>
                    <option value="true">Ja</option>
                    <option value="false">Nein</option>
                  </select>
                </td>
                <td v-if="stats.permissions.internalDispo && !billingMode">
                  <select @change="getJobs()" v-model="filter.checkinRequired">
                    <option value="">Auswählen</option>
                    <option value="true">Ja</option>
                    <option value="false">Nein</option>
                  </select>
                </td>
                <td><button class="inline-block font-bold mx-1 mt-0 bg-gray-500 rounded p-2 text-white" @click="getJobs()">Aktualisieren</button></td>
              </tr>
              <tr v-for="job in jobs" :key="job.id">
                <td v-if="!billingMode">{{ job.Lfd_Nr }}</td>
                <td>{{ job.datum_formatted }}</td>
                <td v-if="job['Ist_Zeiten_geprüft']" class="text-green-600 font-bold">{{ job.Von_Uhrzeit }}</td>
                <td v-else>{{ job.Soll_Von }}</td>
                <td v-if="job['Ist_Zeiten_geprüft']" class="text-green-600 font-bold">{{ job.Bis_Uhrzeit }}</td>
                <td v-else>{{ job.Soll_Bis }}</td>
                <td v-if="billingMode">{{ job.hours }}</td>
                <td v-if="billingMode">{{ job.Q }}</td>
                <td>{{ job.Einsatzort_Beschreibung }}</td>
                <td>{{ job.Einsatzstelle_Beschreibung }}</td>
                <td>{{ job.Pos }}</td>
                <td v-if="billingMode">{{ job.Firmenkurzel }}</td>
                <td>
                  <div class="print:hidden" v-if="!billingMode && job.Datum_von >= datestamp">
                    <div class="inline-block p-2 relative">
                      <input size="1" v-model="job.Mitarbeiter" placeholder="Mitarbeiter" @click="filterUsers(job)" @focus="filterUsers(job)" class="border-b-2 w-64 p-2 border-gray-500" @input="filterUsers(job)" />
                      <div class="absolute right-4 text-2xl bottom-3 text-gray-500 cursor-pointer" @click="
                        job.Mitarbeiter = '';
                      job.changed = true;
                      ">
                        <svg fill="#6b7280" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                                <path
                            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                        </svg>
                      </div>
                      <div v-if="job.id == jobEmployeeDropdown" :ref="'jobDropdown' + job.id" class="z-10 border-gray-500 absolute p-2 w-full bg-white max-h-96 overflow-y-auto"></div>
                    </div>
                    <button v-show="job.changed" class="mx-1 inline-block font-bold mt-4 bg-gray-500 rounded p-2 text-white" @click="changeUser(job)">
                      Speichern
                    </button>
                    <button v-show="job.changed" class="mx-1 inline-block font-bold mt-4 bg-gray-500 rounded p-2 text-white" @click="getJobs()">
                      Reset
                    </button>
                  </div>
                  <div class="print:hidden" v-else>
                    {{ job.Mitarbeiter }}
                  </div>
                  <div class="hidden print:inline-block">
                    {{ job.Mitarbeiter }}
                  </div>
                  <button v-if="!billingMode && job.Mitarbeiter && !job['Ist_Zeiten_geprüft']" class="inline-block font-bold mx-1 mt-0 bg-gray-500 rounded p-2 text-white" @click="contactDetails = job">Kontakt</button>
                </td>
                <td class="text-center">
                  <svg v-if="job.MA_gebucht" fill="#22C55E" xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 448 512">
                    <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                  </svg>
                  <svg v-else fill="#ef4444" xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 448 512">
                    <path
                      d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                  </svg>
                </td>
                <td class="text-center">
                  <svg v-if="job['MA_bestätigt']" fill="#22C55E" xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 448 512">
                    <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                  </svg>
                  <svg v-else fill="#ef4444" xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 448 512">
                    <path
                      d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                  </svg>
                </td>
                <td v-if="billingMode" class="text-center">
                  <svg v-if="job['Ist_Zeiten_geprüft']" fill="#22C55E" xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 448 512">
                    <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                  </svg>
                  <svg v-else fill="#ef4444" xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 448 512">
                    <path
                      d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                  </svg>
                </td>
                <td v-if="billingMode" class="text-center">
                  <svg v-if="job.Bez" fill="#22C55E" xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 448 512">
                    <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                  </svg>
                  <svg v-else fill="#ef4444" xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 448 512">
                    <path
                      d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                  </svg>
                </td>

                <td v-if="stats.permissions.internalDispo && !billingMode" class="align-middle relative">
                  <div class="center">
                    <input size="1" type="checkbox" v-model="job.checkinRequired" :id="job.id + 'checkin'" @change="changeCheckin(job)" />
                    <label :for="job.id + 'checkin'"></label>
                  </div>
                </td>
                <td class="align-top print:hidden">
                  <button class="mx-1 inline-block font-bold mt-4 bg-gray-500 rounded p-2 text-white" @click="selectedJob = job">
                    Details
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CASP from "../../CASP";
import { useCookies } from "vue3-cookies";
import JobModal from "@/components/JobModal.vue";
import ContactDetails from "@/components/ContactDetails.vue";
import Spinner from "@/components/Spinner";
import TopBanner from "@/components/TopBanner";
let in1Week = new Date()
in1Week.setDate(in1Week.getDate() + 2)
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  name: "Jobs",
  components: { ContactDetails, JobModal, Spinner, TopBanner },
  data() {
    return {
      token: "",
      filter: {
        project: "",
        start: new Date().toISOString().split("T")[0],
        end: in1Week.toISOString().split("T")[0],
        location: "",
        place: "",
        position: "",
        persnr: "",
        accepted: "",
        confirmed: "",
        time_checked: "",
        Bez: "",
        checkinRequired: ""
      },
      printing: false,
      loading: false,
      bannerText: "",
      bannerMode: "success",
      allProjects: [],
      allUsers: [],
      contactDetails: false,
      showProjectDropdown: false,
      showActions: false,
      jobEmployeeDropdown: -1,
      selectedJob: false,
      searched: false,
      jobs: [],
      billingMode: false,
      meta: {
        results: 0,
        sumBillableHours: 0,
        sumPenalties: 0,
      },
      stats: { permissions: {} }
    };
  },
  methods: {
    resetFilter() {
      this.filter = {
        project: "",
        start: new Date().toISOString().split("T")[0],
        end: in1Week.toISOString().split("T")[0],
        location: "",
        place: "",
        position: "",
        persnr: "",
        accepted: "",
        confirmed: "",
        time_checked: "",
        Bez: "",
        checkinRequired: ""
      }
      this.getJobs()
    },
    
    exportCSV() {
      let text = ""
      let fields = []
      if (!this.billingMode) {
        text = "Lfd Nr.;Datum;Beginn;Ende;Einsatzort;Einsatzstelle;Position;Mitarbeiter;Angenommen;Bestätigt;Check-In notwendig?"
        fields = [
          "Lfd_Nr",
          "datum_formatted",
          "Soll_Von",
          "Soll_Bis",
          "Einsatzort_Beschreibung",
          "Einsatzstelle_Beschreibung",
          "Pos",
          "Mitarbeiternr",
          "MA_gebucht",
          "MA_bestätigt",
          "checkinRequired"
        ]
      } else {
        text = "Datum;Beginn;Ende;Stunden;Strafen;Einsatzort;Einsatzstelle;Position;Firma;Mitarbeiter;Angenommen;Bestätigt;Zeit geprüft;Bezahlt"
        fields = [
          "datum_formatted",
          "Soll_Von",
          "Soll_Bis",
          "hours",
          "Q",
          "Einsatzort_Beschreibung",
          "Einsatzstelle_Beschreibung",
          "Pos",
          "Firmenkurzel",
          "Mitarbeiternr",
          "MA_gebucht",
          "MA_bestätigt",
          "Ist_Zeiten_geprüft",
          "Bez"]
      }
      this.jobs.forEach(job => {
        text += "\n"
        job.hours=(job.hours+"").replace(".",",")
        fields.forEach(field => {
          if(job["Ist_Zeiten_geprüft"] && field=="Soll_Von") field="Von_Uhrzeit"
          if(job["Ist_Zeiten_geprüft"] && field=="Soll_Bis") field="Bis_Uhrzeit"
          text += job[field] === true ? "X" : job[field] === false ? "" : job[field]
          text += ";"
        })
      })
      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', new Date().toJSON().split("T")[0] + "_auftraege.csv");
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    checkClick(event) {
      if (this.selectedJob) return;
      if (event.target == this.$refs.modalbg) {
        this.showActions = false
      }
      else if (event.target.nodeName == "P") {
        if (
          event.target.innerText.includes("PSL") ||
          event.target.innerText.includes("SUB") ||
          event.target.innerText == "Offen"
        ) {
          if (this.jobEmployeeDropdown == 0) {
            this.filter.persnr = event.target.innerText;
            this.getJobs()
          } else {
            let job = this.jobs.filter((el) => {
              return el.id == this.jobEmployeeDropdown;
            })[0];
            job.Mitarbeiter = event.target.innerText;
            job.changed = true;
          }
        } else {
          this.filter.project = event.target.innerText;
          this.getJobs();
        }
      }
      if (event.target.nodeName != "INPUT") {
        this.showProjectDropdown = false;
        this.jobEmployeeDropdown = -1;
      }
    },
    changeCheckin(job) {
      if (this.stats.permissions.internalDispo) {
        this.changeJob(job, { checkinRequired: job.checkinRequired });
      } else {
        job.checkinRequired = !job.checkinRequired
      }
    },
    changeUser(job) {
      this.selectedJob = false
      this.changeJob(job, { Mitarbeiternr: job.Mitarbeiter.split(" (")[0] });
    },
    changeJob(job, changes) {
      CASP.changeJob(this.token, job.id, changes, (res) => {
        if (res) {
          this.getJobs();
        }
      });
    },
    getProjects() {
      let vm = this;
      CASP.getProjects(this.token, (res) => {
        if (res) {
          vm.allProjects = res;
          if (res.length == 1) {
            this.filter.project = res[0].name;
            this.getJobs();
          }
        }
      });
    },
    getUsers() {
      let vm = this;
      CASP.getUsers(this.token, (res) => {
        if (res) {
          vm.allUsers = res;
        }
      });
    },
    filterUsers(job) {
      if (job.id == 0) job.Mitarbeiternr = this.filter.persnr
      console.log("filter" + job.id);
      this.jobEmployeeDropdown = job.id;
      let vm = this
      let options = "";
      if (job.id == 0) {
        options = "<p class='dp'>Offen</p>";
      }
      this.allUsers.forEach((el) => {
        console.log(el.displayName.toLowerCase().includes(this.filter.persnr.toLowerCase()))
        console.log(vm.filter.persnr.toLowerCase())
        console.log(el.displayName.toLowerCase())
        if (
          this.jobEmployeeDropdown != 0 && (!job.Mitarbeiter ||el.displayName.toLowerCase().includes(job.Mitarbeiter.toLowerCase()))
        ) {
          options += "<p class='dp'>" + el.displayName + "</p>";
        } else if (
          this.jobEmployeeDropdown == 0 && el.displayName.toLowerCase().includes(this.filter.persnr.toLowerCase())
        ) {
          options += "<p class='dp'>" + el.displayName + "</p>";
        }
      });
      //console.log(options)
      if (this.$refs["jobDropdown" + job.id]) {
        console.log("setting")
        console.log("jobDropdown" + job.id)
        if (this.$refs["jobDropdown" + job.id][0]) this.$refs["jobDropdown" + job.id][0].innerHTML = options;
        else this.$refs["jobDropdown" + job.id].innerHTML = options;
        console.log(this.$refs["jobDropdown" + job.id])
      }

    },
    printPage() {
      this.showActions = false
      setTimeout(() => { print() }, 500)

    },
    filterProjects() {
      this.showProjectDropdown = true;
      let options = "";
      this.allProjects.forEach((el) => {
        if (el.name.toLowerCase().includes(this.filter.project.toLowerCase())) {
          options += "<p class='dp'>" + el.name + "</p>";
        }
      });
      if (this.$refs.projectList) {
        this.$refs.projectList.innerHTML = options;
      }
    },
    requestSMS(type) {
      CASP.requestSMS(this.token,
        this.filter, type, (res) => {
          if (res.status == "success") {
            this.bannerText = res.amountSent + " SMS gesendet."
            setTimeout(() => {
              this.bannerText = ""
            }, 2000)
          } else if (res.error) {
            this.bannerText = res.error
            this.bannerMode = "error"
            setTimeout(() => {
              this.bannerText = ""
              this.bannerMode = "success"
            }, 2000)
          }
        })
    },
    getJobs() {
      let vm = this;
      this.jobs = []
      CASP.getAdminJobs(
        this.token,
        this.filter,
        (res) => {
          vm.meta = res.meta
          vm.jobs = res.jobs;
          vm.searched = true;
          vm.loading = false;
        }
      );
    },
  },
  computed: {
    today: function () {  
      let d = new Date().toISOString().split("T")[0].split("-");
      return d[2] + "." + d[1] + "." + d[0];
    },
    datestamp: function () {
      return new Date().toISOString().split("T")[0];
    },
  },
  mounted: function () {
    let vm = this
    document.getElementById("mainspinner").style.display = "none";
    if (this.cookies.get("authtoken")) {
      this.token = this.cookies.get("authtoken");
      CASP.isAuthenticated(this.token, (auth) => {
        if (!auth) {
          window.location = "/admin/login";
        } else {
          this.getProjects();
          this.getUsers();
          this.getJobs();
          CASP.getStats(this.token, (status, response) => {
            if (status == 200) {
              vm.stats = JSON.parse(response)
            }
          })
        }
      });
    } else {
      window.location = "/admin/login";
    }
  },
};
</script>
<style scoped>
#filterrow td {
  border-bottom: 2px solid black;
}

tr {
  page-break-inside: avoid;
}

@region {
  size: auto;
}

@region table {
  break-before: avoid;
}

table {
  page-break-before: auto;
  /* or always, avoid */
  page-break-after: auto;
  /* or always, avoid */
}

td {
  white-space: nowrap;
}

@media print {
  #printtest {
    display: none
  }

  button {
    display: none
  }

  td,
  th {
    padding: 2px;
    margin: 2px;
    max-width: 75px;
    white-space: normal;
    word-break: break-word;

  }

  table {}
}
</style>