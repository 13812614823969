<template>
  <div ref="modalbg" class="z-30 !fixed center w-full h-full lg:px-20 lg:py-10 bg-black/50 text-center" @click="checkClick($event)">
    <div class="bg-white w-full lg:w-2/3 inline-block lg:rounded-3xl overflow-y-auto lg:px-8 pt-4 basis-0 relative text-left">
      <div class="text-center w-full border-b-4 border-black p-2">
        <div style="position: absolute; right: 40px;color: black " class="cursor-pointer" @click="$emit('close')">
          <i ref="xmark" class="fa fa-xmark text-4xl"></i>
        </div>
        <span class="mt-10 font-bold text-3xl text-black">{{editableJob.Einsatzstelle_Beschreibung}}</span>
      </div>
      <div class="w-full overflow-x-auto">
        <table class="bg-white w-full">
          <tbody>
            <tr>
              <th class="bg-gray-500 text-white">Projekt</th>
              <td>{{ editableJob.Vertragsbeschreibung }}</td>
            </tr>
            <tr>
              <th class="bg-gray-500 text-white">Einsatzort</th>
              <td>{{ editableJob.Einsatzort_Beschreibung }}</td>
            </tr>
            <tr>
              <th class="bg-gray-500 text-white">Einsatzstelle</th>
              <td>{{ editableJob.Einsatzstelle_Beschreibung }}</td>
            </tr>
            <tr>
              <th class="bg-gray-500 text-white">Treffpunkt</th>
              <td>{{ editableJob.Treffpunkt }}</td>
            </tr>
            <tr>
              <th class="bg-gray-500 text-white w-1/3">Mitarbeiter</th>
              <td>
                <input v-model="editableJob.Mitarbeiternr" placeholder="Mitarbeiternr" @focus="filterUsers()" @click="filterUsers()" class="border-b-2 w-64 p-2 border-gray-500" @input="filterUsers()" />
                <div v-if="showEmployeeDropdown" ref="modalEmployeeDropdown" class="z-10 border-gray-500 absolute p-2 bg-white max-h-96 overflow-y-auto"></div>
                <button v-if="editableJob.maChanged" class="mx-1 inline-block font-bold mt-4 bg-gray-500 rounded p-2 text-white" @click="$emit('changeUser', editableJob)">Speichern</button>
              </td>
            </tr>
            <tr>
              <th class="bg-gray-500 text-white">Gebucht / Bestätigt / Zeit geprüft / Bezahlt:</th>
              <td class="text-2xl"><i v-if="editableJob.MA_gebucht" class="mx-3 fa fa-check" /><i v-else class="mx-3 fa fa-xmark" />/<i v-if="editableJob['MA_bestätigt']" class="mx-3 fa fa-check" /><i v-else class="mx-3 fa fa-xmark" />/<i
                v-if="editableJob['Ist_Zeiten_geprüft']" class="mx-3 fa fa-check" /><i v-else class="mx-3 fa fa-xmark" />/<i
                v-if="editableJob.Bez" class="mx-3 fa fa-check" /><i v-else class="mx-3 fa fa-xmark" /></td>
            </tr>
            <tr>
              <th class="bg-gray-500 text-white">Datum</th>
              <td>{{ editableJob.datum_formatted }}</td>
            </tr>
            <tr>
              <th class="bg-gray-500 text-white">Uhrzeit</th>
              <td>{{ editableJob.Soll_Von }}-{{ editableJob.Soll_Bis }}</td>
            </tr>
            <tr>
              <th class="bg-gray-500 text-white">Position</th>
              <td>{{ editableJob.Pos }}</td>
            </tr>
            <tr>
              <th class="bg-gray-500 text-white">Funktion</th>
              <td>{{ editableJob.Funktion }}</td>
            </tr>
            <tr>
              <th class="bg-gray-500 text-white">Sonstige Infos</th>
              <td>{{ editableJob.Bemerkungen }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "JobModal",
    props: {
      job: Object,
      users: Array,
      changeable: Boolean
    },
    data() {
      return {
        showEmployeeDropdown: false,
        editableJob: {}
      }
    },
    methods: {
      checkClick(event) {
        console.log(event)
        if (event.target.nodeName == "P") {
          if (
            event.target.innerText.includes("PSL") ||
            event.target.innerText.includes("SUB")
          ) {
            console.log(event.target.innerText)
            this.editableJob.Mitarbeiternr = event.target.innerText;
            this.editableJob.maChanged = true;
          }
        }
        if (event.target.nodeName != "INPUT") {
          console.log("closing dropdown")
          this.showEmployeeDropdown = false;
        } if (event.target == this.$refs.modalbg) {
          this.$emit("close");
        }
      },

      filterUsers() {
        console.log(this.job.Mitarbeiternr)
        console.log(this.editableJob.Mitarbeiternr)

        this.showEmployeeDropdown = true
        let options = "";
        this.users.forEach((el) => {
          if (
            el.displayName.toLowerCase().includes(this.editableJob.Mitarbeiternr.toLowerCase())
          ) {
            options += "<p class='dp'>" + el.displayName + "</p>";
          }
        });
        if (this.$refs["modalEmployeeDropdown"]) {
          this.$refs["modalEmployeeDropdown"].innerHTML = options;

        }
      },
    },
    created: function () {
      this.editableJob = JSON.parse(JSON.stringify(this.job))
    }
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .detailline {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
  }

  .attributename {
    display: inline-block;
    flex-grow: 1;
    flex-basis: 33.33%;
    font-weight: bold;
  }

  .attributevalue {
    border-bottom: 1px solid black;
    flex-grow: 2;
    flex-basis: 66.66%;
  }
</style>