<template>
  <toolbar title="Impressum" />
  <div class="w-full relative">
    <div class="entry-content mt-5">
          <h2>Angaben gemäß § 5 TMG:</h2>
<p><strong>CASP GmbH</strong><br />Alfred Delp Strasse 1<br />50354 Hürth Germany</p>
<p><strong>Vertreten durch:</strong></p>
<p>Alessandro Bellini<br />Stefanos Gountoulas</p>
<p><strong>Kontakt:</strong></p>
<p>Fon: <a href="tel:+492233402850">+49 2233 402850</a><br />Fax:  +49 2233 4028510</p>
<p>Mail: <a href='mailto:info@casp-security.com'>info@casp-security.com</a></p>
<p><strong>Registereintrag:</strong></p>
<p>Eintragung im Handelsregister<br />Registergericht: Amtsgericht Köln<br />Registernummer: HRB72390<br />Geschäftsführer: Alessandro Bellini, Stefanos Gountoulas</p>
<p><span style="font-weight: 600;">Haftpflichtversicherung</span><b>:</b></p>
<p>Zurich Insurance plc in 50427 Köln<br />Personenschäden:          3.000.000 Euro<br />Sachschäden:                   3.000.000 Euro<br />Vermögendsschäden:        300.000 Euro</p>
<p><strong>Umsatzsteuer-ID:</strong></p>
<p>Umsatzsteuer Identifikationsnummer: DE277631043</p>
<p><strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</strong></p>
<p>Alessandro Bellini<br />Alfred Delp Strasse 1<br />50354 Hürth Germany</p>
<p> </p>
<p><strong>Verantwortlich für die Gestaltung der Website:</strong></p>
<p>TAR MediaDesign<br />Brühler Straße 1<br />50968 Köln</p>
<p>Web: <a href="http://www.tarcgn.de" target="_blank" rel="noopener noreferrer">www.tarcgn.de</a><br />Mail: <a class="mailto-link mail-link" href="javascript:;" data-enc-email="gubznf[at]gnepta.qr" data-wpel-link="ignore">thomas@t</a>arcgn.de</p>
<div style="color: red;"> </div>
<p><strong>Rechtlicher Hinweis</strong><br />Wir, die Herausgeber, sind bemüht, auf unserern Internetseiten stets richtige, aktuelle und vollständige Informationen bereitzustellen und ändern oder ergänzen diese bei Bedarf laufend und ohne vorherige Ankündigung. Dennoch müssen wir für die Korrektheit, Aktualität und Vollständigkeit jede Gewähr, Haftung oder Garantie ausschließen.</p>
<p>Dies gilt auch für alle Verweise und Weiterleitungen, die wir auf unserer Internetseite  anbieten. Wir können für die Inhalte bzw. Auswirkungen solcher externen Internetseiten, die Sie mittles Hyperlinks oder sonstige Hinweise erreichen, keine Verantwortung  übernehmen. Ferner haften wir nicht für direkte oder indirekte Schäden, die auf  Informationen zurückgeführt werden können, die auf externen Internetseiten stehen.</p>


<p></p>
        </div>
  </div>
</template>
<script>
import Toolbar from "@/components/Toolbar.vue";
import { useCookies } from "vue3-cookies";
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  components: { Toolbar },
  created: function () {
    document.getElementById("mainspinner").style.display = "none";
    if (this.cookies.get("authtoken")) {
      this.token = this.cookies.get("authtoken");
    } else {
      window.location = "/forbidden";
    }
  },
};
</script>
