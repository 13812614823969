<template>
    <div class="joblist" style="padding: 5px">
      <div v-if="jobs.length == 0 && !loading" style="width: 100%;text-align: center;margin-top: 10px; font-weight: bold">Hier sind keine Aufträge</div>
      <template v-for="job in jobs" :key="job.id">
          <Job :job="job"/>
          <div style="height: 2px;background:lightgray;width: 100%"></div>
      </template>
    </div>
</template>

<script>
import Job from './Job.vue'
export default {
  name: 'Joblist',
  props: {
    jobs: Array,
    loading: Boolean
  },
  components: {
    Job
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
