<template>
<div class="screencenter" style="height: 600px; width: 200px">
  <span style="font-weight: bold; font-size: 30px">Zugriff verweigert</span>
  <i style="margin-top: 30px; color: black" class="fas fa-lock fa-10x"></i>
  <span style="display: block;font-weight: bold;  margin-top: 20px">Es scheint ein Problem mit ihren Zugriffsrechten zu geben. Bitte loggen Sie sich erneut ein</span>
  </div>
</template>
<script>
export default {
  created: function () {
    document.getElementById('mainspinner').style.display = 'none'
  }
}
</script>
