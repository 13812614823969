<template>
  <toolbar title="Nachrichten" :backUrl="'/'" />
  <div class="w-full text-center">
    <div class="w-full md:w-5/6 inline-block text-left">
      <Spinner v-if="loading" />
      <div class="tab">
        <button style="flex-grow: 4; font-size: 15px" v-bind:class="{ tabactive: showChats }" @click="setChats()">
          Chats
        </button>
        <button style="font-size: 15px" v-bind:class="{ tabactive: showNew }" @click="setNew()">
          Neuer Chat
        </button>
        <button style="font-size: 15px" v-bind:class="{ tabactive: showHint }" class="text-red-500" @click="setHint()">
          Hinweis
        </button>
      </div>
      <div v-if="showChats" style="padding: 3px">
        <template v-for="chat in chats" :key="chat.id">
          <div class="job" style="padding: 5px; display: block" :onclick="'window.location=\'/chat/' + chat.id + '\''">
            <span style="font-size: 20px; font-weight: bold">{{ chat.name }}</span>
            <div class="detailrow" v-bind:class="{ unread: !chat.read }">
              <svg v-if="chat.incoming" class="svg-inline--fa fa-arrow-left" style="color: black;" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"></path></svg>
              <svg v-else class="svg-inline--fa fa-arrow-right" style="color: black;" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path></svg>
              <span style="margin-left: 10px; font-weight: bold">{{
        chat.last
      }}</span>
              <div style="margin-top: 10px; height: 1px; background: grey; width: 100%"></div>
            </div>
          </div>
        </template>
      </div>
      <div v-if="showNew">
        <div class="job" style="padding: 15px; display: block">
          <div v-if="error">
            <h3 style="color: red">{{ error }}</h3>
          </div>
          <div style="margin-top: 15px; margin-bottom: 15px">
            <div>
              <span style="font-weight: bold">Kontakt:</span>
            </div>
            <input v-model="contact" style="width: 100%; box-sizing: border-box" list="chatContacts" />
            <datalist id="chatContacts">
              <template v-for="contact in chatContacts" :key="contact">
                <option>{{ contact }}</option>
              </template>
            </datalist>
          </div>
          <div style="margin-top: 15px; margin-bottom: 15px">
            <span style="display: inline-block; font-weight: bold; margin-bottom: 5px">Nachricht:</span>
            <div>
              <textarea v-model="newMessage" style="padding: 5px; box-sizing: border-box; width: 100%" rows="1" @focus="resizeTextarea" @keyup="resizeTextarea"></textarea>
            </div>
          </div>
          <div style="width: 100%; text-align: left">
            <button style="
            display: inline-block;
            color: rgb(0, 122, 255);
            border: 3px solid rgb(0, 122, 255);
            border-radius: 8px;
            font-weight: bold;
            font-size: 18px;
            width: 40%;
            background-color: white;
          " @click="createChat">
              Senden
            </button>
          </div>
        </div>
      </div>
      <div v-if="showHint">
        <div class="job" style="padding: 15px; display: block">
          <div v-if="error">
            <h3 style="color: red">{{ error }}</h3>
          </div>
          <div class="text-center">
            <span class="font-bold text-xl">Hinweis geben</span>
          </div>
          <div>
            <span class="text">Nach dem Hinweisgeberschutzgesetz darf jeder Mitarbeiter Verstöße gegen Rechtsvorschriften melden, ohne, dass dies negative Auswirkungen auf den Hinweisgebenden hat. Folgende Meldungen sind in diesem Gesetz
              enthalten und können somit bedenkenlos gemeldet werden:
              <li>Verstöße gegen Strafvorschriften: Dies umfasst jede Strafnorm nach deutschem Recht.</li>
              <li>Verstöße, die mit einem Bußgeld bedroht sind, wenn der Verstoß den Schutz von Beschäftigten betrifft (Arbeits- und Gesundheitsschutz, Mindestlohn) </li>
              <li>Verstöße gegen EU-Recht (Geldwäsche, Umweltschutz, Datenschutz, ...) </li>
            </span>
            <a class="underline font-bold inline-block mt-4" href="https://www.ihk.de/stuttgart/fuer-unternehmen/recht-und-steuern/arbeitsrecht/whistleblowing-5169770">Mehr Infos</a>
          </div>
          <div style="margin-top: 15px; margin-bottom: 15px">
            <span style="display: inline-block; font-weight: bold; margin-bottom: 5px">Nachricht:</span>
            <div>
              <textarea v-model="newMessage" style="padding: 5px; box-sizing: border-box; width: 100%" rows="1" @focus="resizeTextarea" @keyup="resizeTextarea"></textarea>
            </div>
          </div>
          <div style="width: 100%; text-align: center">
            <div class="inline-block"><input type="checkbox" v-model="anonymous" id="hint_anonymous" />
              <label for="hint_anonymous">Anonym melden</label>
            </div><br><br>

            <button style="
              display: inline-block;
              color: rgb(0, 122, 255);
              border: 3px solid rgb(0, 122, 255);
              border-radius: 8px;
              font-weight: bold;
              font-size: 18px;
              width: 40%;
              background-color: white;
            " @click="sendHint">
              Senden
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CASP from "../../CASP";
import Spinner from "@/components/Spinner";
import { useCookies } from "vue3-cookies";
import Toolbar from "@/components/Toolbar.vue";

export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  name: "Jobs",
  components: {
    Spinner,
    Toolbar,
  },
  data() {
    return {
      showChats: true,
      showHint: false,
      showNew: false,
      chatContacts: [],
      chats: [],
      token: "",
      newMessage: "",
      contact: "",
      error: false,
      loading: true,
      anonymous: false,
    };
  },
  methods: {
    hideAll() {
      this.showChats = false;
      this.showNew = false;
      this.showHint = false;
    },
    setNew() {
      this.hideAll();
      this.showNew = true;
    },
    setHint() {
      this.hideAll();
      this.showHint = true;
    },
    setChats() {
      this.hideAll();
      this.showChats = true;
    },
    resizeTextarea(e) {
      const area = e.target;
      area.style.overflow = "hidden";
      area.style.height = area.scrollHeight + "px";
    },
    createChat() {
      const comp = this;
      CASP.createChat(this.token, {
        "contact": this.contact,
        "message": this.newMessage,
        "hint": false,
        "anonymous": false
      }, (response) => {
        if (response.success) {
          window.location = "/chat/" + response.chatId;
        } else {
          comp.error = response.error;
        }
      });
    },
    sendHint() {
      const comp = this;
      CASP.createChat(this.token, {
        "contact": "",
        "message": this.newMessage,
        "hint": true,
        "anonymous": this.anonymous
      }, (response) => {
        if (response.success) {
          window.location = "/chat/" + response.chatId;
        } else {
          comp.error = response.error;
        }
      });
    },
  },
  created: function () {
    document.getElementById("mainspinner").style.display = "none";
    if (this.cookies.get("authtoken")) {
      this.token = this.cookies.get("authtoken");
    } else {
      window.location = "/forbidden";
    }
    const comp = this;
    CASP.getChats(this.token, (chats) => {
      comp.chats = chats;
      comp.loading = false;
    });
    CASP.getChatContacts(this.token, (chatContacts) => {
      comp.chatContacts = chatContacts;
    });
  },
};
</script>
<style scoped>
.tab {
  overflow: hidden;
  background-color: white;
  display: flex;
}

input:focus {
  outline: none;
  border: unset;
  border-bottom: 2px solid rgb(0, 122, 255);
}

input,
textarea {
  border: unset;
  outline: none;
  border-bottom: 1px solid black;
}

textarea:focus {
  border-bottom: 1px solid rgb(0, 122, 255);
  outline: none;
}

.small {
  flex-grow: 2;
}

.large {
  flex-grow: 1;
}

button {
  font-weight: bold;
}

.unread {
  color: rgb(245, 0, 0);
}

/* Style the buttons that are used to open the tab content */
button {
  color: gray;
  border: none;
  outline: none;
  cursor: pointer;
  background: inherit;
  padding: 14px 16px;
  transition: 0.3s;
  flex-grow: 1;
  border-bottom: 3px solid gray;
}

/* Change background color of buttons on hover
.tab button:hover {
  background-color: rgb(37, 37, 37);
}*/

/* Create an active/current tablink class */
.tabactive {
  color: rgb(0, 122, 255);
  border-bottom: 3px solid rgb(0, 122, 255);
}

/* Style the tab content */
.tabcontent {
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
</style>