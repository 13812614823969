<template>
  <div class="w-full h-full relative text-center m-0 p-0">

    <div class="fixed w-full z-10 text-center">
      <img class="w-full lg:mt-24 inline sm:hidden" src="@/assets/banner.jpg" />
      <img class="w-full sm:inline hidden" src="@/assets/DesktopBanner.png" />
    </div>
    <div class="relative w-full h-full text-center overflow-y-auto">
      <div v-if="groupDetails">
        <div class="modalbg">
          <div class="modalcontent p-5 lg:!w-auto !text-center">
            <div class="absolute right-5 font-black cursor-pointer" @click="groupDetails = null">
              <i ref="xmark" class="fa fa-xmark text-4xl"></i>
            </div>
            <input class="inline-block border-b-2 border-black w-96 text-3xl font-bold mt-2 mx-10 text-center" placeholder="Gruppenname" v-model="groupDetails.name">
            <div class="md:grid grid-cols-2 mt-5 text-left">
              <div class="mx-2">
                <h2 class="text-xl font-bold">Gruppenmitglieder</h2>
                <div v-for="member in groupDetails.members" :key="member.id">
                  <span class="cursor-pointer hover:font-bold" @click="groupDetails.members.splice(groupDetails.members.indexOf(member), 1)">{{ member.surname }}, {{ member.name }} ({{ member.persnr }})</span>
                </div>
              </div>
              <div class="mx-2">
                <h2 class=" text-xl font-bold">Alle Nutzer</h2>
                <div class="h-96 overflow-y-auto p-2 rounded-lg">
                  <div v-for="user in allUsers" :key="user.id">
                    <span class="cursor-pointer hover:font-bold" @click="groupDetails.members.push(user)">{{ user.surname }}, {{ user.name }} ({{ user.persnr }})</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center mt-5">
              <button class="text-center mt-2" @click="saveMessageGroup()">Gruppe speichern</button>
            </div>
          </div>
        </div>
      </div>
      <div class="relative w-full text-center pt-48 sm:pt-[10%] xl:w-5/6 xl:inline-block">
        <button class="hidden md:inline absolute left-0 text-black bg-caspyellow border-4 border-gray-500 rounded-lg w-32 py-2 font-bold" onclick="window.location='/admin'">
          Zurück
        </button>
        <button @click="groupDetails = { name: '', members: [] }" class="hidden md:inline absolute right-0 text-white bg-gray-500 border-4 rounded-lg w-32 py-2 font-bold">
          Neue Gruppe
        </button>
        <span class="font-bold text-4xl">Nachrichtencenter</span><br />

        <button class="inline md:hidden text-black rounded-lg mt-5 w-32 py-2 font-bold" onclick="window.location='/admin'">
          Zurück
        </button>
        <div class="hidden">
          <input ref="fileinput" id="fileinput" type="file" accept=".jpg, .jpeg" @change="prepareUploadFile()" />
        </div>
        <div v-if="allUsers.length > 0" id="tablecontent" class="w-full">
          <table class="max-w-full overflow-x-auto md:table">
            <thead>
              <th>Name</th>
              <th>Mitglieder</th>
              <th>Letzte Nachricht</th>
              <th>Neue Nachricht</th>
              <th class="text-center">Aktionen</th>
            </thead>
            <tbody>
              <tr v-for="group in allGroups" :key="group.id">
                <td>{{ group.name }}</td>
                <td>
                  <span class="block" v-for="member in group.members" :key="member.id">{{ member.surname }}, {{ member.name }} ({{ member.persnr }})</span>
                </td>
                <td><pre>{{ group.lastMessage.message }}</pre></td>
                <td>
                  <div class="w-96">
                    <textarea class="bg-gray-200 w-full p-2" v-model="group.newMessage"></textarea>
                    <button class="float-right" @click="sendMessage(group)">Senden</button>
                  </div>
                </td>

                <td class="align-top">
                  <div class="text-center">
                    <button class="inline-block font-bold mx-1 mt-4 bg-gray-500 rounded p-2 text-white" @click="groupDetails = group">
                      Gruppe ändern
                    </button>
                    <button class="inline-block font-bold mx-1 mt-4 bg-gray-500 rounded p-2 text-white" @click="deleteGroup(group)">
                      Gruppe löschen
                    </button>
                  </div>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CASP from "../../CASP";
import { useCookies } from "vue3-cookies";
import JobModal from "@/components/JobModal.vue";
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  name: "Jobs",
  components: { JobModal },
  data() {
    return {
      token: "",
      allUsers: [],
      groupDetails: null,
      allGroups: [],
    };
  },
  methods: {
    getUsers() {
      let vm = this;
      CASP.getUsers(this.token, (res) => {
        if (res) {
          vm.allUsers = res
          this.allUsers.sort((a, b) => {
            const surnameA = a.surname.toLowerCase();
            const surnameB = b.surname.toLowerCase();

            if (surnameA < surnameB) {
              return -1;
            }
            if (surnameA > surnameB) {
              return 1;
            }
            return 0;
          });
        }
      });
    },
    getMessageGroups() {
      let vm = this
      CASP.getMessageGroups(this.token, (res) => {
        if (res) {
          vm.allGroups = res;
        }
      })
    },
    deleteGroup(group) {
      let vm = this
      CASP.deleteMessageGroup(this.token, group, (res) => {
        this.getMessageGroups()
      })
    },
    sendMessage(group) {
      let vm = this
      CASP.sendMessageToGroup(this.token, group, { message: group.newMessage },(res) => {
        if(res) {
          this.getMessageGroups()
        }
      })
    },
    saveMessageGroup() {
      CASP.saveMessageGroup(this.token, this.groupDetails, (res) => {
        if (res) {
          this.groupDetails = null
          this.getMessageGroups()
        }
      })
    }
  },
  created: function () {
    document.getElementById("mainspinner").style.display = "none";
    if (this.cookies.get("authtoken")) {
      this.token = this.cookies.get("authtoken");
      CASP.isAuthenticated(this.token, (auth) => {
        if (!auth) {
          window.location = "/admin/login";
        } else {
          this.getUsers();
          this.getMessageGroups();
        }
      });
    } else {
      window.location = "/admin/login";
    }
  },
};
</script>
<style scoped></style>