<template>
  <router-view />
</template>

<style>
html{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  height: 100%;
}
body{
  padding: unset;
  margin: unset;
  height: 100%;
  background: #f5f5f5;
}
div{
  box-sizing: border-box;
}
div#app{
  height: 100%;
}
.tab {
  overflow: hidden;
  background-color: white;
  display: flex;
}
</style>
