/* eslint-disable standard/no-callback-literal */


function get(token, path, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('GET', process.env.VUE_APP_API_URL + path)
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    if (req.status !== 200) {
      callback(false)
    } else {
      callback(JSON.parse(req.responseText))
    }
  })
  req.send()
}

function getWithStatus(path, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('GET', process.env.VUE_APP_API_URL + path)
  console.log(path)
  req.onload=(event)=> {
    if (req.status !== 200) {
      callback(req.status, req.responseText)
    } else {
      callback(req.status, JSON.parse(req.responseText))
    }
  }
  req.onerror= (event)=> {
    callback(req.status, req.responseText)
  }
  req.send()
}
function deleteWithStatus(path, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('DELETE', process.env.VUE_APP_API_URL + path)
  req.addEventListener('load', function (event) {
    if (req.status !== 200) {
      callback(req.status, req.responseText)
    } else {
      callback(req.status, JSON.parse(req.responseText))
    }
  })
  req.send()
}
function postWithStatus(path, data, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('POST', process.env.VUE_APP_API_URL + path)
  req.setRequestHeader('content-type', 'application/json')
  req.addEventListener('load', function (event) {
    if (req.status !== 200) {
      callback(req.status, req.responseText)
    } else {
      callback(req.status, JSON.parse(req.responseText))
    }
  })
  req.send(JSON.stringify(data))
}

function putWithStatus(path, data, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('PUT', process.env.VUE_APP_API_URL + path)
  req.setRequestHeader('content-type', 'application/json')
  req.addEventListener('load', function (event) {
    if (req.status !== 200) {
      callback(req.status, req.responseText)
    } else {
      callback(req.status, JSON.parse(req.responseText))
    }
  })
  req.send(JSON.stringify(data))
}

function post(token, path, data, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('POST', process.env.VUE_APP_API_URL + path)
  req.setRequestHeader('content-type', 'application/json')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    if (req.status !== 200) {
      callback(false)
    } else {
      callback(JSON.parse(req.responseText))
    }
  })
  req.send(JSON.stringify(data))
}
function httpDelete(token, path, data, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('DELETE', process.env.VUE_APP_API_URL + path)
  req.setRequestHeader('content-type', 'application/json')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    if (req.status !== 200) {
      callback(false)
    } else {
      callback(JSON.parse(req.responseText))
    }
  })
  req.send(JSON.stringify(data))
}
function put(token, path, data, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('PUT', process.env.VUE_APP_API_URL + path)
  req.setRequestHeader('content-type', 'application/json')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    if (req.status !== 200) {
      callback(false)
    } else {
      callback(JSON.parse(req.responseText))
    }
  })
  req.send(JSON.stringify(data))
}

function getAllJobs(token, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('GET', process.env.VUE_APP_API_URL + '/jobs')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(JSON.parse(req.responseText))
  })
  req.send()
}

function getJobsInGroup(token, groupId, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('GET', process.env.VUE_APP_API_URL + '/jobs/groups/' + groupId)
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(JSON.parse(req.responseText))
  })
  req.send()
}
function getChats(token, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('GET', process.env.VUE_APP_API_URL + '/chats')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(JSON.parse(req.responseText))
  })
  req.send()
}

function getChatContacts(token, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('GET', process.env.VUE_APP_API_URL + '/chats/contacts')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(JSON.parse(req.responseText))
  })
  req.send()
}

function getArchive(token, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('GET', process.env.VUE_APP_API_URL + '/jobs/archive')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(JSON.parse(req.responseText))
  })
  req.send()
}
function getJobById(token, id, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('GET', process.env.VUE_APP_API_URL + '/jobs/' + id)
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(JSON.parse(req.responseText))
  })
  req.addEventListener('error', function (event) {
    callback(false)
  })
  req.send()
}

function createChat(token, content, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('POST', process.env.VUE_APP_API_URL + '/chats')
  req.setRequestHeader('content-type', 'application/json')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(JSON.parse(req.responseText))
  })
  req.send(JSON.stringify(content))
}
function getChatById(token, id, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('GET', process.env.VUE_APP_API_URL + '/chats/' + id)
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(JSON.parse(req.responseText))
  })
  req.send()
}

function sendMessageInChat(token, id, message, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('POST', process.env.VUE_APP_API_URL + '/chats/' + id)
  req.setRequestHeader('content-type', 'application/json')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    return callback(req.status === 201)
  })
  req.send(JSON.stringify({
    message: message
  }))
}

function setAttendance(token, id, von, bis, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('PUT', process.env.VUE_APP_API_URL + '/jobs/' + id)
  req.setRequestHeader('content-type', 'application/json')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    return callback(req)
  })
  req.send(JSON.stringify({
    Von_Uhrzeit: von,
    Bis_Uhrzeit: bis
  }))
}

function isAuthenticated(token, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('GET', process.env.VUE_APP_API_URL + '/rest/auth/authenticate')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(req.status === 200)
  })
  req.send()
}

function acceptJob(token, id, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('GET', process.env.VUE_APP_API_URL + '/jobs/' + id + '/accept')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(req.status, req.responseText)
  })
  req.send()
}

function acceptJobGroup(token, groupid, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('GET', process.env.VUE_APP_API_URL + '/jobs/groups/' + groupid + '/accept')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(req.status, req.responseText)
  })
  req.send()
}

function declineJob(token, id, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('GET', process.env.VUE_APP_API_URL + '/jobs/' + id + '/decline')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(req.status, req.responseText)
  })
  req.send()
}

function confirmJob(token, id, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('GET', process.env.VUE_APP_API_URL + '/jobs/' + id + '/confirm')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(req.status, req.responseText)
  })
  req.send()
}

function getStats(token, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('GET', process.env.VUE_APP_API_URL + '/stats')
  req.setRequestHeader('authorization', token)
  req.onload=(event)=> {
    if (req.status == 200 && localStorage) {
        localStorage.setItem("stats", req.responseText);
    }
    callback(req.status, req.responseText)
  }
  req.onerror= (event)=> {
    callback(req.status, req.responseText)
  }
  req.send()
}

function getUser(token, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('GET', process.env.VUE_APP_API_URL + '/user/')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(req.status, req.responseText)
  })
  req.send()
}

function getAdminUser(token, persnr, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('GET', process.env.VUE_APP_API_URL + '/rest/users/?persnr=' + persnr)
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(req.status, req.responseText)
  })
  req.send()
}

function getFiles(token, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('GET', process.env.VUE_APP_API_URL + '/assets/')
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    callback(req.status, req.responseText)
  })
  req.send()
}

function getFile(token, id, callback) {
  getFileOTL(token, id, (r, s) => {
    var req = new XMLHttpRequest()
    req.withCredentials = true
    req.open('GET', process.env.VUE_APP_API_URL + JSON.parse(s).link)
    req.setRequestHeader('authorization', token)
    req.responseType = 'arraybuffer';
    req.addEventListener('load', function (event) {
      if (req.status == 200) {
        callback(req.response, req.getResponseHeader("content-type"))
      }
    })
    req.send()
  })

}

function getFileOTL(token, id, callback) {
  var req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('GET', process.env.VUE_APP_API_URL + '/assets/otl/' + id)
  req.setRequestHeader('authorization', token)
  req.addEventListener('load', function (event) {
    if (req.status == 200) {
      callback(req.status, req.responseText)
    }
  })
  req.send()
}

function generateLoginCode(token, callback) {
  get(token, "/rest/auth/loginCode", callback)
}
function getProjects(token, callback) {
  get(token, "/rest/projects", callback)
}

function requestSMS(token, filter, type, callback) {
  filter.type = type
  post(token, "/rest/jobs/sms", filter, callback)
}

function getAdminJobs(token, filter, callback) {
  queryurl = "?"
  k = Object.keys(filter)
  for (i = 0; i < k.length; i++) {
    if (queryurl != "?") queryurl += "&"
    queryurl += k[i] + "=" + filter[k[i]]
  }
  get(token, "/rest/adminjobs" + queryurl, callback)
}
function getPlanJobs(token, filter, callback) {
  queryurl = "?"
  console.log(filter)
  k = Object.keys(filter)
  console.log(k)
  for (i = 0; i < k.length; i++) {
    if (queryurl != "?") queryurl += "&"
    queryurl += k[i] + "=" + filter[k[i]]
  }
  get(token, "/rest/planjobs" + queryurl, callback)
}
function getUsers(token, callback) {
  get(token, "/rest/users", callback)
}
function getPicture(token, user, callback) {
  get(token, "/rest/users/" + user.id + "/picture", callback)
}
function getPresets(token, callback) {
  get(token, "/rest/presets", callback)
}
function getSpots(token, location, callback) {
  get(token, "/rest/locations/" + location + "/spots", callback)
}
function getCAs(token, callback) {
  get(token, "/rest/companies", callback)
}
function changeUser(token, userId, changes, callback) {
  put(token, "/rest/users/" + userId, changes, callback)
}

function sendInvitation(token, userId, callback) {
  post(token, "/rest/users/sms", { type: "invitation", user: userId }, callback)
}

function changeJob(token, jobId, changes, callback) {
  put(token, "/rest/adminjobs/" + jobId, changes, callback)
}
function createJob(token, job, callback) {
  post(token, "/rest/adminjobs", job, callback)
}
function sendCheckinReport(token, filter, mail, callback) {
  post(token, "/rest/checkins/sendReport", {
    filter: filter,
    mail: mail
  }, callback)
}
function savePicture(token, user, image, callback) {
  put(token, "/rest/users/" + user.id + "/picture", image, callback)
}
function checkLoginCode(code, callback) {
  post("", "/rest/auth/loginCode", { code: code }, callback)
}
function getCheckinJobs(token, callback) {
  get(token, "/rest/checkins", callback)
}
function getMessageGroups(token, callback) {
  get(token, "/rest/messagegroups", callback)
}
function checkInJob(token, jobId, callback) {
  post(token, "/rest/checkins", { jobId: jobId }, callback)
}
function saveMessageGroup(token, group, callback) {
  post(token, "/rest/messagegroups", group, callback)
}
function sendMessageToGroup(token, group, message, callback) {
  post(token, "/rest/messagegroups/" + group.id + "/send", message, callback)
} function deleteMessageGroup(token, group, callback) {
  httpDelete(token, "/rest/messagegroups/" + group.id, {}, callback)
}
function getDocumentUsers(contract,location, cb) {
  getWithStatus("/rest/admin/docs/users?contract=" + contract + "&location=" + location, cb)
}

function isOnline(cb){
  getWithStatus("/ping", (status,res) => {
    if(status == 200){
      cb(true)
    }else{
      cb(false)
    }
  })
}

function updateDocumentUser(permission, cb) {
  if (permission.user.id == -1) {
    postWithStatus("/rest/admin/docs/users", permission, cb)
  } else {
    putWithStatus("/rest/admin/docs/users/" + permission.user.id, permission, cb)
  }
}

function requestMailCode(mail, cb) {
  postWithStatus("/rest/auth/mailcode", { mail: mail, requestId: generateRequestId(mail) }, cb)
}

function deleteExternalDocumentPermission(permission, cb) {
  deleteWithStatus("/rest/admin/docs/users/permission/" + permission.id, cb)
}

function checkMailCode(code, cb) {
  postWithStatus("/rest/auth/mailcode/redeem", { code: code }, cb)
}

function getOpenDocuments(cb) {
   getWithStatus("/rest/admin/docs", cb)
}

function getJobDocumentsForUser(cb){
  getWithStatus("/docs", cb)
}

function getSameJobs(job, cb) {
  getWithStatus("/docs/sameJobs?jobid=" + job.id, cb)
}

function getSignaturesForJob(job, cb) {
  getWithStatus("/docs/" + job.id + "/signatures", cb)
}

function updateLocation(contract,location,body, cb) {
  putWithStatus("/rest/admin/docs/" + contract + "/" + location, body, cb)
}

function addRoute(data,cb) {
  postWithStatus("/routes", data, cb)
}

function updateRoute(id, data,cb) {
  putWithStatus("/routes/" + id, data, cb)
}

function getRoute(id, cb) {
  getWithStatus("/routes/" + id, cb)
}

function getTours(route_id, cb) {
  getWithStatus("/routes/" + route_id + "/tours", cb)
}

function addTour(routeId, data, cb) {
  postWithStatus("/routes/"+routeId+"/tours",data, cb)
}

function updateTour(id, data,cb) {  
  putWithStatus("/routes/tours/" + id, data, cb)
}

function getTour(id, cb) {
  getWithStatus("/routes/tours/" + id, cb)
}
function downloadTour(id, cb) {
  getWithStatus("/routes/tours/" + id+"/download", cb)
  
}
function addWaypointscan(tour_id, data, cb){
  postWithStatus("/routes/tours/"+tour_id+"/waypoints", data, cb)
}

function getRoutesForPlace(placeCode, cb){
  getWithStatus("/routes?placeCode=" + placeCode, cb)
}

function getRoutesForJob(jobId, cb){
  getWithStatus("/routes?job=" + jobId, cb)
}

function saveJobDocument(job, type, document, docId, cb) {
  if(docId) {
    putWithStatus("/docs/document/" + docId, {
      document: document,
      type: type
    }, cb)
  }else {
    postWithStatus("/docs/" + job.id, {
      document: document,
      type: type
    }, cb)
  }
}

function getJobDocuments(job, cb) {
  getWithStatus("/docs/" + job.id, cb)
}

function getJobDocument(id, cb) {
  getWithStatus("/docs/document/" + id, cb)
}
function logoutWeb(cb) {
  postWithStatus("/rest/auth/logoutWeb", {}, cb)
}

function getLocationInfo(contract, location, date, cb){
  getWithStatus("/rest/admin/docs/" + contract + "/" + location+"?date=" + date, cb)
}

function signDocument(id, type, signature, cb) {
  postWithStatus("/docs/document/" + id + "/sign", {
    type: type,
    path: signature
  }, cb)
}

function checkSignature(id, type, cb) {
  getWithStatus("/docs/document/" + id + "/sign?type=" + type, cb)
}

function getTasks(status, cb) {
  getWithStatus("/tasks/?status="+status, cb)
}
function getTask(id, cb) {
  getWithStatus("/tasks/" + id, cb)
}

function getTaskContacts(cb){
  getWithStatus("/tasks/contacts", cb)
}

function getTaskFiles(task, cb){
  getWithStatus("/tasks/"+task.id+"/files", cb)
}
function addTaskFile(task, file, cb){
  postWithStatus("/tasks/"+task.id+"/files", file, cb)
}

function getTaskComments(task, cb){
  getWithStatus("/tasks/" + task.id + "/comments", cb)
}

function addTaskComment(task, comment, cb){
  postWithStatus("/tasks/" + task.id + "/comments", {
    comment: comment
  }, cb)
}

function createTask(task, cb) {
  postWithStatus("/tasks", task, cb)
}
function updateTask(task,cb){
  putWithStatus("/tasks/"+task.id, task, cb)
}

function updateWaypointscan(id, wps, cb) {
  putWithStatus("/routes/waypointscans/"+id, wps, cb)
}

module.exports = {
  addTaskFile: addTaskFile,
  getPlanJobs: getPlanJobs,
  checkInJob: checkInJob,
  getMessageGroups: getMessageGroups,
  getCheckinJobs: getCheckinJobs,
  deleteMessageGroup: deleteMessageGroup,
  sendMessageToGroup: sendMessageToGroup,
  changeJob: changeJob,
  changeUser: changeUser,
  sendInvitation: sendInvitation,
  sendCheckinReport: sendCheckinReport,
  getCAs: getCAs,
  getPicture: getPicture,
  saveMessageGroup: saveMessageGroup,
  getPresets: getPresets,
  getSpots: getSpots,
  savePicture: savePicture,
  getUsers: getUsers,
  getAdminJobs: getAdminJobs,
  getProjects: getProjects,
  checkLoginCode: checkLoginCode,
  generateLoginCode: generateLoginCode,
  arrayBufferToBase64: arrayBufferToBase64,
  getAllJobs: getAllJobs,
  getJobsInGroup: getJobsInGroup,
  getChats: getChats,
  getArchive: getArchive,
  sendMessageInChat: sendMessageInChat,
  getJobById: getJobById,
  getChatContacts: getChatContacts,
  createJob: createJob,
  requestSMS: requestSMS,
  setAttendance: setAttendance,
  createChat: createChat,
  getChatById: getChatById,
  isAuthenticated: isAuthenticated,
  acceptJob: acceptJob,
  acceptJobGroup: acceptJobGroup,
  declineJob: declineJob,
  getStats: getStats,
  getUser: getUser,
  getAdminUser: getAdminUser,
  getFileOTL: getFileOTL,
  getFile: getFile,
  getFiles: getFiles,
  confirmJob: confirmJob,
  getDocumentUsers: getDocumentUsers,
  updateDocumentUser: updateDocumentUser,
  deleteExternalDocumentPermission: deleteExternalDocumentPermission,
  requestMailCode: requestMailCode,
  checkMailCode: checkMailCode,
  getOpenDocuments: getOpenDocuments,
  getSameJobs: getSameJobs,
  getSignaturesForJob: getSignaturesForJob,
  saveJobDocument: saveJobDocument,
  getJobDocuments: getJobDocuments,
  getJobDocument: getJobDocument,
  signDocument: signDocument,
  getJobDocumentsForUser: getJobDocumentsForUser,
  logoutWeb: logoutWeb,
  getLocationInfo: getLocationInfo,
  updateLocation: updateLocation,
  checkSignature: checkSignature,
  getTasks: getTasks,
  getTaskContacts: getTaskContacts,
  createTask: createTask,
  getTask: getTask,
  updateTask: updateTask,
  getTaskComments: getTaskComments,
  addTaskComment: addTaskComment,
  getTaskFiles: getTaskFiles,
  getRoute: getRoute,
  getTour: getTour,
  getTours: getTours,
  addRoute: addRoute,
  addTour: addTour,
  addWaypointscan: addWaypointscan,
  updateRoute: updateRoute,
  getRoutesForPlace: getRoutesForPlace,
  getRoutesForJob: getRoutesForJob,
  isOnline: isOnline,
  updateTour: updateTour,
  updateWaypointscan: updateWaypointscan,
  downloadTour: downloadTour
}

function generateRequestId(message) {
  let hashValue = 0
  for (let i = 0; i < message.length; i++) {
    let charCode = message.charCodeAt(i);
    hashValue = ((hashValue << 5) - hashValue) + charCode;
    hashValue = hashValue & hashValue; // Ensure 32-bit integer limit
  }

  hashValue = hashValue >>> 0; // Convert to unsigned 32-bit integer
  console.log(hashValue.toString(16).padStart(8, '0'));
  return hashValue.toString(16).padStart(8, '0');
}

function arrayBufferToBase64(arrayBuffer) {
  var base64 = "";
  var encodings =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

  var bytes = new Uint8Array(arrayBuffer);
  var byteLength = bytes.byteLength;
  var byteRemainder = byteLength % 3;
  var mainLength = byteLength - byteRemainder;

  var a, b, c, d;
  var chunk;

  for (var i = 0; i < mainLength; i = i + 3) {
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

    a = (chunk & 16515072) >> 18;
    b = (chunk & 258048) >> 12;
    c = (chunk & 4032) >> 6;
    d = chunk & 63;

    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
  }

  if (byteRemainder == 1) {
    chunk = bytes[mainLength];

    a = (chunk & 252) >> 2;

    b = (chunk & 3) << 4;

    base64 += encodings[a] + encodings[b] + "==";
  } else if (byteRemainder == 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

    a = (chunk & 64512) >> 10;
    b = (chunk & 1008) >> 4;

    c = (chunk & 15) << 2;

    base64 += encodings[a] + encodings[b] + encodings[c] + "=";
  }

  return base64;
}