<template>
  <div
    ref="modalbg"
    class="z-30 center w-full h-full lg:px-20 lg:py-10 bg-black/25 text-center"
  >
    <div class="screencenter">
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Spinner",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
