<template>
  <div
    class="w-full h-full relative text-center m-0 p-0"
    @click="checkClick($event)"
  >
    <Transition>
      <JobModal
        v-if="selectedJob"
        :job="selectedJob"
        @close="selectedJob = false"
      />
    </Transition>
    <div class="fixed w-full z-10 text-center">
      <img class="w-full lg:mt-24 inline sm:hidden" src="@/assets/banner.jpg" />
      <img class="w-full sm:inline hidden" src="@/assets/DesktopBanner.png" />
    </div>
    <div class="relative w-full h-full text-center overflow-y-auto">
      <div
        class="relative w-full text-center pt-48 sm:pt-[10%] xl:w-5/6 xl:inline-block"
      >
        <button
          class="hidden md:inline absolute left-0 text-black bg-caspyellow border-4 border-gray-500 rounded-lg w-32 py-2 font-bold"
          onclick="window.location='/admin'"
        >
          Zurück
        </button>
        <span class="font-bold text-4xl">Nutzerverwaltung</span><br />
        <div
          class="text-left border-8 border-gray-500 rounded-xl shadow-lg mt-5 p-5 w-11/12 sm:w-5/6 inline-block"
        >
          <span class="block text-2xl font-bold">Neuen Benutzer anlegen</span>
          <div class="inline-block p-2 relative align-top">
            <span class="block font-bold mt-2">Benutzer</span>
            <input
              v-model="editedUser.displayName"
              placeholder="Mitarbeiternr"
              @click="filterUsers(editedUser)"
              @focus="filterUsers(editedUser)"
              @input="filterUsers(editedUser)"
              class="border-b-2 w-64 p-2 border-gray-500"
            />
            <div
              v-if="showUserDropdown == editedUser"
              ref="userDropdown"
              class="z-10 border-gray-500 absolute p-2 w-full bg-white max-h-96 overflow-y-auto"
            ></div>
          </div>
          <div class="inline-block p-2 relative">
            <span class="block font-bold mt-2">Berechtigungen</span>
            <input
              type="checkbox"
              v-model="editedUser.admin"
              id="editedUser_admin"
              @change="sanitizePermissions(editedUser, 'admin')"
            />
            <label for="editedUser_admin">Admin</label><br />
            <input
              type="checkbox"
              v-model="editedUser.standby"
              id="editedUser_standby"
              @change="sanitizePermissions(editedUser, 'standby')"
            />
            <label for="editedUser_standby">Rufbereitschaft</label><br />
            <input
              type="checkbox"
              v-model="editedUser.internalDispo"
              id="editedUser_internalDispo"
              @change="
                sanitizePermissions(editedUser, 'internalDispo')
              "
            />
            <label for="editedUser_internalDispo">Interner Disponent</label
              ><br />
            <input
              type="checkbox"
              v-model="editedUser.externalDispo"
              id="editedUser_externalDispo"
              @change="
                sanitizePermissions(editedUser, 'externalDispo')
              "
            />
            <label for="editedUser_externalDispo">Externer Disponent</label>
            <br />
            <input
              type="checkbox"
              v-model="editedUser.documents"
              id="editedUser_documents"
              @change="
                sanitizePermissions(editedUser, 'documents')
              "
            />
            <label for="editedUser_documents">Schicht-Dokumente</label>
            <br />
            <input
              type="checkbox"
              v-model="editedUser.tours"
              id="editedUser_tours"
              @change="
                sanitizePermissions(editedUser, 'tours')
              "
            />
            <label for="editedUser_tours">Rundgänge</label>
          </div>
          <div
            v-if="editedUser.internalDispo &&! editedUser.admin"
            class="inline-block p-2 relative align-top"
          >
            <span class="block font-bold mt-2">Projekt</span>
            <input
              v-model="editedUser.project"
              placeholder="Projektname"
              @click="filterProjects(editedUser, true)"
              @focus="filterProjects(editedUser, true)"
              class="border-b-2 w-full sm:w-96 p-2 border-gray-500"
              @input="filterProjects(editedUser, true)"
            />
            <div
              v-if="showProjectDropdown == editedUser"
              ref="projectDropdown"
              class="z-10 border-gray-500 absolute p-2 w-full bg-white max-h-96 overflow-y-auto"
            ></div>
          </div>
          <div
            v-if="editedUser.externalDispo &&! editedUser.admin"
            class="inline-block p-2 relative align-top"
          >
            <span class="block font-bold mt-2">Firma</span>
            <input
              v-model="editedUser.ca"
              placeholder="Firmenschlüssel"
              @click="filterCAs(editedUser, true)"
              @focus="filterCAs(editedUser, true)"
              class="border-b-2 w-36 p-2 border-gray-500"
              @input="filterCAs(editedUser, true)"
            />
            <div
              v-if="showCADropdown == editedUser"
              ref="caDropdown"
              class="z-10 border-gray-500 absolute p-2 w-full bg-white max-h-96 overflow-y-auto"
            ></div>
          </div>
          <button
            class="ml-4 inline-block font-bold mt-4 bg-gray-500 rounded p-2 text-white"
            @click="changeUser(editedUser)"
          >
            Hinzufügen
          </button>
        </div>
        <button
          class="inline md:hidden text-black bg-caspyellow border-4 border-gray-500 rounded-lg mt-5 w-32 py-2 font-bold"
          onclick="window.location='/admin'"
        >
          Zurück
        </button>
        <div v-if="advancedUsers.length > 0" id="tablecontent" class="w-full">
          <table class="w-full overflow-x-auto block md:table">
            <thead>
              <th>Personalnummer</th>
              <th>Name</th>
              <th>Berechtigungen</th>
              <th>Projekt / Firmenkürzel</th>
              <th>Aktionen</th>
            </thead>
            <tbody>
              <tr v-for="user in advancedUsers" :key="user.persnr">
                <td>{{ user.persnr }}</td>
                <td>{{ user.fullName }}</td>
                <td class="text-left">
                  <div class="inline-block">
                    <input
                      type="checkbox"
                      v-model="user.admin"
                      :id="user.persnr + 'user_admin'"
                      @change="sanitizePermissions(user, 'admin')"
                    />
                    <label :for="user.persnr + 'user_admin'">Admin</label>
                  </div>
                  <br />
                  <div class="inline-block">
                    <input
                      type="checkbox"
                      v-model="user.standby"
                      :id="user.persnr + 'user_standby'"
                      @change="sanitizePermissions(user, 'standby')"
                    />
                    <label :for="user.persnr + 'user_standby'"
                      >Rufbereitschaft</label
                    >
                  </div>
                  <br />
                  <div class="inline-block">
                    <input
                      type="checkbox"
                      v-model="user.internalDispo"
                      :id="user.persnr + 'user_internalDispo'"
                      @change="
                        sanitizePermissions(user, 'internalDispo')
                      "
                    />
                    <label :for="user.persnr + 'user_internalDispo'"
                      >Interner Disponent</label
                    >
                  </div>
                  <br />
                  <div class="inline-block">
                    <input
                      type="checkbox"
                      v-model="user.externalDispo"
                      :id="user.persnr + 'user_externalDispo'"
                      @change="
                        sanitizePermissions(user, 'externalDispo')
                      "
                    />
                    <label :for="user.persnr + 'user_externalDispo'"
                      >Externer Disponent</label
                    >
                  </div>
                  <br />
                  <div class="inline-block">
                    <input
                      type="checkbox"
                      v-model="user.documents"
                      :id="user.persnr + 'user_documents'"
                      @change="
                        sanitizePermissions(user, 'documents')
                      "
                    />
                    <label :for="user.persnr + 'user_documents'"
                      >Schicht-Dokumente</label
                    >
                  </div>
                  <br />
                   <div class="inline-block">
                    <input
                      type="checkbox"
                      v-model="user.tours"
                      :id="user.persnr + 'user_tours'"
                      @change="
                        sanitizePermissions(user, 'tours')
                      "
                    />
                    <label :for="user.persnr + 'user_tours'"
                      >Rundgänge</label
                    >
                  </div>
                  <br />
                </td>
                <td>
                  <div
                    v-if="user.internalDispo &&! user.admin"
                    class="inline-block p-2 relative align-top"
                  >
                    <span class="block font-bold mt-2">Projekt</span>
                    <input
                      v-model="user.project"
                      placeholder="Projektname"
                      @click="filterProjects(user)"
                      @focus="filterProjects(user)"
                      class="border-b-2 w-full sm:w-96 p-2 border-gray-500"
                      @input="filterProjects(user)"
                    />
                    <div
                      v-if="showProjectDropdown == user"
                      :ref="user.id + 'projectDropdown'"
                      class="z-10 border-gray-500 absolute p-2 w-full bg-white max-h-96 overflow-y-auto"
                    ></div>
                  </div>
                  <div
                    v-if="user.externalDispo &&! user.admin"
                    class="inline-block p-2 relative align-top"
                  >
                    <span class="block font-bold mt-2">Firma</span>
                    <input
                      v-model="user.ca"
                      placeholder="Firmenschlüssel"
                      @click="filterCAs(user)"
                      @focus="filterCAs(user)"
                      class="border-b-2 w-36 p-2 border-gray-500"
                      @input="filterCAs(user)"
                    />
                    <div
                      v-if="showCADropdown == user"
                      :ref="user.id + 'caDropdown'"
                      class="z-10 border-gray-500 absolute p-2 w-full bg-white max-h-96 overflow-y-auto"
                    ></div>
                  </div>
                </td>
                <td class="align-top">
                  <button
                    v-if="user.changed"
                    class="inline-block font-bold mx-1 mt-4 bg-gray-500 rounded p-2 text-white"
                    @click="changeUser(user)"
                  >
                    Speichern
                  </button>
                  <button
                    v-if="user.changed"
                    class="inline-block font-bold mx-1 mt-4 bg-gray-500 rounded p-2 text-white"
                    @click="getUsers()"
                  >
                    Reset
                  </button>
                  <button
                    class="inline-block font-bold mx-1 mt-4 bg-gray-500 rounded p-2 text-white"
                    @click="deleteUser(user)"
                  >
                    Löschen
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CASP from "../../CASP";
import { useCookies } from "vue3-cookies";
import JobModal from "@/components/JobModal.vue";

export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  name: "Jobs",
  components: { JobModal },
  data() {
    return {
      token: "",
      editedUser: {
        id: "",
        displayName: "",
        standby: false,
        internalDispo: false,
        externalDispo: false,
        admin: false,
        project: "",
        ca: "",
      },
      permissions: "",
      allProjects: [],
      allCAs: [],
      allUsers: [],
      showProjectDropdown: false,
      showUserDropdown: false,
      showCADropdown: false,
      selectedJob: false,
      adminusers: [],
    };
  },
  methods: {
    checkClick(event) {
      if (event.target.nodeName == "P") {
        if (this.showUserDropdown) {
          this.editedUser = this.allUsers.filter((el) => {
            return el.displayName == event.target.innerText;
          })[0];
          this.showUserDropdown = false;
        } else if (this.showProjectDropdown) {
          this.showProjectDropdown.project = event.target.innerText;
          this.showProjectDropdown.changed = true;
          this.showProjectDropdown = false;
        } else if (this.showCADropdown) {
          this.showCADropdown.ca = event.target.innerText;
          this.showCADropdown.changed = true;
          this.showCADropdown = false;
        }
      }
      console.log(event.target.nodeName);
      console.log(event.target.placeholder);
      if (event.target.nodeName != "INPUT") {
        this.showCADropdown = false;
        this.showUserDropdown = false;
        this.showProjectDropdown = false;
      } else if (event.target.placeholder == "Mitarbeiternr") {
        this.showProjectDropdown = false;
        this.showCADropdown = false;
      } else if (event.target.placeholder == "Firmenschlüssel") {
        this.showProjectDropdown = false;
        this.showUserDropdown = false;
      } else if (event.target.placeholder == "Projektname") {
        this.showCADropdown = false;
        this.showUserDropdown = false;
      } 
      console.log(this.showUserDropdown);
    },
    sanitizePermissions(obj, field) {
      obj.changed = true;
      if (obj[field]) {
        if (field == "internalDispo" && obj.externalDispo) {
          obj.externalDispo = false;
        } else if (field == "externalDispo" && obj.internalDispo) {
          obj.internalDispo = false;
        } else if (field == "admin") {
          obj.internalDispo = true;
          obj.externalDispo = true;
          obj.standby = true;
        }
      }else{
        obj.admin=false
      }
    },
    setPermissions(user) {
      CASP.updateUser(
        this.token,
        user.id,
        { Mitarbeiternr: user.Mitarbeiternr.split(" (")[0] },
        (res) => {
          if (res) {
            delete user.MitarbeiternrChanged;
          }
        }
      );
    },
    getProjects() {
      let vm = this;
      CASP.getProjects(this.token, (res) => {
        if (res) {
          vm.allProjects = [{name: "Alle Projekte"}].concat(res);
        }
      });
    },
    getCAs() {
      let vm = this;
      CASP.getCAs(this.token, (res) => {
        if (res) {
          vm.allCAs = res;
        }
      });
    },
    getUsers() {
      let vm = this;
      CASP.getUsers(this.token, (res) => {
        if (res) {
          vm.allUsers = res;
        }
      });
    },
    deleteUser(user) {
      CASP.changeUser(this.token, user.id, {admin: false, internalDispo: false, externalDispo: false, standby: false}, (res) => {
        if (res) {
          this.getUsers();
        }
      });
    },
    changeUser(user) {
      CASP.changeUser(this.token, user.id, user, (res) => {
        if (res) {
          this.getUsers();
        }
      });
    },
    filterUsers(user) {
      this.showUserDropdown = user;
      let options = "";
      this.allUsers.forEach((el) => {
        if (
          el.displayName.toLowerCase().includes(user.displayName.toLowerCase())
        ) {
          options += "<p class='dp'>" + el.displayName + "</p>";
        }
      });
      if (this.$refs["userDropdown"]) {
        this.$refs["userDropdown"].innerHTML = options;
      }
    },
    filterProjects(user, edit) {
      this.showProjectDropdown = user;
      let options = "";
      this.allProjects.forEach((el) => {
        if (el.name.toLowerCase().includes(user.project.toLowerCase())) {
          options += "<p class='dp'>" + el.name + "</p>";
        }
      });
      let dropdownId = edit ? "" : user.id;
      if (this.$refs[dropdownId + "projectDropdown"]) {
        this.$refs[dropdownId + "projectDropdown"].innerHTML = options;
      }
    },
    filterCAs(user, edit) {
      console.log(user);
      this.showCADropdown = user;
      let options = "";
      this.allCAs.forEach((el) => {
        if (el.name.toLowerCase().includes(user.ca.toLowerCase())) {
          options += "<p class='dp'>" + el.name + "</p>";
        }
      });
      let dropdownId = edit ? "" : user.id;
      if (this.$refs[dropdownId + "caDropdown"]) {
        this.$refs[dropdownId + "caDropdown"].innerHTML = options;
      }
    },
  },
  computed: {
    advancedUsers: function () {
      return this.allUsers.filter((el) => {
        return el.admin || el.internalDispo || el.externalDispo || el.standby;
      });
    },
  },
  created: function () {
    document.getElementById("mainspinner").style.display = "none";
    if (this.cookies.get("authtoken")) {
      this.token = this.cookies.get("authtoken");
      CASP.isAuthenticated(this.token, (auth) => {
        if (!auth) {
          window.location = "/admin/login";
        } else {
          this.getProjects();
          this.getUsers();
          this.getCAs();
        }
      });
    } else {
      window.location = "/admin/login";
    }
  },
};
</script>
<style scoped>
</style>