<template>
  <toolbar title="Einstellungen" />
  <div class="w-full relative text-center">
    <div class="w-full border-b border-b-gray-200 py-2 pl-2 text-left" onclick="window.location='/impressum'">
      <span class="align-middle font-bold">Impressum</span>
      <i
        class="
          fa fa-chevron-right
          align-middle
          float-right
          mt-1
          mr-5
          text-gray-500
        "
      />
    </div>
    <span class="font-bold mt-10 inline-block">Version: 2.1.27</span><br>
    <button onclick="window.location='/forbidden'" class="border-red-500 border-2 rounded-lg text-red-500  text-lg font-bold px-7 mt-2">Ausloggen</button>
  </div>
</template>
<script>
import Toolbar from "@/components/Toolbar.vue";
import { useCookies } from "vue3-cookies";
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  components: { Toolbar },
  created: function () {
    document.getElementById("mainspinner").style.display = "none";
    if (this.cookies.get("authtoken")) {
      this.token = this.cookies.get("authtoken");
    } else {
      window.location = "/forbidden";
    }
  },
};
</script>
