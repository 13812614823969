<template >
  <toolbar title="Dienstausweis" />
  <div class="w-full text-center">
    <div class="w-full h-14 text-black bg-caspyellow text-center border-b-2 border-b-black">
      <span class="text-3xl mt-2 inline-block font-bold tracking-widest">SECURITY</span>
    </div>
    <div class="max-w-5xl inline-block pb-20">
      <div class="w-full text-center">
        <span class="text-3xl mt-2 inline-block tracking-widest">DIENSTAUSWEIS</span><br />
      </div>
      <div class="w-full text-center">
        <img v-if="!enlargeQR" class="w-24 xs:w-32 mx-5 mt-3 inline" src="@/assets/casplogo.png" />
        <div v-if="!enlargeQR" class="inline-block">
          <QR v-if="user.qrValue" :value="user.qrValue" :size="96" class="xs:hidden inline-block mx-5 mt-3" @click="enlargeQR = !enlargeQR"></QR>
          <QR v-if="user.qrValue" :value="user.qrValue" :size="128" class="hidden xs:inline-block mx-5 mt-3" @click="enlargeQR = !enlargeQR"></QR>
        </div>
        <QR v-else :value="user.qrValue" :size="325" class="inline-block mx-5 mt-3" @click="enlargeQR = !enlargeQR"></QR><br />
        <span class="mt-2 xs:mt-4 inline-block xs:text-2xl">www.casp-security.com</span>
      </div>
      <div v-if="!enlargeQR" class="w-full grid mt-4 p-3 grid-cols-2">
        <div class="text-left">
          <div>
            <div class="w-5/6 border-b-2 border-b-black">
              <span class="font-bold text-lg">{{ user.function }}</span>
            </div>
            <span>FUNKTION</span>
          </div>
          <div>
            <div class="w-5/6 border-b-2 border-b-black">
              <span class="font-bold text-lg">{{ user.persnr }}</span>
            </div>
            <span>PERSONAL-NR.</span>
          </div>
          <div>
            <div class="w-5/6 border-b-2 border-b-black">
              <span class="font-bold text-lg">{{ user.idnr }}</span>
            </div>
            <span>AUSWEIS-NR</span>
          </div>
          <div>
            MA: <span class="text-sm">{{ user.name }}</span><br />
            ID-BEWAR: <span class="font-bold">{{ user.idbewar }}</span>
          </div>
        </div>
        <div class="text-center">
          <i v-show="user.pictureId == ''" class="fa fa-user-tie fa-8x inline" />
          <img v-show="user.pictureId" id="img" class="w-32 inline" />
        </div>
        <div class="text-center w-full col-span-2">
          <a href="/info" class="inline-block mt-10 border-caspyellow text-black bg-caspyellow rounded-lg w-28 xl:w-56 xs:w-72 py-2 border-2 font-bold xl:m-10">
            Persönliche Daten
          </a>
        </div>
      </div>

    </div>
    <div class="w-full fixed left-0 bottom-0 h-18 text-black bg-caspyellow text-center">
      <img class="w-96 px-2 inline" src="@/assets/barcode.png" /><br />
      <span class="-mt-3 inline-block">info@casp-security.com</span>
    </div>
  </div>
</template>
<script>
import Toolbar from "@/components/Toolbar.vue";
import CASP from "../../CASP";
import QR from "@/lib/QR.js";

import { useCookies } from "vue3-cookies";
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      enlargeQR: false,
      user: {},
    };
  },
  components: { Toolbar, QR },
  created: function () {
    document.getElementById("mainspinner").style.display = "none";
    if (this.cookies.get("authtoken")) {
      this.token = this.cookies.get("authtoken");
    } else {
      window.location = "/forbidden";
    }
    let comp = this;
    CASP.getUser(this.token, (status, user) => {
      if (status == 200) {
        comp.user = JSON.parse(user);
        if (user.pictureId != "") {
          CASP.getFile(this.token, comp.user.pictureId, (response, type) => {
            document.getElementById("img").src =
              "data:" + type + ";base64," + CASP.arrayBufferToBase64(response);
          });
        }
      }
    });
  },
};
</script>
